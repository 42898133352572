import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqDesk = () => {
  return (
    <>
      <section className='faq-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                     What is Zentixs Desk?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Desk is a ticket management and client complaint management software designed to streamline customer support and enhance communication between clients and support teams.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      How does the automated ticketing system work?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The automated ticketing system generates a ticket for each client complaint, categorizing and prioritizing them based on urgency to ensure efficient resolution.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Can I track the status of my complaint?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Yes, clients can track the status of their complaints in real-time, receiving notifications about updates and resolutions.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What compliance standards does Zentixs Desk follow?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Desk adheres to ISO 9001 standards, focusing on quality management and continuous improvement in customer satisfaction.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                     How can I analyze complaint trends?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Desk provides comprehensive reporting and analytics features that allow you to view ticket resolution times, complaint trends, and team performance.
                    </div>
                  </div>
                </div>              
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='accordion faq-accordion' id='accordionExample2'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                    Is Zentixs Desk customizable?
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, Zentixs Desk can be customized to fit your organization's specific needs and workflows, ensuring optimal use.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                      How can I get support for using Zentixs Desk?
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Support is available through our help center, user manuals, and customer service team, ready to assist with any questions or issues you may have.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                     What types of complaints can be managed with Zentixs Desk?
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Desk can handle various types of complaints, including product issues, service inquiries, and general feedback, providing a comprehensive solution for customer support.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                      Can I assign tickets to specific team members?
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, tickets can be assigned to specific team members based on their expertise or workload, ensuring that the right person addresses each complaint efficiently.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-11'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-11'
                      aria-expanded='false'
                    >
                    How does Zentixs Desk improve team collaboration?
                    </button>
                  </h5>
                  <div
                    id='collapse-11'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-11'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Desk facilitates team collaboration by allowing team members to comment on tickets, share updates, and collaborate on solutions within the platform.
                   </div>
                  </div>
                </div>

             
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqDesk;
