import React from 'react';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';


export default function DeskContent() {
  return (
    <section className="bg-white ptb-120 ">
      <div className="container ">
        <div className='desk-world-section'>
        <div className="row justify-content-center align-items-center">      
          <div className="col-lg-5">
          <Reveal effect="fadeInUp">

            <div className="digi-how-works-left">   
            

              <h2>
              Why use Zentixs Desk?       
              </h2>             
 
              <div className="dg-news-img">
                <img
                  src="assets/img/desk1.png"
                  className="img-fluid text-end"
                  alt="document process"
                />
              </div>     
            </div>
            </Reveal>
          </div>
         
          <div className="col-lg-5">
            <div className="mt-5 mt-lg-0">
            <div className='feature-content-wrap'>

            <ul className="list-unstyled d-flex flex-wrap text-dark-gray eye-list mb-0">
      <Fade bottom delay={0}>
        <li className="py-1">
          <i className="fa-sharp fa-solid fa-circle-check"></i>
          <strong>Automated Ticketing System:</strong> Automatically generates and prioritizes tickets for client complaints, ensuring no issue is overlooked and streamlining support workflows.
        </li>
      </Fade>
      <Fade bottom delay={300}>
        <li className="py-1">
          <i className="fa-sharp fa-solid fa-circle-check"></i>
          <strong>Real-Time Notifications:</strong> Provides instant updates on ticket status, enhancing communication and fostering trust between clients and support teams.
        </li>
      </Fade>
      <Fade bottom delay={600}>
        <li className="py-1">
          <i className="fa-sharp fa-solid fa-circle-check"></i>
          <strong>Comprehensive Reporting:</strong> Offers detailed analytics on ticket resolution times and trends, helping management optimize processes and improve customer satisfaction.
        </li>
      </Fade>
      <Fade bottom delay={900}>
        <li className="py-1 mb-0">
          <i className="fa-sharp fa-solid fa-circle-check"></i>
          <strong>Root Cause and Impact:</strong> Enhancing quality management and customer satisfaction and effective root cause analysis using the 5 Whys method to prevent future issues.
        </li>
      </Fade>
    </ul>
</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}
