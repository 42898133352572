import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../common/HeroTitle';
import Reveal from 'react-reveal/Reveal';


const HeroLms = () => {
 
  return (
    <>
      <section
        className='hero-section red-bg text-white ptb-120'        
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5 hero-doc'>
                <HeroTitle
                  title='Transform Findings into Action with Zentixs Eye'
                  desc='Efficiently manage internal and external audits, ensuring compliance with GxP and regulatory standards. Enhance transparency, reduce risk, and simplify the audit process to maintain high-quality standards across all operations.'                  
                />          
              
              </div>
            </div>
            <div className='col-lg-6 col-md-8'>
            <Reveal effect="fadeinright">


              <div className='hero-img position-relative circle-shape-images'>             
                {/* <!--animated shape end--> */}
                <img
                  src='assets/img/audit.png'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                />
              </div>
              </Reveal>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroLms;
