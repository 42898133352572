import React from 'react';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const EulaDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>        
            <div className='col-lg-8 col-md-8'>

            <p>THIS DOCUMENT IS A LEGAL AGREEMENT BETWEEN ADRTA TECHNOLOGIES PRIVATE LIMITED <b>("Adrta", "we", "us", "our")</b> AND YOU WITH REGARDS TO THE SOFTWARE AND DOCUMENTATION. THE TERM "YOU" <b>(“you”, “your”, “User”)</b> REFERS TO EITHER YOU AS AN INDIVIDUAL OR ENTITY OR THE ENTITY YOU REPRESENT, ALONG WITH ITS USERS, AS APPLICABLE.</p>
            <p>By downloading, installing, copying, accessing or using the software (explained in Clause 1.2.2. of this Agreement), the User automatically agrees to this Adrta End User License Agreement (hereinafter referred to as <b>“Agreement”</b>) and the promises, covenants and warranties contained herein. If the User is accepting this Agreement on behalf of another person or another legal entity, the User hereby represents and warrants that the User has the full authority to bind that person or legal entity to the terms and conditions of this Agreement. The User must ensure that the User complies with this Agreement and is responsible for compliance with and the User shall be held liable in case of breach of this Agreement.</p>
            <p>If the User does not agree with the terms of this Agreement, the User must (a) not download, install, copy, access or use the Software; and (b) promptly return the Software and proof of entitlement acquired from the Software.</p>
            <p>THE SOFTWARE AND DOCUMENTATION ARE PROTECTED BY COPYRIGHT LAWS OF INDIA, AND OTHER INTELLECTUAL PROPERTY LAWS AND TREATIES.</p>
            <p>BY DOWNLOADING, INSTALLING, COPYING, OR OTHERWISE UTILIZING THE SOFTWARE, IN ANY MANNER, YOU ACKNOWLEDGE AND AGREE TO BE BOUND BY THE TERMS OF THIS ADRTA END-USER LICENSING AGREEMENT <b>("EULA").</b> YOU CONFIRM THAT YOU HAVE THE LEGAL POWER TO ENTER INTO THIS AGREEMENT. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF AN ENTITY, YOU CONFIRM THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY TO THIS AGREEMENT. IF YOU DO NOT ACCEPT THE TERMS OF THIS EULA, YOU ARE NOT PERMITTED TO DOWNLOAD, INSTALL, COPY, DUPLICATE, OPERATE, OR USE THE SOFTWARE. ADDITIONALLY, YOU MUST DELETE OR DESTROY ANY COPIES OF THE SOFTWARE YOU MAY HAVE ALREADY DOWNLOADED OR INSTALLED AND REFRAIN FROM USING IT IN ANY CAPACITY.</p>
            <p>This Agreement describes your rights and restrictions with regards to the Software and Documentation. </p>
            <p>All references to the plural in this Agreement shall also mean the singular and to the singular shall also mean the plural unless the context otherwise requires. The headings used in this Agreement are included for purposes of convenience and reference only and will not limit or otherwise affect the terms and conditions herein.</p>
            
            <h3 class="h5 pb-2">1.	DEFINITIONS AND GENERAL TERMS</h3>
            <p>1.1.	The capitalized terms used herein shall have the definitions assigned to them in this Clause 1, and shall include the singular as well as the plural form of the capitalized terms, unless otherwise expressly defined herein.</p>
            <p>1.2.	In this Agreement, except where the context or subject matter otherwise requires: </p>
            <div class="sub-clause">            
            <p>1.2.1.	“Company” or “Adrta” shall mean the corporate entity being Adrta Technologies Private Limited providing the Software and Documentation, under this Agreement, including its affiliates, successors, and assigns.</p>
            <p>1.2.2.	"Software'' shall mean the whole, or any portion of, or any derivative work of (a) the Adrta software (both the server-side and client-side framework), and (b) the Adrta database. This shall include the design as well as the code, data, database tables, images, components, tools and user-interfaces, and any additions or modifications made to them in new releases. It excludes any third party software, as mentioned elsewhere in this  Agreement. Such Software shall include but not be limited to the Zentixs Suite which encompasses a range of digital solutions aimed at optimizing various organizational processes. Each service offered within the Zentixs Suite is hereby tailored to address specific needs within different domains. Following are the services offered through the Software and/or the Zentixs Suite, including but not limited to Zentixs Docs, Zentixs Talent, Zentixs Track, Zentixs Eye, Zentixs Labs, Zentixs Sign, Zentixs Caliber, Zentixs Log, Zentixs Sprint, Zentixs Saga, Zentixs Desk, and Zentixs Ibook. You can find more information about each of these services offered on the Zentixs Suite by checking out our Website Terms and Conditions or by clicking on this link: https://adrtatech.com/terms.</p>
            <p>1.2.3.	"Documentation" shall mean any information provided by us which describes the functioning of the Software (which includes but is not limited to documentation, tutorials, examples, context-sensitive help, “frequently asked questions”, marketing material, videos, sound recordings, discussions on social media and forums, group or individual emails).</p>
            <p>1.2.4.	"User" shall refer to any individual, organization, or entity that downloads, installs, accesses, or otherwise utilizes the Software provided by Adrta Technologies, whether in whole or in part. A User may include but is not limited to employees, contractors, agents, representatives, or any other authorized personnel acting on behalf of the client organization. Each User is subject to the terms and conditions of this Adrta End User License Agreement (EULA) and is responsible for compliance with all applicable provisions therein.</p>
            <p>1.2.5.	"Facilities" refer to the distinct physical locations or operational units within an organization where the Software is intended to be deployed and utilized. Each facility may represent a separate branch, office, department, or other designated area within the organization.</p>
            <p>1.2.6.	“Permissible  Parts” refers to those (a) files that Adrta specifically and clearly marks at the top of the file with the words “The code in this specific file is licensed under the Adrta License” and (b) any third party software, as stated in the “Third Party Software” section of this Agreement.</p>
            <p>1.2.7.	“Third-Party Software” refers to any software or technology that is not proprietary to the Company and which may be included or integrated with the Software provided under this Agreement. Third Party Software may include, but is not limited to, libraries, frameworks, modules, plugins, APIs, or other components provided by third-party vendors, licensors, or open-source contributors. Third Party Software may be subject to separate license agreements, terms of use, or other legal terms, which shall govern the use of such software in conjunction with this Agreement.</p>
            <p>1.2.8.	“Confidential Information” refers to any information (regardless of the form of disclosure or the medium used to store or represent it) of Adrta, including trade secrets and technical, financial or business information, data, ideas, concepts or know-how, that:</p>
            <div class="sub-clause"> 
              <p>1.2.8.1.	is designated as “confidential” or by similar words by Adrta at the time of disclosure and, if oral or visual, is confirmed as confidential by Adrta in writing within fifteen (15) days of disclosure; or</p>
              <p>1.2.8.2.	the User should have reasonably considered such information to be confidential in nature under the circumstances surrounding the disclosure. However, Confidential Information does not include any information including but not limited to:</p>
              <div class="sub-clause"> 
                <p>1.2.8.2.1.	written records lawfully acquired by or previously known to the User, independent of Adrta;</p>
                <p>1.2.8.2.2.	is received from a third party without restrictions on its use or disclosure and not by inadvertence or mistake;</p>
                <p>1.2.8.2.3.	is or has become disseminated to the public through no fault of the User and without violation of the terms of this Agreement or other obligation to maintain confidentiality; or</p>
                <p>1.2.8.2.4.	is created independently by the User without breach of this Agreement, including any obligation of confidentiality owed to Adrta.</p>
                </div>
            </div>

            <p>1.2.9.	“Intellectual Property Rights” refers to all intellectual property or other proprietary rights within the territory of India and/or throughout the world, whether existing under statute, at common law or in equity, now existing or created in the future, including:</p>
            <div class="sub-clause"> 
              <p>1.2.9.1.	copyright, trademark, design and patent rights, trade secrets, moral rights, right of publicity, or authors’ rights;</p>
              <p>1.2.9.2.	any application or right to apply for any of the rights referred to in sub clause 1.2.9.1.; and</p>
              <p>1.2.9.3.	all renewals, extensions, continuations, divisions, restorations or reissues of the rights, or applications referred to in sub clauses 1.2.9.1. and 1.2.9.2.</p>
            </div>
            <p>1.2.10.	"Adrta Platform" refers to and encompasses the entirety of the Software and Documentation, and any updates, modifications, or enhancements thereto, made available by Adrta on the Website. </p>
            <p>1.2.11.	“Subscription Period” refers to the duration during which the User is granted access to and use of the Adrta Platform and/or its associated services, as specified in the subscription page or purchase page displayed to the User on the website having URL: https://adrtatech.com/. The Subscription Period begins on the commencement date specified in the payment receipt and shall end on the expiration date, unless terminated earlier in accordance with the terms of this Agreement.</p>
            <p>1.2.12.	“Subscription Fees” refers to the monetary charges payable by the User to the Company for the access to and use of the exclusive or premium Adrta Platform and/or its associated services during the Subscription Period, as outlined in the subscription page or purchase page displayed to the User on the Website having URL: https://adrtatech.com/. Subscription Fees may include one-time setup fees, recurring periodic fees, and any applicable taxes or additional charges specified in the Agreement or which may be uploaded on the purchase page, order page or web page, from time to time.</p>
            <p>1.2.13.	"Subscription Plan" refers to the specific package or tier of services offered by Adrta to Users, entitling them to access and utilize the Software, Documentation and associated services provided by Adrta Technologies. The Subscription Plan outlines the features, functionalities, duration, pricing, and terms of use applicable to the subscribed services. Each Subscription Plan may vary in terms of service level, support options, usage limits, and additional benefits, as specified in the corresponding plan documentation provided by Adrta.</p>
            <p>1.2.14.	“Website” shall refer to the online platform or web application operated by the Company, accessible via the internet, through which the Adrta Platform is made available to Users. The Website may include web pages, content, features, and functionalities related to the Adrta Platform, as well as any associated documentation, support resources, or communication channels provided by the Company.</p>
            <p>1.2.15.	“Force Majeure Event” refers to any event beyond a party’s reasonable control that, by its nature, could not have been foreseen or, if it could have been foreseen, was unavoidable, including strikes, lock-outs or other industrial disputes (whether involving its own workforce or a third party’s), acts of God, war, riot, embargoes, acts of civil or military authorities, acts of terrorism or sabotage, shortage of supply or delay in delivery by Adrta’s vendors, fire, flood, earthquake, accident, radiation, inability to secure transportation, failure of communications or energy sources, malicious damage, breakdown of plant or machinery, or default of suppliers or subcontractors.</p>
            <p></p>
            <p></p>
            </div>

            
            <h3 class="h5 pb-2">2.	VERSIONING OF SOFTWARE </h3>
            <p>2.1.	The Software and this Agreement are presently aligned with ____ version, as updated on _____ [date] and are versioned according to the _______ model [name the model], which follows the format ___________ [name the format]. This model defines how version numbers are assigned and incremented for the Software and the Agreement, ensuring clear communication of changes and updates to Users.</p>
            
            <h3 class="h5 pb-2">3.	MODIFICATIONS TO THIS AGREEMENT</h3>
            <p>3.1.	This Agreement encompasses or may be modified to encompass copies, downloads, installations, and upgrades of official versions of the Software. You are hereby prohibited under the terms of this Agreement and under the Intellectual Property Rights of the Republic of India and/or from the Intellectual Property Rights from your home country from relocating, deleting, obscuring, or renaming the file containing the Agreement or its contents and non-compliance of this sub clause 3.1. Such acts as mentioned in this sub clause 3.1. shall lead to the persuasion of civil, criminal and/or other remedies available under the law, against the violating User.</p>
            <p>3.2.	Adrta may, at its sole discretion, modify, amend or update this Agreement with each release, modification or improvement of the Adrta Platform and you are hereby directed to check this Agreement from time to time. By downloading, copying, upgrading or making use of the Adrta Platform, by any means, you accept the terms and conditions of the Agreement associated with the release, which includes all changes to the Agreement to date.</p>
           
            <h3 class="h5 pb-2">4.	PAYMENTS</h3>
            <p>4.1.	THE SOFTWARE IS LICENSABLE IN NATURE AND NOTHING IN THIS AGREEMENT SHALL CONSTRUE THAT THE SOFTWARE IS FOR SALE.</p>
            <p>4.2.	The Subscription Fee for the exclusive and non-transferrable use of the Software is determined, calculated and/or tiered based on the total number of Facilities or Users, using the Software during the Subscription Period.   </p>
            <p>4.3.	The Software is made available to the User under a subscription model for a specific Subscription Period, which includes an option for a one-time payment and/or an annual maintenance contract, between the User and Adrta. The one-time payment grants the User perpetual access to the Software, while the annual maintenance contract ensures ongoing and perpetual maintenance, support and updates to the Adrta Platform.</p>
            <p>4.4.	The User may choose to subscribe to the Software on a yearly basis and the Subscription Fee for the yearly plan grants access to the Software for a period of one (1) year (365 days) from the date of subscription and/or the beginning of the Subscription Period. Renewal of the Subscription Period is subject to payment of the applicable fee and/or Subscription Fee, as may be highlighted, displayed or modified on the Website and/or subscription page displayed on the Website.</p>
            <p>4.5.	The User may opt for a monthly Subscription Plan, which grants access to the Software for a period of one (1) month (31 days) from the date of beginning of the Subscription Period. Renewal of the Subscription Period shall be subject to payment of the applicable fee and/or Subscription Fee, as may be highlighted, displayed or modified on the Website.</p>
            <p>4.6.	The Software may be used free of charge in the following usages cases, subject to the Limitations of Use clause below (clause 10):</p>
            <div class="sub-clause"> 
            <p>4.6.1.	Versatile Use. The Software is intended for versatile use, whether for personal or commercial purposes, and may be utilized in connection with any form of business, occupation, or vocation. It is expressly permitted for versatile use, including but not limited to activities aimed for commercial use and/or financial gain.</p>
            <p>4.6.2.	Trial Accounts. Users may access the Software on a free trial basis, subject to the limitations outlined in this Agreement and/or as may be displayed on other agreements of the Website from time to time. However, only one (1) free trial account shall be permitted per User, company, individual, or entity. Moreover, since the Software is provided on a free trial basis, ADRTA reserves the right to terminate such free or trial usage with at least one (1) week's prior written notice. Further use of the Service may be subject to payment of the relevant Subscription Fees.</p>
            </div>

            <p>4.7.	Payments, applicable for Software used by the Users, shall have the following terms and conditions, being: </p>
            <div class="sub-clause"> 
            <p>4.7.1.	The Subscription Fee must be paid in all other cases to use the Software.</p>
            <p>4.7.2.	The one time fee allows for the use of the Software for an infinite period of time, until the termination of the contractual relationship between the User and Adrta. </p>
            <p>4.7.3.	The annual fee allows for the use of the Software for a total period of one (1) year (365 days) only.</p>
            <p>4.7.4.	The monthly fee allows for the use of the Software for a period of thirty one (31) days only.</p>
            <p>4.7.5.	The first Subscription Period starts on the first day of any calendar month, as soon as any User makes any utilization, use, applies or operates the Software.</p>
            <p>4.7.6.	Payment shall be payable in advance for the Subscription Period and shall not be refundable in nature, upon termination, unless terminated for breach of the terms of this Agreement and/or any other terms as notified from time to time, by Adrta.</p>
            </div>

            <p>4.8.	Subscription Fees payable by the User’s are non-refundable and non-transferable in nature. The Subscription Fees may be adjusted from time to time, and notifications to this effect shall be sent to the User’s registered email account, at least sixty (60) days prior to when the Subscription Fees are scheduled for adjustment, modification or change. At the time of payment, the Subscription Fees displayed on the official Adrta website, shall be payable.</p>
            <p>4.9.	The Subscription Period shall auto-renew and Subscription Fees shall be payable, provided either party does not advise termination of the Agreement at any time before the end of the current Subscription Period. A notification for payment may be sent at least sixty (60) days before an annual subscription is up for renewal, and ten (10) days before a monthly subscription is up for renewal. When a payment or automatic payment for yearly subscription cannot be processed, you shall have a period of thirty (30) days from the renewal date to make the payment, in order to retain the license to the Software. Subscription Fees not paid when due, shall be subject to interest at a rate of the lower of one and one-half percent (1.5%) per calendar month or the maximum rate permitted by law.</p>
            <p>4.10.	Users shall only use Software where all due Subscription Fees have been promptly paid by the User. Failure to pay any due Subscription Fees shall constitute a material breach of this Agreement.</p>
            <p>4.11.	Maintenance Services as highlighted in clause 12 of this Agreement may be subject to additional fees as specified in the subscription page of the official Adrta website, and/or a maintenance agreement signed and entered into separately between the Company and the User.</p>
            <p>4.12.	Invoicing for Subscription Fees shall be conducted either monthly or annually, depending upon the Subscription Plan selected by the User. However, in order to ensure uninterrupted service of the Adrta Platform, we kindly request that all payments and/or Subscription Fees be made in advance of the end of the Subscription Period to prevent immediate suspension of the service of the Software.</p>
            <p>4.13.	User facility, organization, and/or office visits conducted by the Adrta employees, assignees, or contractors are subject to additional charges, which shall be invoiced and payable separately, by the User.</p>
            <p>4.14.	If a purchase order is received from the User, but the Subscription Fees remain outstanding for a period exceeding three (3) months from the date of end of the Subscription Period, the Software access shall be suspended, until the payment is duly received from the User. Moreover, in such cases, new rates may apply upon reactivation of the Subscription Period of the Software. Note: Adrta reserves the right to adjust the Subscription Fee payable by the User, at any time and Users shall be notified, in advance, of any changes to the Subscription Fees.</p>
            
            <h3 class="h5 pb-2">5.	GRANT OF LICENSE & DOCUMENTATION</h3>
            <p>5.1.	Subject to compliance with all the terms and conditions of this Agreement, we hereby grant the Users a non-exclusive, non-transferable, non-perpetual license to use the Software solely for the purpose of providing help desk services. This license includes the right to use, exploit, input, enter, key in, introduce, log, add, type in, put in, feed, load, and insert information, data, facts, findings, specifically designed for help desk management and/or the Software.</p>
            <p>5.2.	Take note that there are restrictions in the manner in which the Software may be used, operated, utilized or maneuvered. Please refer to clause 10 of this Agreement for more details on the use of the Software and the limitations of the Software.</p>
            <p>5.3.	Usage of the Software shall require the provision of validated documentation, verified and approved by Adrta employees, assignees or contractors, to meet regulatory and compliance requirements. The User shall be responsible for generating the necessary documents required for validation, which may include but are not limited to, user manuals, technical specifications, test plans, and validation protocols. Please note that additional charges may apply for validation services for documentations, if requested by the User from Adrta. The specific charges shall be communicated to the User and invoiced accordingly.</p>
            
            <h3 class="h5 pb-2">6.	CHANGES AND EXTENSIONS</h3>
            <p>6.1.	The User may opt for additional customizations or add-ons for the Software on a monthly basis. The additional charge payable for each additional customization and/or add-on shall be displayed on the official Adrta website.</p>
            <p>6.2.	The User may choose to subscribe to additional customizations or add-ons for the Software on a yearly basis. The charge for each such additional customizations or addon shall be displayed on the official Adrta website.</p>
            <p>6.3.	Such additional customization and/or add-on charges are applicable only if the User selects additional features beyond the standard offering of the Software. The User shall be notified of the applicable charges for customizations or add-ons prior to making any selections. Any changes to the customization and/or add-on charges shall be communicated to the User in advance.</p>
            <p>6.4.	Users are granted the flexibility to upgrade or downgrade their Subscription Plans of the Adrta Platform, based on their evolving needs and requirements. Users may choose to upgrade their Subscription Plan of the Adrta Platform to a higher tier to access additional features or services offered by the Adrta Platform. The upgraded plan shall take effect immediately upon confirmation of the upgrade request, and any applicable prorated charges shall be adjusted and invoiced accordingly. Similarly, Users may opt to downgrade their Subscription Plan of the Adrta Platform to a lower tier, if their specific usage requirements change. The downgraded plan shall take effect at the end of the current billing cycle, and any adjustments to Subscription Fees shall be reflected in the subsequent billing cycle. Users may request modifications to the Subscription Plan of the Adrta Platform, through the designated channels, as displayed, provided and/or informed by the Company, from time to time. The Company reserves the right to approve or deny any modification requests made by the Users at its sole discretion. </p>
            <p>6.5.	However, it is worth noting that modifications to the Subscription Plan of the Adrta Platform are subject to the availability of the chosen plan and the terms outlined in this Agreement. Users are responsible for reviewing the features and pricing associated with each Subscription Plan of the Adrta Platform, before requesting modifications and any changes or modifications to the Subscription Fees by Adrta, resulting from plan modifications which shall be promptly communicated to the User, in advance.</p>
            
            <h3 class="h5 pb-2">7.	REVOKING AND TERMINATION LEASE </h3>
            <p>7.1.	This Agreement and your rights herein to use the Software shall terminate immediately, if you fail to comply with all of the terms and conditions of this Agreement. Upon termination, you agree to immediately cease using the Software and you must destroy or delete your copies of the Software and Documentation. Adrta also reserves the right to revoke a User's license in case Adrta deems that the terms and conditions of this Agreement are not strictly adhered to. </p>
            <p>7.2.	Users shall have the option to cancel their subscriptions at any time. Upon cancellation, access to the Adrta Platform and the associated services, the license to the Adrta Platform and the associated services shall continue until the end of the current billing cycle. After the end of the billing cycle, the User's access to the Adrta Platform and the services shall be terminated. Moreover, it is hereby declared that no refunds or credits shall be issued for the partial use of the Subscription Period, remaining at the time of cancellation of the Subscription Period, by the User. </p>
            <p>7.3.	However, Users are responsible for initiating the cancellation process through the designated channels, as may be updated and/or provided by the Company and/or as updated on the Website, from time to time and any outstanding fees or charges accrued prior to the cancellation date shall remain payable by the User.</p>
            <p>7.4.	The terms agreed to in clause  8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19 and 21 shall remain in force after the termination or the expiry of this Agreement.</p>
            
            <h3 class="h5 pb-2">8.	CONFIDENTIALITY</h3>
            <p>8.1.	The User acknowledges that it may have access to Confidential Information of Adrta in connection with this Agreement, and that Adrta’s Confidential Information is of substantial value to Adrta, which could be impaired if it were improperly disclosed to third parties or used in violation of this Agreement.</p>
            <p>8.2.	The recipient of the Confidential Information, being the User, under this Agreement must:</p>
            <div class="sub-clause">
            <p>8.2.1.	keep Adrta’s Confidential Information confidential and protect it at least to the same extent it protects its own Confidential Information and to the same extent that a reasonable person would protect such Confidential Information;</p>
            <p>8.2.2.	not use Adrta’s Confidential Information in any way for its own account or the account of any third party, except to perform its duties, exercise its rights or is otherwise authorized under this Agreement; and</p>
            <p>8.2.3.	not disclose Adrta’s Confidential Information, except to perform its duties or exercise its rights under this Agreement or as otherwise authorized under this Agreement, provided that:</p>
            <div class="sub-clause">
            <p>8.2.3.1.	any disclosure made to the User’s employees, contractors or agents is on a need-to-know basis; and</p>
            <p>8.2.3.2.	the User’s employees, contractors or agents in receipt of the Confidential Information are under an obligation of confidentiality, no less stringent than that set forth in this sub clause.</p>
            </div>
            </div>

            <p>8.3.	Notwithstanding the restrictions in sub clause 8.2, if the User is required to disclose any of the Adrta’s Confidential Information by law, such as in response to a court order, subpoena or requirement of any regulator, court, arbitral, administrative, or legislative body and the User must:</p>
            <div class="sub-clause">
            <p>8.3.1.	where reasonably possible and permitted, immediately provide written notice to Adrta of the required disclosure to give Adrta an opportunity to move for a protective order or otherwise prevent the disclosure;</p>
            <p>8.3.2.	disclose only the minimum amount of Confidential Information required to satisfy the legal obligation; and</p>
            <p>8.3.3.	assert and take proper steps with the body requiring disclosure to maintain the confidentiality of the Confidential Information to be disclosed.</p>
            </div>

            <p>8.4.	The User shall immediately, and at least within seventy-two (72) hours, notify Adrta if Confidential Information of Adrta is used or disclosed, in breach with the terms of this Agreement. As monetary damages may not be sufficient relief, if anyone violates or threatens to violate the terms of this sub clause, Adrta is immediately entitled to enforce its rights by specific performance or injunction proceedings, in addition to any other rights or remedies it may have.</p>
            <p>8.5.	Upon Adrta’s request and upon termination of this Agreement (unless agreed otherwise by the parties at the time), the User will return, destroy or delete permanently (at Adrta’s election) Adrta’s Confidential Information.</p>
            <p>8.6.	On termination of this Agreement, the User must continue to keep Adrta’s Confidential Information confidential for five (5) years in accordance with this sub clause.</p>
            
            <h3 class="h5 pb-2">9.	NO OTHER RIGHTS GRANTED </h3>
            <p>9.1.	Except as expressly set forth herein, Adrta grants and you warrant to receive no other rights or licenses to the Software, or any intellectual property rights related to the Software, whether by implication, estoppel or otherwise.</p>
            
            <h3 class="h5 pb-2">10.	LIMITATIONS OF USE</h3>
            <p>10.1.	Following are the limitations of use related to the Software and Documentation, as notified in this Agreement or as may be notified or displayed on the Website: </p>
            <div class="sub-clause">
            <p>10.1.1.	The use of the Software is limited to the purposes outlined in this Agreement, which shall include but not be limited to providing assistance and support services to the employees, assignees or contractors of the User, for an additional compensation.</p>
            <p>10.1.2.	Users are prohibited from using the Software for any unlawful, unauthorized, or malicious activities, including but not limited to:</p>
            <div class="sub-clause">
            <p>10.1.2.1.	Engaging in any form of hacking, unauthorized access, or tampering with the software or its associated data;</p>
            <p>10.1.2.2.	Distributing spam, malware, viruses, or any other harmful content through the software;</p>
            <p>10.1.2.3.	Violating the privacy or intellectual property rights of third parties; and</p>
            <p>10.1.2.4.	Engaging in any activities that may disrupt or interfere with the operation or security of the Software or its associated systems.</p>
            </div>
            <p>10.1.3.	Users are also prohibited from sublicensing, reselling, or otherwise commercializing the Software and/or the Documentation without prior written consent from the Company.</p>
            <p>10.1.4.	The Company reserves the right to monitor the use of the Software and take appropriate action, including suspension or termination of access, in cases of suspected misuse or violation of this Agreement.</p>
            <p>10.1.5.	Any copyright, acknowledgment, attribution, trademark, warning or disclaimer statement affixed to, related to, or otherwise applied in connection with the Software and/or the Documentation must not be removed, obscured or changed.</p>
            <p>10.1.6.	You shall not combine the Software with any other software, program or application that is licensed in any manner that could cause, or could be interpreted or asserted to cause, the Software, or any modifications thereto, to become subject to different licensing terms and conditions as those set forth in this Agreement.</p>
            </div>

            <p>10.2.	The Software shall be licensed for lawful and ethical purposes only. The following activities are strictly prohibited by the terms of this Agreement and breach of the below mentioned terms shall constitute a material breach of the terms of this Agreement:</p>
            <div class="sub-clause">
            <p>10.2.1.	General restrictions. The User may not, and may not cause or allow any third party to:</p>
            <div class="sub-clause">
            <p>10.2.1.1.	decompile, disassemble or reverse-engineer the Adrta Platform, or create or recreate the source code for the Software;</p>
            <p>10.2.1.2.	remove, erase, obscure or tamper with any copyright or any other product identification or proprietary rights notices, seal or instructional label printed or stamped on, affixed to, or encoded or recorded in or on any Software or Documentation; or fail to preserve all copyright and other proprietary notices in all copies the User makes of the Software and Documentation;</p>
            <p>10.2.1.3.	lease, lend or use the Software for time sharing or service bureau purposes; sell, market, license, sublicense, distribute or otherwise grant to any person or entity any right to use the Software except to the extent expressly permitted in this Agreement; or use the Software to provide, alone or in combination with any other product or service, any product or service to any person or entity, whether on a fee basis or otherwise;</p>
            <p>10.2.1.4.	modify, adapt, tamper with, translate or create derivative works of the Software or the Documentation; combine or merge any part of the Software or Documentation with or into any other software or documentation; or refer to or otherwise use the Software as part of any effort to develop software (including any routine, script, code, or program) having any functional attributes, visual expressions or other features similar to those of the Software to compete with Adrta;</p>
            <p>10.2.1.5.	except with Adrta’s prior written permission, publish any performance or benchmark tests or analysis relating to the Software;</p>
            <p>10.2.1.6.	attempt to do any of activities in sub clauses 10.2.1.1. to 10.2.1.5; or</p>
            <p>10.2.1.7.	run or operate the Software in a cloud, internet-based computing or similar on-demand computing environment unless otherwise allowed through the explicit written consent by the Company. </p>
            </div>
            <p>10.2.2.	Violence and Hate Speech:</p>
            <div class="sub-clause">
            <p>10.2.2.1.	Using or operating the Software to promote, glorify, or incite violence against individuals or groups based on race, ethnicity, religion, gender, sexual orientation, nationality, disability, or any other protected characteristic.</p>
            <p>10.2.2.2.	Using or operating the Software that facilitates or enables physical or emotional harm to others.</p>
            <p>10.2.2.3.	Using or operating the Software to disseminate contents that depicts or glamorizes violence or violent acts, including but not limited to war, terrorism, or assault.</p>
            </div>
            <p>10.2.3.	Sexual Misconduct and Exploitation:</p>
            <div class="sub-clause">
            <p>10.2.3.1.	Using or operating the Software or to engage in any form of sexual harassment, intimidation, or coercion.</p>
            <p>10.2.3.2.	Using or operating the Software that facilitate or promote child sexual abuse content or exploitation.</p>
            <p>10.2.3.3.	Using or operating the Software to display sexually explicit material that is illegal or harmful, including pornography or content that depicts sexual or non-consensual sexual activity.</p>
 
            </div>

            <p>10.2.4.	Harmful and Unethical Content:</p>
            <div class="sub-clause">
            <p>10.2.4.1.	Using or operating the Software in a way that promote or glorify illegal activities, such as drug trafficking, weapons sales, or organized crime.</p>
            <p>10.2.4.2.	Using or operating the Software to spread misinformation, disinformation, or hate speech.</p>
            <p>10.2.4.3.	Using or operating the Software to engage in activities that may be harmful to children, such as cyberbullying or grooming.</p>
            <p>10.2.4.4.	Using or operating the Software to collect or store personal data without proper consent or for malicious purposes and not in compliance with data privacy laws and statutes of the Republic of India, California, European Union and/or European Economic Area (EEA) or your local jurisdiction.</p>
            </div>

            <p>10.2.5.	Labor Abuses:</p>
            <div class="sub-clause">
            <p>10.2.5.1.	Using or operating the Software in a way that directly or indirectly support or benefit from forced labor, child labor, or other exploitative labor practices.</p>
            <p>10.2.5.2.	Using or operating the Software in a way that promotes or normalizes unfair or unsafe working conditions.</p>         
            </div>

            <p>10.2.6.	Additional Prohibited Activities</p>
            <div class="sub-clause">
            <p>10.2.6.1.	Any use or operation of the Software that violates applicable laws or regulations, including intellectual property rights, privacy laws, data privacy laws, and export controls.</p>
            <p>10.2.6.2.	Using or operating the Software to harm, interfere with, or disrupt the computer systems or networks of others.</p>
            <p>10.2.6.3.	Using or operating the Software to attempt to circumvent or disable any security features of the Software or Documentations.</p>
            </div>           
            </div>
            <p>10.3.	It is hereby declared that the limitations contained in sub clause 10.1. and 10.2. of this Agreement is not exhaustive, and Adrta reserves the right to terminate a license associated with any breach of this Agreement.</p>
            <p>10.4.	Written permission from us along with additional compensation shall be required for any deviations from the terms as mentioned in clause 10.2. of this Agreement and/or other terms of this Agreement, and must be agreed upon and signed by both parties involved.</p>
         

            <h3 class="h5 pb-2">11.	OWNERSHIP</h3>
            <p>11.1.	The nature of this transaction between Adrta and the User is that of a licensing transaction and the agreement between the Adrta and the User is that of a licensing agreement and not an agreement of sale. We reserve ownership of all copyrights, intellectual property rights, title and interest, and all rights other than those expressly granted by this Agreement, inherent in or relating to the Software, Documentation and/or the Adrta Platform, which includes but is not limited to all copyright  and  patent rights, all rights in relation to trademarks also service marks registered or not; any information shared by us, Users or any other party related to us, including trade secrets and know-how, any suggestions, ideas, enhancement requests, feedback, recommendations, or any other information provided by Adrta.</p>
            <p>11.2.	Adrta Technologies retains ownership rights including but not limited to intellectual property rights, as guaranteed under Indian laws and statutes, on the Software and Adrta Platform, as provided to the User under the terms of this Agreement. If the client chooses to independently update, modify, amend or fork the Software installed on their personal electronic device or personal computer, Adrta shall not be held responsible for any issues or consequences arising from such updates, modifications, amendments or forkings. Moreover, the User acknowledges that independent updates performed by the User may affect the functionality, performance, or compatibility of the Software or the Adrta Platform, and they assume all associated risks and responsibilities.</p>
            <p>11.3.	You hereby agree to indemnify us against all actions, claims, proceedings, damages, costs and expenses arising from any actual or alleged infringement of intellectual property rights that you become aware of, and that you will notify us within three (3) days of your knowledge of any such actual or alleged infringement.</p>
            <p>11.4.	Any copyright, acknowledgment, attribution, trademark, warning or disclaimer statement affixed to, related to, or otherwise applied in connection with the Software or the Adrta Platform must not be removed, obscured or changed.</p>
            
            <h3 class="h5 pb-2">12.	MAINTENANCE, SUPPORT & IMPLEMENTATION</h3>
            <p>12.1.	Adrta’s Software offers comprehensive error correction, bug fixing, and implementation services across all licensed locations. However, licensed locations may change from time to time and may be informed from time to time on the Website and a list of all licensed locations shall be displayed on the Website. The Adrta employees, assignees or contractors may collaborate with the Users and User’s employees, to identify and resolve issues encountered during the implementation of the Software. Additionally, Adrta employees, assignees or contractors may provide comprehensive one-time training sessions to empower Users and User’s employees or contractors, to efficiently and accurately utilize the Adrta software. Please note that any additional training sessions beyond the initial session shall be subject to additional charge.</p>
            <p>12.2.	Customization of the Software, as well as data import or migration for existing data, shall incur additional charges based on specific requirements of the User. These additional charges shall be communicated to the User and invoiced accordingly.</p>
            <p>12.3.	Maintenance and support services for the Software are provided based on the Subscription Plan opted by the User and such maintenance and support services shall only be provided during the Subscription Period. The level of maintenance and support available to the User may vary depending on the Subscription Plan opted by the User, and details regarding the scope and availability of maintenance and support services may be outlined in the respective plan documentation and/or may be displayed on the Website. However, free maintenance and support service shall not be made available for the Software and Documentations, without the payment of additional charges. Users are required to subscribe to a suitable plan to access maintenance and support services.</p>
            <p>12.4.	Users may submit maintenance requests through the designated channels provided by the Company, including but not limited to email, online ticketing systems, or customer support portals. Maintenance requests shall include a detailed description of the issue or enhancement request.</p>
            <p>12.5.	The Company shall use commercially reasonable efforts to acknowledge receipt of maintenance requests within a reasonable timeframe and to provide an initial response indicating the receipt of the message and the estimated timeline for resolution or implementation of the requested maintenance. However, maintenance requests may be categorized into priority levels based on the severity and impact of the issue or enhancement request, as determined by the Company. The Company shall allocate resources and prioritize maintenance efforts accordingly.</p>
            <p>12.6.	The Company may, at its sole discretion, release software updates, patches, or enhancements to the Software. Such updates and enhancements may be provided free of charge or as part of the Subscription Plan or a separate maintenance agreement, as determined by the Company. However, Maintenance Services do not cover issues arising from: </p>
            <div class="sub-clause">
            <p>12.6.1.	User error or misuse of the Software or Documentation;</p>
            <p>12.6.2.	modifications or alterations to the Software made by anyone other than the Company; </p>
            <p>12.6.3.	integration with third-party software or services not authorized or supported by the Company; or </p>
            <p>12.6.4.	force majeure events or circumstances beyond the Company's reasonable control.</p>
            </div>
            <h3 class="h5 pb-2">13.	AUDIT</h3>
            <p>13.1.	We or a third party sent, hired or authorized by us, may at our expense, audit your use of the Adrta Platform. Should there be any material violation of the use of either, you shall be required to repay the reasonable costs of auditing. We also reserve the right to terminate or revoke your license and undertake any other remedies available under applicable law. </p>
            <p>13.2.	If the terms of this Agreement have been breached, you agree to pay us any amounts owing that are attributable to the unauthorized use. Also, we reserve the right, at our discretion, to terminate your license for the Software, in addition to seeking any other remedies available under the law of the Republic of India. This section shall remain in force for two (2) years after the termination and/or the expiry of this Agreement.</p>
            
            <h3 class="h5 pb-2">14.	DISCLAIMER OF WARRANTIES</h3>
            <p>14.1.	You hereby understand, warrant and agree that:</p>
            <div class="sub-clause">
            <p>14.1.1.	The Adrta Platform may contain bugs, errors and other technical and non-technical problems that could cause system or other failures, damage and data loss. Consequently, the Software and any related outcome of its use is provided to you on an “as is” basis and Adrta disclaims any warranty or liability obligations of any kind to you, your clients, end-users, suppliers or anyone else affected by these occurrences. Where legal liability cannot be excluded, but it may be limited, Adrta’ liability and that of its suppliers and affiliates shall be limited to the sum of ten dollars (U.S. $10) in total.</p>
            <p>14.1.2.	The Software, any use or operation of the Software, the results obtained through any use of the Software, the Documentation, any modifications or omissions or updates to the Software or Documentation, any trade and any other course of dealing related to the Software or Documentation, comes without any warranty, guarantee or representation, and no other warranties, either express or implied, are made with respect to these, including but not limited to the correctness, accuracy, security, timeliness, reliability, currency, lack of errors, lack of interruption, lack of protection, merchantability or fitness for a particular purpose, title and non-infringement. You hereby acknowledge that it is not possible for us to provide any warranties.</p>
            <p>14.1.3.	No oral or written information or advice given by anyone, and specifically but not limited to owners and employees of Adrta, and anyone involved in the management, creation, production, support, training, sales or delivery of the Software or Documentation shall create a warranty or in any way increase the scope of this warranty, and you shall not rely on any such information or advice.</p>
            <p>14.1.4.	Adrta, its employees, and affiliated persons may update or modify the Software at any time, in any way, and without notice, but shall not be obligated to support, modify or update the Software. </p>
            <p>14.1.5.	The Adrta Platform may contain independent third-party products and rely on them to perform certain functionalities and Adrta makes no warranty as to the operation of any third-party products or the accuracy of any third-party information.</p>
            <p>14.1.6.	THE SOFTWARE MAY FAIL AND IS NOT DESIGNED, DEVELOPED, TESTED, OR INTENDED TO BE RELIABLE IN THE CONTEXT OF HIGH-RISK SYSTEMS. ADRTA HAS NO RESPONSIBILITY FOR, AND THE USER WILL INDEMNIFY AND HOLD HARMLESS ADRTA FROM, ALL CLAIMS, SUITS, DEMANDS AND PROCEEDINGS ALLEGING, CLAIMING, SEEKING, OR ASSERTING ANY LIABILITY, LOSS, OBLIGATION, RISK, COST, DAMAGE, AWARD, PENALTY, SETTLEMENT, JUDGMENT, FINE OR EXPENSES (INCLUDING ATTORNEY FEES) ARISING FROM OR IN CONNECTION WITH THE USER’S USE OF THE SOFTWARE ON OR IN A HIGH RISK SYSTEM, INCLUDING THOSE THAT COULD HAVE BEEN PREVENTED BY DEPLOYMENT OF FAIL-SAFE OR FAULT-TOLERANT FEATURES TO THE HIGH RISK SYSTEM, OR ARE BASED ON A CLAIM, ALLEGATION, OR ASSERTION THAT THE FUNCTIONING OF HIGH RISK SYSTEM DEPENDS OR DEPENDED ON THE FUNCTIONING OF THE SOFTWARE, OR THAT THE FAILURE OF THE SOFTWARE CAUSED A HIGH RISK SYSTEM TO FAIL.</p>
          
            </div>
            <h3 class="h5 pb-2">15.	OTHER DISCLAIMERS</h3>
            <p>15.1.	You assume the entire risk, liability, and resulting costs related to your and your employees, assignees or contractors use of the Software. This includes but is not limited to the results, actions, operation and performance of the Software. You must accept the entire cost and responsibility of all related damage, liability, obligation, servicing, support, maintenance, repair and correction of your employee’s, assignee’s and contractor’s use of the Software.</p>
            <p>15.2.	You accept that neither Adrta, its employees, affiliated persons, nor anyone involved in the creation, production, support, training, sales or delivery of the Software or Documentation shall be liable, in any way for any special, incidental, direct, indirect, exemplary, consequential or any other type of damage, regardless of the legal theory on which they are based, arising out of any dealing with, or the use of, or inability to use the Software (including, but not limited to, procurement of substitute goods or services; business interruption; loss of use, data, media, or information; loss of income, savings, profits or the potential thereof), even if Adrta has been advised of the possibility of such damages. We are licensing the Software on an “as is” basis, and hence the use of the Software, by you, shall be at your own risk and we disclaim any warranty of any kind or liability to you or anyone else.</p>
            <p>15.3.	The foregoing limitations of liability will apply even if the above stated warranty fails to serve its essential purpose. Each party acknowledges that the subscription fees set forth in this Agreement reflects the allocation of risk and responsibility agreed upon and deemed reasonable by the parties and that they would not enter into this Agreement without these limitations of liabilities.</p>
            
            <h3 class="h5 pb-2">16.	THIRD PARTY SOFTWARE</h3>
            <p>16.1.	The Software makes use of, and is distributed with, software (“Third-Party Software”) supplied by third-parties. </p>
            <p>16.2.	The Third-Party Software, and its use, is made available to you by permission of the respective licensors and/or copyright holders on the terms provided by such parties, including those terms required to be provided to you that are set forth below, and subject also to the Agreement applicable to the Software. Without limiting the terms in the Agreement, we expressly disclaim any warranty or other assurance to you regarding the Third-Party Software. The following terms relate only to the Third-Party Software identified below and not to the Software or Documentation.</p>
            <p>16.3.	Credits for the work performed by the respective licensors and/or copyright holders is due to the respective licensors and/or copyright holders. Copies of all Third Party Software included and the applicable license agreements can be found through a quick search on the internet. You are recommended to study the respective license agreements of such Third Party Softwares before you use such Third Party Softwares. </p>
            <p>16.4.	Any Third Party Software is provided “as is”, without any warranty and you specifically agree that we cannot be responsible for any support, faults, failures, errors, corrections or maintenance relating to the Third Party Software, nor the availability of updates and upgrades. We shall not have any liability under this Agreement, regardless of the nature of the claim or supposed damages of any kind, for any claim arising from or related to your use or distribution of the Third Party Software.  We disclaim any and all representations and warranties, express, implied or statutory, with respect to any Third Party Software, including but without limitation, any warranties of merchantability, fitness for a particular purpose, system integration, data accuracy, title, non-infringement, quiet enjoyment, and/or non-interference.</p>

            <h3 class="h5 pb-2">17.	PAYMENT AND TAXES</h3>
            <p>17.1.	All charges and fees to be paid under this Agreement are exclusive of any applicable sales, use, GST, VAT, service tax or other local or international tax. Any such transaction tax shall be your sole responsibility and you shall pay all taxes, fees or charges reflected in your account according to the taxes, fees, charges, and billing terms in effect at the time a fee or charge is due and payable. We do not collect or remit sales and use taxes.  Subscription Fees are quoted and payable in Indian Rupees and/or in any other currency we may accept the Subscription Fees in.  </p>
            <p>17.2.	Payment obligations cannot be canceled or reduced, and once Subscription Fees are paid, they shall be deemed non-refundable.</p>
            <p>17.3.	All payments or Subscription Fees due from the User shall be made free and clear and without deduction for any present and future taxes imposed by any taxing authority. If the User is required by applicable law to deduct or withhold income taxes from amounts payable to Adrta under this Agreement (“Withholding Taxes”), the User will remit, and provide Adrta with evidence that the User has remitted, the Withholding Taxes to the appropriate taxing authority and pay to Adrta the remaining net amount. The User will provide written notice to Adrta of its intent to withhold (including details of the amounts and legal basis for Withholding Taxes) at least fifteen (15) days before the due date for any payments under this Agreement and will cooperate with Adrta to reduce any Withholding Taxes. If Adrta provides the User with valid and official documentation issued by the relevant taxing authority for a lower rate of Withholding Taxes, then the User will apply the lower rate.</p>
            <p>17.4.	Each party shall be responsible for its own income taxes or taxes based on gross revenues or gross receipts.</p>
            
            <h3 class="h5 pb-2">18.	COMPLIANCE WITH LAWS</h3>
            <p>18.1.	Each party shall be liable to comply with the applicable national, state and local laws with respect to its rights and obligations under this Agreement, including applicable privacy and export control laws and regulations, the U.S. Foreign Corrupt Practices Act, and other applicable anti-corruption laws. The User shall not use, transfer or access any Software for end use relating to any nuclear, chemical or biological weapons, or missile technology unless authorized by the U.S. Government by regulation or specific license.</p>
            <p>18.2.	If Adrta receives notice that the User is or becomes identified as a sanctioned or restricted party under applicable law, Adrta will not be obligated to perform any of its obligations under this Agreement, if such performance would result in violation of the sanctions or restrictions.</p>
            
            <h3 class="h5 pb-2">19.	NOTIFICATIONS AND CUSTOMER INFORMATION</h3>
            <p>19.1.	You accept the responsibility of ensuring that we can contact you for the duration of this Agreement by registering on our Website, and keeping the registered email address we have for you on our system valid and up to date. Your email address and the use thereof shall be protected in accordance with our Privacy Policy.</p>
            <p>19.2.	Adrta may provide notifications, whether such notifications are required by law or are for marketing or other business related purposes. Adrta reserves the right to determine the form and means of providing notifications to you, provided that you may opt out of certain means of notification as provided. However, Adrta shall not be responsible for any automatic filtering, you or your network provider may apply to email notifications, we send to the email address you provide to us.</p>
            <p>19.3.	Your company or organization agrees to be identified as a customer of Adrta and you agree that we may refer to your company or organization by name, logo, trade name and/or trademark, if applicable, and may briefly describe your company or organization in our marketing materials, portfolio(s) and website.</p>
            
            <h3 class="h5 pb-2">20.	FORCE MAJEURE </h3>
            <p>20.1.	Neither party shall be liable for delays or failures to perform any of its obligations under this Agreement to the extent caused by a Force Majeure Event. However, either party shall have the responsibility to inform the other party about the extent of the Force Majeure Event within a period of sixty (60) days. </p>
            <p>20.2.	Adrta’s failures or delays in its performance are excused to the extent they result from:</p>
            <div class="sub-clause">
            <p>20.2.1.	User’s acts or omissions, or those of its employees, agents, users, affiliates or contractors;</p>
            <p>20.2.2.	notwithstanding the generality of sub clause 20.2.1., User’s failure or delay in the performance of a specific task, obligation or responsibility under this Agreement, which task, obligation, or responsibility is a condition or requirement for a Adrta task, obligation, or responsibility;</p>
            <p>20.2.3.	reliance on instructions, authorizations, approvals or other information from Company; or</p>
            <p>20.2.4.	acts or omissions of third parties (unless directed by Adrta).</p>         
            </div>

            <h3 class="h5 pb-2">21.	CHOICE OF LAW</h3>
            <p>21.1.	This license is governed by the laws of the Republic of India. For any dispute with Adrta, you agree to first contact us at support@adrtatech.com and attempt to resolve the dispute with us through good faith discussions and/or mediation as per the The Mediation Act of 2023. In the unlikely event that we have not been able to resolve a dispute through mediation within a period of sixty (60) days, any and all disputes related to this Agreement shall be settled by the nearest court (having proper jurisdiction) located in Sola, Ahmedabad, Gujarat, India. A court in a different country may be used to settle disputes, subject to agreement by both parties to make use of it.</p>
            
            <h3 class="h5 pb-2">22.	ENTIRE AGREEMENT</h3>
            <p>22.1.	You agree that this is the complete agreement for the Software, Documentations and licenses, and this Agreement supersedes all prior agreements or representations. If any of the terms and conditions of this Agreement are found to be invalid or unenforceable, the remaining provisions will remain effective. If any remedy provided is determined to have failed for its essential purpose, all limitations of liability and exclusions of damages set forth in this Agreement shall remain in effect. </p>
            
            <h3 class="h5 pb-2">23.	ASSIGNMENT</h3>
            <p>23.1.	The User may not sublicense, assign or transfer its rights under this Agreement without Adrta’s prior written consent. Any attempt by the User to sublicense, assign or transfer any of its rights, duties or obligations under this Agreement, whether directly, or indirectly by merger, acquisition or change of control, shall be null and void.</p>
            
            <h3 class="h5 pb-2">24.	RELATIONSHIP</h3>
            <p>24.1.	The parties to this Agreement shall have a contractual relationship and expressly disclaim any partnership, franchise, joint venture, agency, employer/employee, fiduciary or other special relationship. Neither party intends this Agreement to benefit or create any right or cause of action in or on behalf of, any person or entity other than the parties and listed affiliates. This Agreement is not intended to create a third-party beneficiary of any kind. The User must not represent to any third party that it has any right to bind Adrta in any manner and the User shall not make any representations or warranties on behalf of Adrta.</p>
            
            <h3 class="h5 pb-2">25.	SEVERABILITY </h3>
            <p>25.1.	If a court holds that any provision of this Agreement is invalid or unenforceable under applicable law, the court will modify the provision to the minimum extent necessary to make it valid and enforceable or, if it cannot be made valid and enforceable, the court will sever and delete the provision from this Agreement. The change will affect neither the validity of the amended provision nor the validity of any other provision of this Agreement, which will continue in full force and effect.</p>
            
            <h3 class="h5 pb-2">26.	NO WAIVER </h3>
            <p>26.1.	A party’s failure or delay in enforcing any provision of this Agreement will not operate as a waiver of the right to enforce that provision or any other provision of this Agreement at any time. A waiver of any provision of this Agreement must be in writing, specify the provision to be waived and signed by the party agreeing to the waiver.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EulaDetails;
