import React from 'react';

const EyesContentFour = () => {
    return (
        <>
            <section className='ptb-120 light-red-bg'>
                <div className='container'>
                    <div class="row justify-content-center text-center">
                        <div className='col-md-10'>
                        <h2 className='title2 pb-2 '>Discover Unseen Possibilities with Zentixs Eye</h2> 
                        <p className=''>Explore unique experiences and uncover unseen possibilities that elevate your journey. <br/>  Embrace a new perspective and transform your aspirations into reality today!
</p>                         
                        </div>
                    </div>
                    <div className='pb-0 text-center pt-5'>
                        <img
                            src='assets/img/eye.svg'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default EyesContentFour;