import React from 'react';
import SectionTitle from '../common/SectionTitle';

const TechnologiesContentOne = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'ptb-120'
        } `}
      >
        <div className='container'>
        <div class="row justify-content-center"><div class="col-lg-8 col-md-10"><div class="section-heading text-center">
          <h2 class="title2"> Meeting Your Technology Needs<br />
           Effectively and Efficiently</h2>
        
          </div>
          </div>
          </div>
          <div className='row  justify-content-between'>

            <div className='col-lg-6 order-lg-1'>
              <div className='sticky-sidebar'>
          
                  <img
                    src='assets/img/web-development-office.png'
                    alt=''
                    className='img-fluid rounded-custom '
                  />
                 
                
              </div>
            </div>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-5 box-content'>
                <div className='icon-box'>
                <img
                    src='assets/img/web-development.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Web Development</h3>
                <p>Expertise in creating responsive, dynamic web applications using modern technologies like HTML, CSS, JavaScript, and popular frameworks such as React.js and Angular.</p>                    
              </div>  
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/database.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Database Management</h3>
                <p>Proficiency in designing, optimizing, and managing SQL and NoSQL databases like MySQL, PostgreSQL, MongoDB, and Firebase for efficient data storage and retrieval.</p>                    
              </div>  
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/cloud-host.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Cloud Computing Platforms</h3>
                <p>Experience in deploying and managing applications on AWS, Azure, and Google Cloud, leveraging services like EC2, S3, Azure VMs, and Kubernetes Engine for scalable and resilient infrastructure.</p>                    
              </div>
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/it-infrastructure.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>IT Infrastructure Management</h3>
                <p>Skilled in designing and maintaining robust IT infrastructures, including servers, networks, and storage systems, with a focus on security, monitoring, and disaster recovery.</p>                    
              </div>        
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologiesContentOne;
