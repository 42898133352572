import React from 'react';

export default function SagaContentFour() {
  return (
    <section className="digi-news-letter ptb-120 pt-0">
      <div className="container">
        <div className="bg-light-saga text-light rounded-custom p-4 p-md-5 p-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="digi-newsletter">            
                <h2 className='title2 mb-4'>Analyze and improve <br /> data quality</h2>
                <p className='lead'>
                  Intrinsicly cultivate user-centric collaboration and
                  idea-sharing after value-added processes repurpose unique
                  models and global schemas.
                </p>        
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/d-pie.png"
                  className="img-fluid text-end"
                  alt="pie"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
