import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LogContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Simplify Data Management with Zentixs Logbook</h2>
                <div className='mt-5 mb-5'>
                  <h4 className='h4'>Data Integrity and Backup</h4>
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Ensures that data is securely stored, backed up, and protected from tampering or loss.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Supports disaster recovery mechanisms in case of system failures.
                      </span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className='h4'>Integration with Other Systems</h4>
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Can be integrated with manufacturing execution systems (MES), laboratory information management systems (LIMS), or enterprise resource planning (ERP) systems.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Allows for seamless data exchange and enhanced visibility across processes.
                      </span>
                    </li>
                  </ul>                   
                  </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/screen/feature.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogContentTwo;
