import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import TermsDetails from '../../components/legal/TermsDetails';
import PageHeader from '../../components/common/PageHeader';


const Terms = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <PageHeader
        title="Terms of Service" />      
      <TermsDetails />      
      <FooterOne />
    </Layout>
  );
};

export default Terms;
