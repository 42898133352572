import React from 'react';
import SectionTitle from '../common/SectionTitle';

const QmsContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2 mb-32'>Achieve Compliance, <br /> Enhance Quality, Drive Growth
</h2>

<p>Our software stands as a sentinel, guarding both product quality and process enhancements. Through its robust framework, it champions compliance with regulatory giants like US FDA and ISO 9001:2015, ensuring product strength, quality, and purity. </p>
            <p>Guided by ICH Q10 and ISO principles, it fosters employee involvement, customer focus, and a process-oriented ethos. For organizations, it becomes the catalyst for maintaining quality control while embarking on an unwavering voyage of continuous improvement.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/feature-hero-img.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QmsContentTwo;
