import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-60 pb-60`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>           
                       
                <div className='col-md-3 col-lg-3 mt-2 mt-md-0 mt-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/img/logo-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/img/logo-color.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>      
                  <p className="footer-entry">Have a question? We’re here to help. <br/> Call us at +91 9664863015</p>
                  
                </div>
                  </div>
                  <div className='col-md-2 col-lg-2 mt-2 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Adrta</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to='/about' className='text-decoration-none'>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/leadership' className='text-decoration-none'>
                           Leadership
                          </Link>
                        </li>
                        <li>
                          <Link to='/career' className='text-decoration-none'>
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/customers'
                            className='text-decoration-none'
                          >
                            Customers
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/contact'
                            className='text-decoration-none'
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-2 col-lg-2 mt-2 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Support & Success</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/support' className='text-decoration-none'>
                          Support
                          </Link>
                        </li>
                        <li>
                          <Link to='/terms' className='text-decoration-none'>
                          Terms of Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/privacy'
                            className='text-decoration-none'
                          >
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/eula'
                            className='text-decoration-none'
                          >
                            EULA
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/contact-us'
                            className='text-decoration-none'
                          >
                            Security
                          </Link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3 mt-2 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Products</h3>
                      <div className='row'>
                      <div className='col'>   <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/zentixs-docs-sop-document-review-control'
                            className='text-decoration-none'
                          >
                           Zentixs Docs
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-talent-lms-training-management'
                            className='text-decoration-none'
                          >
                            Zentixs Talent
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-track-qms-quality-management-incident-capa-change-control'
                            className='text-decoration-none'
                          >
                            Zentixs Track
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-eye-department-process-supplier-vendor-audit'
                            className='text-decoration-none'
                          >
                            Zentixs Eye
                          </Link>
                        </li>
                        <li>
                          <Link to='/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro' className='text-decoration-none'>
                          Zentixs Labs
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-sign-electronic-digital-signature'
                            className='text-decoration-none'
                          >
                           Zentixs Sign
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-caliber-instrument-equipment-asset-calibration'
                            className='text-decoration-none'
                          >
                           Zentixs Caliber
                          </Link>
                        </li>
                      </ul></div>
                      <div className='col'>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>

                        <li>
                          <Link
                            to='/zentixs-log-eln-electronic-logbook'
                            className='text-decoration-none'
                          >
                           Zentixs Log
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-sprint-project-master-schedule-study-management'
                            className='text-decoration-none'
                          >
                           Zentixs Sprint
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-saga-document-sample-softcopy-archival'
                            className='text-decoration-none'
                          >
                           Zentixs Saga
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-desk-ticket-support-helpdesk-frontdesk'
                            className='text-decoration-none'
                          >
                            Zentixs Desk
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/zentixs-ibook-inventory-chemical-product-store-management'
                            className='text-decoration-none'
                          >
                            Zentixs Ibook
                          </Link>
                        </li>

                      </ul>
                      </div>
                      </div>
                   
                    </div>
                  </div>    
                         
             
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; {year}  Adrta Technologies Pvt Ltd Rights Reserved.                    
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://facebook.com/adrtatech' target='_blank'>
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://twitter.com/adrtatech' target='_blank'>
                    
                      <img
                      src='assets/img/x.svg'
                      alt='logo'
                      className='img-fluid'
                      style={{height:"14px"}}                     
                    /> 
                                      
                      </a>
                     
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/company/adrta/' target='_blank'>
                      <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
