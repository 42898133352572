import React from 'react';
import SectionTitle from '../common/SectionTitle';

const TechnologiesContentThree = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-0' : 'ptb-0'
        } `}
      >
        <div className='container'>     
          <div className='row  justify-content-between'>
       
            <div className='col-lg-4 col-md-6'>
              <div className='mb-5 box-content'>
                <div className='icon-box'>
                <img
                    src='assets/img/mobile-application.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Mobile Application Development</h3>
                <p>Skilled in native and cross-platform mobile app development for iOS and Android using Swift, Kotlin, React Native, and Flutter, delivering intuitive interfaces and optimal performance.</p>                    
              </div>  
              </div>           
            <div className='col-lg-4 col-md-6'>
              <div className='mb-5 box-content'>
                <div className='icon-box'>
                <img
                    src='assets/img/data.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Data Analysis and Visualization</h3>
                <p>Expertise in Python libraries like Pandas, Matplotlib, and Tableau for extracting insights from data and creating interactive visualizations to facilitate informed decision-making.</p>                    
              </div>          
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='mb-5 box-content'>
                <div className='icon-box'>
                <img
                    src='assets/img/it-support.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>IT Support and Troubleshooting</h3>
                <p>Strong troubleshooting skills and proactive support ensure timely resolution of technical issues, minimizing downtime and ensuring smooth operation of systems and services.</p>                    
              </div>          
            </div>
          
        </div>
        </div>
      </section>
    </>
  );
};

export default TechnologiesContentThree;
