import React, { Component } from 'react';
import SectionTitle from '../common/SectionTitle';
import TestimonialModal from './TestimonialModal';
import Rating from '../review/Rating';

class TestimonialOne extends Component {
  render() {
    const { darkBg } = this.props;
    return (
      <>
        <section
          className={`customer-review-tab ptb-120 ${
            darkBg ? 'bg-gradient text-white' : ''
          } position-relative z-2`}
        >
          <div className='container'>
            <div className='row justify-content-center align-content-center'>
              <div className='col-md-10 col-lg-6'>
                {darkBg ? (
                  <SectionTitle                  
                    title='What They Say About Us'                  
                    dark
                    centerAlign
                  />
                ) : (
                  <SectionTitle                 
                    title='What They Say About Us'                   
                    centerAlign
                  />
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='tab-content' id='testimonial-tabContent'>
                  <div
                    className='tab-pane fade active show'
                    id='testimonial-tab-1'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                         
                          <blockquote className='blockquote'>
                            <p>
                            I am Dr. Ravinder Sreedasyam, representing AXIS Clinicals. Our search for quality assurance led us to Mr. Ramesh Patel, while concurrently embarking on an electronic training software initiative. Remarkably, this software seamlessly mirrors our manual workflows, a testament to Mr. Patel's visionary guidance. The software, aligning perfectly with our projections, now governs document issuance control and more across all AXIS Clinicals branches. Adrta's remarkable integration extends beyond Hyderabad. We've entrusted Adrta software for over 5+ years, free from concerns. Regulatory bodies have scrutinized the software extensively, yet no observations have surfaced. Adrta's prowess is truly exceptional.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Dr. Ravinder Sreedasyam</h6>
                            <span>AXIS Clinicals Limited</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='assets/img/testimonial/dr-ravinder-sreedasyam.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='dr ravinder sreedasyam'
                            />                            
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-2'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />                         
                          <blockquote className='blockquote'>
                            <p>
                            I am thrilled to share my experience with Adrta Technologies Private Limited. Their software solution has been an absolute game-changer for our business. The level of compliance it offers across all regulations is truly exceptional, giving us peace of mind in a complex landscape. What truly sets them apart is their commitment to after-sales service. Their support team has consistently gone above and beyond to assist us, ensuring that our transition and ongoing usage have been nothing short of seamless.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Mr. Viral Patel</h6>
                            <span>Sycon Clinical Research Pvt. Ltd.</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='assets/img/testimonial/mr-viral-patel.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />                       
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-3'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                         
                          <blockquote className='blockquote'>
                            <p>
                            Having personally utilized various software solutions from Adrta Technologies Private Limited, I can attest to their seamless functionality without any disruptions. Throughout the entire journey of implementing and maintaining new software solutions, Adrta's support has been truly commendable. Their service is devoid of any time gaps, and they have consistently delivered what was promised, in alignment with their proficient, courteous, and readily accessible team. I extend my best wishes to Adrta Technologies for continued excellence in their future endeavors
                           </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Alkem Laboratories limited</h6>
                            <span>Mumbai, Maharashtra</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='assets/img/testimonial/alkem-lab.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />                         
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-4'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />                       
                          <blockquote className='blockquote'>
                            <p>
                            We sincerely appreciate your efficient, gracious customer service, the level of detail and accountability your firm has demonstrated in the implementation of each module. In addition to this, your team's level of dedication and professionalism has impressed us.  
                            </p>
                            <p>Moreover, we are really impressed by the timely support that you have been providing us. This has been greatly helping us to complete our tasks on time. We are very hopeful that you will continue to do so. 
</p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Vitely Bio LLP</h6>
                            <span>Ahmedabad, Gujarat</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='assets/img/testimonial/vitely-bio-llp.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />                         
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-5'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />                        
                          <blockquote className='blockquote'>
                            <p>
                            I am truly impressed by the exceptional software and unparalleled service provided by Adrta Technologies Private Limited. As a professional working in a highly regulated industry, compliance is non-negotiable. Their all modules of the zentixs software are not only meets but exceeds all regulatory requirements, providing me with a sense of security and peace of mind. I wholeheartedly recommend  Adrta Technologies Private Limited  to anyone seeking a compliance solution backed by top-notch service. With them, you're not just a client; you're a valued partner on a journey towards regulatory success.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Mr. Kartik Vikani</h6>
                            <span>Intervein Research Labs Pvt. Ltd.</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='assets/img/testimonial/mr-kartik-vikani.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />                            
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <ul
                  className='nav nav-pills testimonial-tab-menu mt-60'
                  id='testimonial'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-1'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='assets/img/testimonial/1.jpg'
                          width='50'
                          className='rounded-circle '
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Dr. Ravinder</h6>
                        <span>AXIS Clinicals Limited</span>
                      </div>
                    </div>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-2'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='assets/img/testimonial/2.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Mr. Viral Patel</h6>
                        <span>Sycon Clinical Research</span>
                      </div>
                    </div>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-3'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='assets/img/testimonial/3.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Alkem Lab</h6>  
                        <span>Mumbai, Maharashtra</span>                      
                      </div>
                    </div>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-4'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='assets/img/testimonial/4.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Vitely Bio LLP</h6>
                        <span>Ahmedabad, Gujarat</span>
                      </div>
                    </div>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-5'
                      role='tab'
                      aria-selected='true'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='assets/img/testimonial/5.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Mr. Kartik Vikani</h6>
                        <span>Intervein Research Labs</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TestimonialOne;
