import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTalent = () => {
  return (
    <>
      <section className='faq-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                     What is Zentixs Talent Software?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Talent Software is a comprehensive training management solution designed to streamline employee training processes, ensuring compliance with industry standards, particularly in the pharmaceutical sector.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      What features does Zentixs Talent Software offer for employee training?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The software includes features such as new employee orientation, SOP training, training needs assessment, documentation, compliance training, and more, all aimed at enhancing workforce competency and compliance.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                     How does the New Employee Orientation feature work?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The New Employee Orientation feature provides onboarding training to help new hires understand company policies, safety protocols, and job-specific compliance expectations, ensuring a smooth transition into the organization.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What is SOP Training, and why is it important?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    SOP Training ensures that employees are familiar with Standard Operating Procedures. This training is crucial for maintaining compliance with internal and external regulations, promoting a culture of safety and efficiency within the organization.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                     How does the Training Needs Assessment feature benefit our organization?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The Training Needs Assessment identifies specific skills and knowledge gaps among employees, allowing for targeted training that aligns with job roles and regulatory requirements, ultimately enhancing workforce performance.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-6'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-6'
                      aria-expanded='false'
                    >
                    Can Zentixs Talent Software help with CV preparation?
                    </button>
                  </h5>
                  <div
                    id='collapse-6'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-6'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Yes, the software offers guidance and tools for employees to create effective resumes, helping them present their skills and qualifications in a professional manner.
                   </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='accordion faq-accordion' id='accordionExample2'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                    How is training scheduled using Zentixs Talent Software?
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    The software allows for planning and management of both regular and flexible training sessions, accommodating employee availability while ensuring compliance deadlines are met.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                     What methods are used to assess employee training effectiveness?
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Talent Software employs various assessment methods, including quizzes, tests, and practical demonstrations, to evaluate how well employees understand and apply the training content.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                     How does the software handle documentation of training?
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    The software provides guidelines for recording training completion and assessments, maintaining accurate records that are essential for tracking and audits.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                     What is the purpose of Training Certification?
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Training Certification documents an employee’s successful completion of training, validating their skills and compliance with regulatory standards.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-11'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-11'
                      aria-expanded='false'
                    >
                    How does the software facilitate continuous improvement in training?
                    </button>
                  </h5>
                  <div
                    id='collapse-11'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-11'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Talent Software regularly updates training materials based on participant feedback and performance evaluations, ensuring that the training remains relevant and effective.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-12'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-12'
                      aria-expanded='false'
                    >
                      Can I conduct external training through Zentixs Talent Software?
                    </button>
                  </h5>
                  <div
                    id='collapse-12'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-12'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, the software facilitates participation in external courses or seminars led by industry experts, enhancing specialized skills and knowledge not available internally.
                   </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTalent;
