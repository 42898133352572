import React from 'react';
import { Link } from 'react-router-dom';


const Team = () => {
  return (
    <>
      <section id='our-team' className='team-section pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='row justify-content-center gap-5'>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-1.png'
                        alt='Ramesh Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Ramesh Patel</h5>
                      <p className='mb-3'>Founder and CEO</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#rameshPatel">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-2.png'
                        alt='Nitin Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Nitin Patel</h5>
                      <p className='mb-3'>Managing Director</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#nitinPatel">Read More</Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className='row justify-content-center gap-5 mt-5'>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-6.png'
                        alt='Khushboo V Satasiya'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Khushboo Satasiya</h5>
                      <p className='mb-3'>Project Manager</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#khushbooSatasiya">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-10.png'
                        alt='Bhavesh Gurjar'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Bhavesh Gurjar</h5>
                      <p className='mb-3'>Business Manager	</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#bhaveshGurjar">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-7.png'
                        alt='Nitin Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Kamla Jadeja</h5>
                      <p className='mb-3'>Chief People Officer</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#kamlaJadeja">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-4.png'
                        alt='Mayur Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Mayur Patel</h5>
                      <p className='mb-3'>Team Leader</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#mayurPatel">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-5.png'
                        alt='Rutvik Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Rutvik Patel</h5>
                      <p className='mb-3'>Team Leader</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#rutvikPatel">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-9.png'
                        alt='Akhil Trivedi'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Akhil Trivedi</h5>
                      <p className='mb-3'>Team Leader</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#parthGupta">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>


              <div className='row justify-content-center gap-5 mt-5'>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-3.png'
                        alt='Shailesh Dabhi'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Shailesh Dabhi</h5>
                      <p className='mb-3'>Accounting and Finance</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#shaileshDabhi">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='team-single-wrap mb-5'>
                    <div className='team-img rounded-custom'>
                      <img
                        src='assets/img/team/team-8.png'
                        alt='Nitin Patel'
                        className='img-fluid position-relative'
                      />
                    </div>
                    <div className='team-info mt-4 text-center'>
                      <h5 className='h6 mb-1'>Ranjit Nadoda</h5>
                      <p className='mb-3'>Project Architect</p>
                      <Link className='read-more-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#ranjitNadoda">Read More</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        {/* Ramesh Patel */}
        <div className="modal fade" id="rameshPatel" tabIndex="-1" aria-labelledby="rameshPatel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-1.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className='h6 mb-1'>Ramesh Patel</h5>
                        <p className='mb-3'>Founder and CEO</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr Ramesh Patel stands as a visionary leader and the esteemed founder of Adrta Tech Pvt Ltd, guiding our organization toward innovation and excellence. With a profound understanding of the ever-evolving technology landscape, He inspires the team to reach new heights and embrace cutting-edge solutions. His strategic thinking and strong business acumen drive our company's growth and success in a dynamic market. Moreover, their approachable and empathetic nature fosters a positive and collaborative work environment, where every team member feels valued and motivated to contribute their best. Under their guidance, we are poised to transform industries and make a lasting impact through our technological advancements.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        {/* Nitin Patel */}
        <div className="modal fade" id="nitinPatel" tabIndex="-1" aria-labelledby="nitinPatel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-2.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className='h6 mb-1'>Nitin Patel</h5>
                        <p className='mb-3'>Managing Director</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr Nitin Patel's position as Managing Director within our company is fundamental to its advancement, innovation, and comprehensive triumph with 15+ years of experience. His multifaceted responsibilities encompass strategic guidance, operational prowess, financial acumen, and adept leadership. As Managing Director, his influence is pivotal in charting our company's path, ensuring its stance of strength in the ever-evolving software landscape.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Khushboo V Satasiya */}
        <div className="modal fade" id="khushbooSatasiya" tabindex="-1" aria-labelledby="khushbooSatasiya" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-6.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className='h6 mb-1'>Khushboo Satasiya</h5>
                        <p className='mb-3'>Project Manager</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>A distinguished gold medalist in Bachelor of Engineering-IT, She assumes a vital role in choreographing the triumphant execution of software projects. Her realm of responsibilities spans project planning, team synchronization, client correspondence, risk oversight, and quality validation. Her adept orchestration guarantees the seamless, timely, and fruitful culmination of software projects, aligning with the desired objectives.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Bhavesh Gurjar */}
        <div className="modal fade" id="bhaveshGurjar" tabIndex="-1" aria-labelledby="bhaveshGurjar" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-10.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Bhavesh Gurjar</h5>
                        <p className="mb-3">Business Manager </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr Bhavesh Gurjar's position as a Business Development Manager is of paramount significance in driving our growth trajectory, cultivating robust client relationships, and expanding our market reach. With a versatile skill set spanning market analysis, negotiation, and collaborative efforts, he plays a pivotal role in sculpting our revenue generation strategies and reinforcing our competitive position within the software industry.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Kamla Jadeja */}
        <div className="modal fade" id="kamlaJadeja" tabIndex="-1" aria-labelledby="kamlaJadeja" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-7.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Kamla Jadeja</h5>
                        <p className="mb-3">Chief People Officer</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Kamla Jadeja is pivotal in shaping our workplace culture and driving HR strategy. As Chief People Officer, she ensures we attract, develop, and retain top talent, aligning our people practices with company goals. Her expertise in people management and commitment to employee well-being foster a collaborative and thriving work environment, making our company an employer of choice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Rutvik Patel */}
        <div className="modal fade" id="rutvikPatel" tabIndex="-1" aria-labelledby="rutvikPatel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-5.png'
                          alt='Rutvik Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Rutvik Patel</h5>
                        <p className="mb-3">Team Leader</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr. Rutvik Patel undertakes a pivotal responsibility by offering adept guidance and astute leadership to a team of accomplished software developers. He diligently ensures the impeccable execution of projects, the delivery of exceptional software solutions, and fosters an environment of continuous professional growth within his team. Mr. Patel's exceptional technical proficiency, adept leadership skills, and mastery in project management greatly enhance the company's success in the dynamic and competitive software arena.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Mayur Patel */}
        <div className="modal fade" id="mayurPatel" tabIndex="-1" aria-labelledby="mayurPatel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-4.png'
                          alt='Ramesh Patel'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Mayur Patel</h5>
                        <p className="mb-3">Team Leader</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr Mayur Patel takes on a core role as a Team Leader, guiding a group of technical professionals to achieve successful software project deliveries. He skillfully cultivates a collaborative work environment, fostering teamwork, while ensuring the continuous growth and development of each team member. His effective leadership, combined with his technical acumen and adept interpersonal skills, significantly bolsters the company's triumph in the fiercely competitive software industry.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        {/* Akhil Trivedi */}
        <div className="modal fade" id="parthGupta" tabIndex="-1" aria-labelledby="parthGupta" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-9.png'
                          alt='Akhil Trivedi'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Akhil Trivedi</h5>
                        <p className="mb-3">Team Leader</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>Mr. Akhil Trivedi's role holds paramount importance within our software company. It demands a delicate equilibrium of technical proficiency, adept leadership, and an intricate comprehension of quality assurance practices. His scope of responsibilities extends to not only guiding the QA team but also ensuring the highest standards of software quality and attaining triumphant project results in the dynamic and competitive realm of software development.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Shailesh Dabhi */}
        <div className="modal fade" id="shaileshDabhi" tabIndex="-1" aria-labelledby="shaileshDabhi" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-3.png'
                          alt='Shailesh Dabhi'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Shailesh Dabhi</h5>
                        <p className="mb-3">Accounting and Finance</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>As our Accounting and Finance leader, Mr. Shailesh Dabhi plays a pivotal role within our organization. His influence resonates deeply, affecting the financial health, strategic planning, and unwavering adherence to compliance within the company. With an extensive reservoir of expertise in financial management, seamlessly fused with deft leadership acumen, He emerges as a dynamic catalyst propelling our company's trajectory of growth and remarkable accomplishments. His skill in providing meticulous financial insights and navigating strategic financial decisions adroitly stands as an essential cornerstone in our journey toward triumph.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        {/* Ranjit Nadoda */}
        <div className="modal fade" id="ranjitNadoda" tabIndex="-1" aria-labelledby="ranjitNadoda" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-5">
                <div className="row gap-4 align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className='team-single-wrap'>
                      <div className='team-img rounded-custom'>
                        <img
                          src='assets/img/team/team-8.png'
                          alt='Ranjit Nadoda'
                          className='img-fluid position-relative'
                        />
                      </div>
                      <div className='team-info mt-4 text-center'>
                        <h5 className="h6 mb-1">Ranjit Nadoda</h5>
                        <p className="mb-3">Project Architect</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <p>As the project architect for the company, he plays a pivotal role in shaping the technological landscape of the projects. With a keen eye for detail and a deep understanding of software development principles, he is responsible for designing and overseeing the architecture of complex solutions. His expertise enables him to analyse project requirements, identify technical challenges, and devise innovative solutions that align with business objectives.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>



      </section>
    </>
  );
};

export default Team;
