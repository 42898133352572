import React from 'react';
import SectionTitle from '../common/SectionTitle';

const QmsContentThree = () => {
  return (
    <>
      <section className='why-choose-us ptb-60 bg-light-yellow'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/feature-hero-img-2.png'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
              <div className='mb-32'>
                  <SectionTitle
                    title='Get Mobile App & Make Life more Easy'                   
                    leftAlign
                  />
                </div>
         <h3 className='text-dark-gray mb-4'>Why Zentixs Sign? It is a complete digital signature app for business signatories. The terms “e-signature” and “digital signature” are often used inter-changeably. e-signature means just adding an image of a handwritten signature that can be easily forged. A digital signature uses cryptographic techniques backed by a digital certificate to provide proof of identity.
</h3>
<div class="crypto-btn-wrapper ">
  <a class="me-3 text-decoration-none mb-3 mb-xl-0" href="https://play.google.com/store/apps/details?id=com.zentixssign" target='_blank'>
  <img
                  src='assets/img/Play_Store.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
 </a>
 <a class="me-3 text-decoration-none mb-3 mb-xl-0" href="https://apps.apple.com/in/app/zentixs-sign/id6450251670" target='_blank'>
  <img
                  src='assets/img/App_Store.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
 </a>

      </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
};

export default QmsContentThree;
