import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeSassOne from '../themes/index1/HomeSassOne';
import ScrollToTop from '../components/common/ScrollToTop';
import About from '../pages/About';
import Leadership from '../pages/Leadership';
import Contact from '../pages/Contact';
import Career from '../pages/Career';
import CareerSingle from '../pages/career/CareerSingle';
import RequestDemo from '../pages/RequestDemo';
import Customers from '../pages/Customers';
import Support from '../pages/Support';
import Docs from '../pages/Docs';
import Track from '../pages/Track';
import Talent from '../pages/Talent';
import Sign from '../pages/Sign';
import Labs from '../pages/Labs';
import Caliber from '../pages/Caliber';
import Log from '../pages/Log';
import Sprint from '../pages/Sprint';
import Desk from '../pages/Desk';
import Eye from '../pages/Eye';
import IBook from '../pages/IBook';
import Saga from '../pages/Saga';
import AllProduct from '../pages/AllProduct';
import Technologies from '../pages/Technologies';
import Services from '../pages/Services';
import Community from '../pages/Community';
import Terms from '../pages/trust/Terms';
import Privacy from '../pages/trust/Privacy';
import Eula from '../pages/trust/Eula';


function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeSassOne />} />   
        <Route exact path="/About" element={<About />} />
        <Route exact path="/leadership" element={<Leadership />} />
        <Route exact path="/services" element={<Services />} />       
        <Route exact path="/contact-us" element={<Contact />} />           
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/career-single" element={<CareerSingle />} />  
        <Route exact path="/request-for-demo" element={<RequestDemo />} />
        <Route exact path="/terms" element={<Terms />} /> 
        <Route exact path="/privacy" element={<Privacy />} /> 
        <Route exact path="/eula" element={<Eula />} /> 
        <Route exact path='/Customers' element={<Customers />} />
        <Route exact path='/Support' element={<Support />} />
        <Route exact path='/zentixs-docs-sop-document-review-control' element={<Docs />} />
        <Route exact path='/zentixs-track-qms-quality-management-incident-capa-change-control' element={<Track />} />
        <Route exact path='/zentixs-talent-lms-training-management' element={<Talent />} />
        <Route exact path='/zentixs-sign-electronic-digital-signature' element={<Sign />} />
        <Route exact path='/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro' element={<Labs />} />
        <Route exact path='/zentixs-caliber-instrument-equipment-asset-calibration' element={<Caliber />} />
        <Route exact path='/zentixs-log-eln-electronic-logbook' element={<Log />} />
        <Route exact path='/zentixs-sprint-project-master-schedule-study-management' element={<Sprint />} />
        <Route exact path='/zentixs-desk-ticket-support-helpdesk-frontdesk' element={<Desk />} />
        <Route exact path='/zentixs-ibook-inventory-chemical-product-store-management' element={<IBook />} />
        <Route exact path='/zentixs-eye-department-process-supplier-vendor-audit' element={<Eye/>} />
        <Route exact path='/zentixs-saga-document-sample-softcopy-archival' element={<Saga />} />    
        <Route exact path='/AllProduct' element={<AllProduct />} />    
        <Route exact path='/Contact' element={<Contact />} />    
        <Route exact path='/Technologies' element={<Technologies />} />   
        <Route exact path='/Services' element={<Services />} /> 
        <Route exact path='/Community' element={<Community />} />        
      </Routes>
    </BrowserRouter>
  );
}

export default index;
