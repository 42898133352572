import React from 'react';
import SectionTitle from '../common/SectionTitle';

const DeskContentThree = () => {
  return (
    <>
      <section className='promo-section ptb-120 bg-desk-blue text-white'>
        <div className='container'>
        <div className='row justify-content-center align-items-center'>
        <div className='col-lg-6 col-md-9'>
          <div className='text-center  mb-5'>
        <h2 className='title2 mt-5 mb-3 text-white'>Great customer service for <br/> every business size</h2>
        <p className='text-white'>Zentixs broader objective is to make quality tech available, accessible, and affordable to all. In doing so, Zentixs Desk meets the needs of organizations of all sizes.

</p>
        </div>
        </div>
        </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white rounded-custom p-5 '>
                <div className='promo-icon mb-32'>
                <img
                  src='assets/img/icon/icon-zd-1.png'
                  alt='hero img'
                  className='position-relative z-5'
                />
                </div>
                <div className='promo-info'>
                  <h3 className='h3  text-white'>Startups
</h3>
                  <p className='mb-0  text-white'>
                  Make an essential early
investment to develop and strengthen
your customer relationships.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white rounded-custom p-5 '>
                <div className='promo-icon mb-32'>
                <img
                  src='assets/img/icon/icon-zd-2.png'
                  alt='hero img'
                  className='position-relative z-5'
                />
                </div>
                <div className='promo-info'>
                  <h3 className='h3  text-white'>SMBs
</h3>
                  <p className='mb-0  text-white'>
                  Turn your growing customer base
into brand loyalists with
top-notch service.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white rounded-custom p-5 '>
                <div className='promo-icon mb-32'>
                <img
                  src='assets/img/icon/icon-zd-3.png'
                  alt='hero img'
                  className='position-relative z-5'
                />
                </div>
                <div className='promo-info'>
                  <h3 className='h3  text-white'>Enterprises
</h3>
                  <p className='mb-0  text-white'>
                  Provide elevated service experiences to
all your stakeholders with our powerful
CX stack.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
       
        </div>
      </section>
    </>
  );
};

export default DeskContentThree;
