import React from 'react';
import Reveal from 'react-reveal/Reveal';


export default function LmsContentTwo() {
  return (

    <section className="digi-news-letter pb-120">
      <div className="container">
        <div className="bg-1 text-light rounded-custom p-4 p-md-5 p-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="digi-newsletter">
                <h2 className='title2 mb-4'>Effortless Learning Evolution</h2>
                <div className='mb-4'>
                  <h5>CV Preparation</h5>
                  <ul class="custom-bullet-list text-dark">
                    <li>Offers guidance and tools for employees to create effective resumes, highlighting their skills and qualifications.</li>
                  </ul>
                </div>
                <div>
                  <h5>Job Responsibilities</h5>
                  <ul class="custom-bullet-list  text-dark">
                    <li>Clearly defines the roles of individuals involved in the training process, promoting accountability in training design and delivery.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <Reveal effect="fadeinbottom">
                <div className="dg-news-img text-end">
                  <img
                    src="assets/img/lms-img-02.png"
                    className="img-fluid text-end"
                    alt="pie"
                  />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}