import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import LimsContent from '../components/Product/LimsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import LimsContentTwo from '../components/Product/LimsContentTwo';
import LimsContentThree from '../components/Product/LimsContentThree';
import HeroLims from '../components/Product/HeroLims';
import FaqTwo from '../components/faqs/FaqTwo';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Labs | Laboratory Information Management Software | Adrta' />
      <Navbar/>
      <HeroLims />
      <CustomerBrand  />
      <LimsContent/>
      <LimsContentTwo />
      <LimsContentThree />
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
