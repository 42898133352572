import React from 'react';

const FeatureImgContentThree = () => {
  return (
    <>
      <section className='feature-section ptb-120 pt-0'>
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-1 mb-7 mb-lg-0'>
              <div className='mb-4'>
                <h2 className='title2'>Your Solutions Await: Explore Our Client Care Services</h2>
                <p>
                Welcome to our esteemed company, where your solutions await. Reveal the true potential of your business with our client care services, featuring a dedicated support team adept at handling any challenges, so you can focus on your core operations and growth, leaving technical worries far behind.{' '}
                </p>
              </div>
              <ul className='list-unstyled d-flex flex-wrap list-two-col mt-5'>
                <li>
                  <span>
                    <i className='fal fa-comments-alt fa-2x text-primary mb-4'></i>
                  </span>
                  <h3 className='h5'>Prompt Online Chat Support</h3>
                  <p>Experience real-time agent assistance for prompt solutions and personalized support.</p>
                </li>
                <li>
                  <span>
                    <i className='fal fa-headset fa-2x text-primary mb-4'></i>
                  </span>
                  <h3 className='h5'>Agile digital support</h3>
                  <p>
                  Swift and adaptable assistance for seamless online experiences.{' '}
                  </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-2'>
              <div className='pr-lg-4 position-relative'>
                <div className='text-center rounded-custom overflow-hidden p-5 mx-lg-auto'>
                  <img
                    src='assets/img/support.png'
                    alt='DMS'
                    className='img-fluid rounded-custom'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FeatureImgContentThree;
