import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {Autoplay } from 'swiper';



export default function PaymentCustomer() {
    const swiperOption = {
        spaceBetween:24,
        autoplay:{
          delay: 0,
          disableOnInteraction: false,
          
        },
        pauseOnMouseEnter: true,
        modules:[Autoplay],
        breakpoints: {
     576: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 5,
            },
            1200: {
                slidesPerView: 5,
            },
            1400: {
                slidesPerView: 10,
            },
        },     
        loop: true,  
        speed: 5000,
        allowTouchMove: false,        
    };
    // const swiperOption = {
    //     slidesPerView: 2,        
    //     modules: {Navigation, Pagination, Scrollbar, A11y, Autoplay},
    //     spaceBetween: 24,      
    //     speed: 1000,
    //     autoplay: {
    //         delay:50,
    //         disableOnInteraction: false        
    //     },
    //     slidesPerGroup: 1,
    //     loop: true,      
    //     breakpoints: {
    //         320: {
    //             slidesPerView: 2,
    //             spaceBetween: 16,
    //         },
    //         768: {
    //             slidesPerView: 3,
    //         },
    //         991: {
    //             slidesPerView: 10,
    //         },
    //     },
    // };
    return (
        <section className="payment-brand-logo pt-120 pb-4">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center mb-4">
                            <h5 className='title1 text-white'>
                            Trusted by world famous companies                             
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 slik-slider-bg">
                        <Swiper {...swiperOption} className='payment-brand-logo' >     
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/apotex.png"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=" logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/alkem.png"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/zydus.png"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/lupin.png"
                                        className="img-fluid"
                                        alt="lupin"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/apcer.png"
                                        className="img-fluid"
                                        alt="apcer"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/axis-clinicals.png"
                                        className="img-fluid"
                                        alt="axis-clinicals"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/cliantha.png"
                                        className="img-fluid"
                                        alt="cliantha"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/clianslabs.png"
                                        className="img-fluid"
                                        alt="clianslabs"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/jai-research-foundation.png"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/accutest.png"
                                        className="img-fluid"
                                        alt="accutest"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/zenrise.png"
                                        className="img-fluid"
                                        alt="zenrise"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/azidus.png"
                                        className="img-fluid"
                                        alt="azidus"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/syconcro.png"
                                        className="img-fluid"
                                        alt="syconcro"
                                    />
                                </div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/intervain.png"
                                        className="img-fluid"
                                        alt="intervain"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/vitelybio.png"
                                        className="img-fluid"
                                        alt="vitelybio"
                                    />
                                </div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/veeda-clinical-research.png"
                                        className="img-fluid"
                                        alt="veeda-clinical-research"
                                    />
                                </div>
                            </SwiperSlide>                          
                                        
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
    )
}
