import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import TestimonialFive from '../components/testimonial/TestimonialFive';
import CustomerBrand from '../components/customer/CustomerBrand';

const Customers = () => {
  return (
    <Layout>
       <PageMeta title="Adrta | Customer Success Stories" />
      <Navbar />
      <PageHeader
        title="Here's why customers love us"
        desc="Build a thriving workplace culture with Connect's centralized intranet solution for internal communications, employee engagement, and collaboration.
        "
      />y
      <CustomerBrand />    
      <TestimonialFive />
      <FooterOne  />
    </Layout>
  );
};

export default Customers;
