import React, { useState } from 'react';
import accordionData from '../../json/FaqDocs.json';
import SectionTitle from '../common/SectionTitle';


function FaqDocs() {


  return (
    <>
      <section className='faq-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                       What is Zentixs Docs?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs is a document management software designed for the life science industry, focusing on regulatory compliance and efficient document lifecycle management. It ensures that all regulatory documents, such as SOPs and training materials, adhere to industry guidelines.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                     How does Zentixs Docs ensure document consistency?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs utilizes standardized templates for regulatory documents, minimizing formatting errors and ensuring compliance with industry standards. This approach enhances consistency across all documents.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      How does the software support automated training?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs integrates with Learning Management Systems (LMS) to deliver timely updates on new or revised SOPs, ensuring staff are informed and trained on the latest compliance requirements.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What is the process for document issuance?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The software facilitates controlled distribution of documents, such as SOPs and study or project reports, to authorized personnel only. 
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                    How does Zentixs Docs handle reconciliation and accountability?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs supports the reconciliation of both controlled and uncontrolled printed copies, allowing users to check the accountability of used and unused copies. This feature ensures accurate tracking of all documents.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-6'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-6'
                      aria-expanded='false'
                    >
                     How does Zentixs Docs handle document security and encryption?
                    </button>
                  </h5>
                  <div
                    id='collapse-6'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-6'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs implements advanced encryption methods for data at rest and in transit, ensuring that sensitive documents are securely protected against unauthorized access.
                   </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='accordion faq-accordion' id='accordionExample2'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                   Can Zentixs Docs be customized to fit specific organizational needs?
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, Zentixs Docs is highly customizable, allowing organizations to tailor workflows, templates, and permissions to meet their specific requirements and compliance standards.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                     What backup and disaster recovery options are available?
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs includes robust backup and disaster recovery solutions to ensure that all documents are securely backed up and can be restored in case of data loss.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                     How does Zentixs Docs ensure regulatory compliance?
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    The software ensures all document processes comply with relevant regulations, such as 21 CFR Part 11 and GxP standards, helping organizations maintain high compliance levels.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                      How does the software support data integrity throughout the document lifecycle?
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs employs strict access controls, version control, and audit trails to maintain data integrity throughout the document lifecycle, ensuring compliance with regulatory requirements.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-11'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-11'
                      aria-expanded='false'
                    >
                    What are the integration capabilities of Zentixs Docs?
                    </button>
                  </h5>
                  <div
                    id='collapse-11'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-11'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs enables seamless connections with ERP, LIMS, and electronic signature systems for smooth data sharing. 
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-12'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-12'
                      aria-expanded='false'
                    >
                     How frequently are updates and maintenance performed?
                    </button>
                  </h5>
                  <div
                    id='collapse-12'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-12'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Docs undergoes regular updates and maintenance to ensure optimal performance, security, and compliance with the latest regulatory standards.
                   </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqDocs;
