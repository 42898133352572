import React from 'react';


const DocsContentTwo = () => {
  return (
    <>
      <section className='why-choose-us green-light-bg ptb-120'>
        <div className='container'>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-12">
              <ul class="list-unstyled mb-0">
                <li class="d-flex align-items-start mb-4">
                  <div class="icon-box bg-primary rounded me-4">
                  <i class="fa-solid fa-pencil-alt text-white"></i>
                  </div>
                  <div class="icon-content">
                    <h3 class="h5">Document Issuance</h3>
                    <p>
                      Facilitates the controlled distribution of documents, such as SOPs, protocols, and study or project reports, to authorized personnel only. This maintains document integrity and ensures users have access to current information.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start mb-4">
                  <div class="icon-box bg-danger rounded me-4">
                  <i class="fa-solid fa-balance-scale text-white"></i>                   
                    </div>
                  <div class="icon-content"><h3 class="h5">Reconciliation and Accountability</h3>
                    <p>
                      Supports the reconciliation of both controlled and uncontrolled printed copies, allowing users to check the accountability of used and unused copies. This ensures that all documents are accurately tracked and accounted.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start mb-4 mb-lg-0">
                  <div class="icon-box bg-dark rounded me-4">                   
                    <i class="fa-solid fa-archive text-white"></i>
                </div>
                <div class="icon-content">
                    <h3 class="h5">Archival</h3>
                    <p className='mb-0'>
                    Safely archives inactive documents for future reference and audits, ensuring compliance with retention policies. This preserves essential records as required by regulatory authorities. Efficient archival practices make retrieving documents straightforward during inspections.
                    </p>
                  </div>
                </li>
              </ul>            
            </div>
            <div className='col-lg-5 col-12'>
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/document-process-04.png"
                  className="img-fluid text-end"
                  alt="document process"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocsContentTwo;
