import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import FeatureImgContentThree from '../components/feature-img-content/FeatureImgContentThree';
import FeatureImgEight from '../components/features/FeatureImgEight';
import PromoThree from '../components/promo/PromoThree';
import ServicesContentOne from '../components/Services/ServicesContentOne';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const sections = [
  { id: 'section1', title: 'Custom Development', 
    content: 'Custom development involves creating personalized software solutions to meet specific needs. It starts with gathering requirements, designing the software, writing code, testing it for bugs, and deploying it for use. This tailored approach ensures the software fits perfectly with the clients goals and workflows. While custom development offers flexibility and unique features, it requires more time, resources, and technical expertise compared to off-the-shelf solutions. Organizations should consider their priorities and budget carefully when deciding between custom development and pre-built software options.',
    imageUrl: '/assets/img/icon/custom-development.png'
},
  { id: 'section2', title: 'Product Development', 
  content: 'Product development is the process of creating new products or improving existing ones to meet customer needs and market demands. It involves stages like idea generation, design, prototyping, testing, and manufacturing. This process aims to deliver high-quality products that satisfy customer requirements and stay competitive in the market. Effective product development requires collaboration between different teams, market research, and continuous iteration to refine the product until it meets user expectations.', 
  imageUrl: '/assets/img/icon/product-development.png' },
  { id: 'section3', title: 'Maintenance and Support', 
  content: 'Maintenance and support involve ensuring that software or products remain functional, secure, and up-to-date after deployment. This includes activities such as bug fixes, updates, security patches, and troubleshooting to address issues that arise. Maintenance also encompasses tasks like performance optimization and compatibility enhancements to keep the software running smoothly. Support involves providing assistance to users, answering their questions, and helping them resolve any issues they encounter while using the product. Effective maintenance and support are essential for ensuring the long-term success and usability of software or products.',
  imageUrl: '/assets/img/icon/maintenance-support.png'
},
  { id: 'section4', title: 'Integration', 
  content: 'Integration refers to the process of combining different systems, applications, or components to work together seamlessly. It involves linking various software or hardware components to enable communication and data exchange between them. Integration can be achieved through APIs (Application Programming Interfaces), middleware, or custom code development. The goal of integration is to streamline workflows, improve efficiency, and enhance the overall functionality of systems by allowing them to share data and resources. Effective integration enables businesses to leverage existing investments in technology and create more cohesive and interconnected solutions.',
  imageUrl: '/assets/img/icon/integration.png'
},
  { id: 'section5', title: 'Legacy System Modernization', 
  content: 'Legacy system modernization involves updating or replacing outdated software or technology infrastructure with modern solutions. This process aims to improve efficiency, reduce maintenance costs, and align systems with current business needs and industry standards. It typically involves migrating data, re-platforming applications, and incorporating new technologies such as cloud computing, microservices architecture, or containerization. Legacy system modernization enables organizations to leverage the latest advancements in technology, enhance scalability and security, and adapt to changing market conditions. It often requires careful planning, collaboration between IT and business stakeholders, and a phased approach to minimize disruptions to operations.',
  imageUrl: '/assets/img/icon/legacy-system-modernization.png'
},
  { id: 'section6', title: 'Consulting and Advisory', 
  content: 'Software consulting and advisory services provide expert guidance and advice to businesses on their software-related challenges and opportunities. Consultants help identify problems, recommend solutions, and offer strategic advice on software selection, implementation, and optimization. They assist in aligning technology investments with business goals, improving efficiency, and maximizing return on investment. Whether its selecting the right software, optimizing existing systems, or navigating complex technology decisions, software consultants provide valuable insights and expertise to help businesses make informed decisions and achieve their objectives.',
  imageUrl: '/assets/img/icon/consulting-and-advisory.png'
},
  { id: 'section7',   title: 'User Experience (UX) Design', 
  content: 'User Experience (UX) design focuses on creating digital products, such as websites or applications, that are easy and enjoyable for users to interact with. UX designers prioritize the users needs and preferences to ensure that the product is intuitive, efficient, and satisfying to use. This involves activities like user research, wireframing, prototyping, and usability testing to understand user behaviors and preferences and iteratively improve the design. The goal of UX design is to enhance user satisfaction and loyalty by providing a seamless and engaging experience that meets their needs and expectations.', 
  imageUrl: '/assets/img/icon/ux.png'
},
  { id: 'section8', title: 'Training and Documentation', 
  content: 'Software training and documentation involve educating users about how to effectively use a particular software application or system, as well as providing reference materials for ongoing support. Training can take various forms, including in-person workshops, online tutorials, documentation manuals, or video demonstrations. The aim is to empower users with the knowledge and skills needed to navigate the software, perform tasks efficiently, and troubleshoot common issues. Documentation complements training by providing comprehensive guides, FAQs, and troubleshooting tips that users can refer to as needed. Effective training and documentation are crucial for ensuring successful adoption of the software and maximizing its benefits for users and organizations alike.',
  imageUrl: '/assets/img/icon/training-and-documentation.png'
 },
  { id: 'section9', title: 'Software as a Service (SaaS)', 
  content: 'Software as a Service (SaaS) revolutionizes how software is accessed and utilized. Users subscribe to SaaS applications hosted on the cloud, eliminating the need for local installation. This model offers cost-effectiveness, as users pay a subscription fee instead of upfront purchasing costs. Maintenance and updates are handled seamlessly by the provider, reducing the burden on users. With SaaS, users can access applications from any device with internet connectivity, enhancing flexibility and accessibility. The SaaS model spans various domains, from productivity tools to enterprise solutions, catering to diverse needs. Overall, SaaS simplifies software usage, offering convenience, scalability, and efficiency to both businesses and individuals.',
  imageUrl: '/assets/img/icon/Saas.png'
 },
];

const Services = () => {
  return (
    <Layout>
      <PageMeta title="Service Details-Software &amp; IT Solutions HTML Template" />
      <Navbar />
      <PageHeader
        title="Building Tomorrow's Solutions"
        desc="Where Software Meets Solutions, Innovation Grows."
      />
      <ServicesContentOne  sections={sections} />
      
     
      <PromoThree hasBg />
      <FooterOne />
    </Layout>
  );
};

export default Services;
