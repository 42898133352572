import React from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';





export default function DocsContentFour() {
  return (
    <section className="ptb-120" style={{ background: 'url(assets/img/payment-step.jpg) center center / cover no-repeat' }}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="text-center mb-5">
            <h2 className="text-white mb-3">Empowering Quality, Elevating Compliance. </h2>
            <p className="text-off-white">
            Zentixs Docs manages the process of documentation adherence to GxP standards.
            </p>
          </div>
        </div>
      </div>
      <div className="row mb--150 g-4">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs1.svg" alt="icon" />
            <h6 className="mt-3">Document Tagging</h6>
            <p className="mb-0">Uses a tagging system to categorize documents for easier retrieval based on type, project, or requirements. This simplifies the organization and access of critical information. Efficient tagging supports compliance by enhancing document management practices.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs2.svg" alt="icon" />
            <h6 className="mt-3">Folder and File Access</h6>
            <p className="mb-0">Controls access to folders and files based on user permissions to protect sensitive information. This ensures that only authorized personnel can view or edit critical documents. Strict access management enhances data security and supports regulatory compliance.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs3.svg" alt="icon" />
            <h6 className="mt-3">Document and Content Search</h6>
            <p className="mb-4">Provides an easy way to search for documents and content, allowing users to quickly find what they need. This feature saves time and makes it simple to access important information.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs4.svg" alt="icon" />
            <h6 className="mt-3">Audit Trail</h6>
            <p className="mb-0">Maintains a detailed log of all changes and user activities related to documents. This transparency is crucial for audits, demonstrating accountability and adherence to Good Documentation Practices (GDP). A clear audit trail supports compliance verification.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs5.svg" alt="icon" />
            <h6 className="mt-3">Notifications and Reminders</h6>
            <p className="mb-0">Sends timely alerts for pending tasks, document reviews, and expiration dates. This helps keep compliance tasks on track and ensures nothing is overlooked. Automated reminders enhance efficiency and accountability in document management.</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-payment-step bg-white p-4 mb-4 mb-lg-0">
            <img src="assets/img/icon/docs6.svg" alt="icon" />
            <h6 className="mt-3">Integration Capabilities</h6>
            <p className="mb-0">Enables seamless connections with ERP, LIMS, and electronic signature systems for smooth data sharing. This reduces manual entry errors and supports compliance across various functions. Integration enhances overall efficiency in document management.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
