import React from 'react';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const TermsDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>        
            <div className='col-lg-8 col-md-8'>

            <p>Please read the terms of this Terms and Conditions Agreement (hereinafter referred to as “Agreement”) carefully before you begin using our website: Adrta having URL <b>https://adrtatech.com/</b> or any subdomains and/or any mobile applications (the “Website”). This Website is owned and operated by Adrta Technologies Private Limited, its subsidiaries and its affiliates, based in Sola, Ahmedabad, Gujarat, India (“Adrta”, “we,” “our,” or “us”). The terms “you” or “yours” or “User” refer to the Users of our Website.</p>           
            <p>By visiting, using, viewing, or accessing the Website, purchasing the products or services, and/or any content contained on, accessible or downloadable through this Website, whether available for free or by purchase, accessing the Website, or otherwise signing up to receive any of Adrta’s emails, newsletter, social media posts or content or by purchasing any products or services from the Website, you are voluntarily agreeing to the terms of this Agreement and accept and agree to be bound and abide by them, and you are legally agreeing that you have read, understand, and fully consent to the terms contained below in this Agreement.</p>           
            <p>You are hereby directed to review our Privacy Policy which is incorporated by reference into these Terms and Conditions. These Terms and Conditions, along with the Privacy Policy of the Website, govern your access to and use of the Website. If you have any questions about any of these terms of this Agreement, please contact us at <b>support@adrtatech.com</b> before using this Website and please refrain from using the Website in the meantime.</p>           
            
            <h3 class="h5 pb-2">1.	ZENTIXS SUITE SERVICES</h3>
            <p>1.1.	The Zentixs Suite encompasses a range of digital solutions aimed at optimizing various organizational processes. Each service offered within the Zentixs Suite is tailored to address specific needs within different domains. By utilizing any of the services provided under the Zentixs Suite (hereinafter referred to as "Services"), you agree to abide by the terms and conditions of this Agreement. Following are the Services offered on the Website:	</p>
            <p>(a) <b>Zentixs Docs:</b> Zentixs Docs, being a Document Management Software (DMS) is a digital solution designed to organize, store, manage, and track electronic documents and files within an organization. DMS assists in streamlining document-related processes, improving collaboration, and ensuring compliance with document management standards. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-docs-sop-document-review-control'> https://adrtatech.com/zentixs-docs-sop-document-review-control. </a></p>
            <p>(b) <b>Zentixs Talent:</b> Zentixs Talent, being a Training Management Software (TMS) is a specialized platform designed to streamline and optimize the process of managing training programs within an organization. TMS assists in training managers, administrators, instructors, and learners to efficiently organize, deliver, track, and evaluate training activities. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-talent-lms-training-management'>https://adrtatech.com/zentixs-talent-lms-training-management.</a> </p>
            <p>(c) <b>Zentixs Track:</b> Zentixs Track, being a Quality Management Software (QMS) is a digital solution designed to streamline and automate processes related to quality management within an organization. QMS aids to ensure that products and services meet customer expectations, regulatory requirements, and industry standards by facilitating quality planning, control, assurance, and improvement activities. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-track-qms-quality-management-incident-capa-change-control'>https://adrtatech.com/zentixs-track-qms-quality-management-incident-capa-change-control. </a></p>
            <p>(d) <b>Zentixs Eye:</b> Zentixs Eye, being an Audit Management Software (AMS) is a digital solution designed to streamline and automate audit processes within an organization. AMS aids audit teams to plan, schedule, conduct, report, and follow up on audits efficiently. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-eye-department-process-supplier-vendor-audit'>https://adrtatech.com/zentixs-eye-department-process-supplier-vendor-audit. </a></p>
            <p>(e) <b>Zentixs Labs:</b> Zentixs Labs, being a Laboratory Information Management System (LIMS) is a software solution designed to manage and automate the various tasks and processes within a laboratory environment. LIMS serves as a centralized system for tracking and managing samples, test results, laboratory workflows, and other critical data. For more details, please visit the below mentioned site by clicking on the link below: <a href='1.1.5.	Zentixs Labs: Zentixs Labs, being a Laboratory Information Management System (LIMS) is a software solution designed to manage and automate the various tasks and processes within a laboratory environment. LIMS serves as a centralized system for tracking and managing samples, test results, laboratory workflows, and other critical data. For more details, please visit the below mentioned site by clicking on the link below: https://adrtatech.com/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro'>https://adrtatech.com/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro.</a> </p>
            <p>(f) <b>Zentixs Sign:</b> Zentixs Sign, being a Digital Signature Software (DSS) is a digital solution designed to facilitate the creation, authentication, and management of electronic signatures for documents and transactions. It provides a secure and legally binding way to sign electronic documents, eliminating the need for physical signatures and paper-based processes. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-sign-electronic-digital-signature'>https://adrtatech.com/zentixs-sign-electronic-digital-signature.</a></p>
            <p>(g) <b>Zentixs Caliber:</b> Zentixs Caliber, being an Instrument Management Software (IMS) is a specialized software solution designed to streamline and optimize the management of laboratory instruments and equipment within a research, testing, or manufacturing environment. IMS helps organizations effectively track, maintain, calibrate, and manage various types of scientific instruments and equipment. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration'>https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration. </a></p>
            <p>(i) <b>Zentixs Log:</b> Zentixs Log, being an Electronic Log Management Software (ELMS) is a digital solution designed to streamline and automate the process of creating, managing, and storing electronic logs or records within an organization. ELMS helps organizations replace paper-based logbooks with digital logs, improving efficiency, accuracy, and compliance with regulatory requirements. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration'>https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration.</a></p>
            <p>(j) <b>Zentixs Sprint:</b> Zentixs Sprint, being a Project Management Software (PMS) tailored for the pharmaceutical industry provides comprehensive features and capabilities to manage complex pharmaceutical projects, ensure regulatory compliance, maintain product quality, and drive project success. For more details, please visit the below mentioned site by clicking on the link below: <a href='https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration'>https://adrtatech.com/zentixs-caliber-instrument-equipment-asset-calibration. </a></p>
            <p>(k) <b>Zentixs Saga:</b> Zentixs Saga, being Archival Software, also known as Data Archiving Software (DAS), is a type of software designed to store and manage large volumes of data for long-term retention and preservation. DAS is commonly used by organizations to archive historical records, regulatory documents, research data, and other types of data that need to be retained for compliance, legal, or historical purposes. For more details, please visit the below mentioned site by clicking on the link below: <a href='http://localhost:3000/zentixs-saga-document-sample-softcopy-archival'>http://localhost:3000/zentixs-saga-document-sample-softcopy-archival.</a></p>
            <p>(l) <b>Zentixs Desk:</b> Zentixs Desk, being a Help Desk Software (HDS) is a digital solution designed to streamline and optimize the process of managing customer support inquiries, technical issues, and service requests. It serves as a centralized platform for receiving, tracking, prioritizing, and resolving customer tickets or requests. For more details, please visit the below mentioned site by clicking on the link below: <a href='http://localhost:3000/zentixs-desk-ticket-support-helpdesk-frontdesk'>http://localhost:3000/zentixs-desk-ticket-support-helpdesk-frontdesk.</a> </p>
            <p>(m) <b>Zentixs Ibook:</b> Zentixs Ibook, being an Inventory Management Software (IMS) is a digital solution designed to streamline and optimize the management of inventory and stock levels within an organization. IMS helps businesses track inventory levels, manage stock movements, optimize inventory replenishment, and ensure accurate inventory counts. For more details, please visit the below mentioned site by clicking on the link below: <a href='http://localhost:3000/zentixs-ibook-inventory-chemical-product-store-management'> http://localhost:3000/zentixs-ibook-inventory-chemical-product-store-management.</a> </p>

            <p>1.2 Users shall be eligible to opt for customization and/or add ons to the Services as mentioned in sub clause 1.1. of this Agreement and such customization and/or add ons to the Services shall curtail an additional subscription fee, payable during the subscription period. Such additional subscription fee for the customization and/or add ons to the Services, payable during the subscription period may be levied on a monthly basis or a yearly basis and/or on the basis of any other method, as offered by Adrta and as opted by the User during the subscription period.   </p>
            <p>1.3.	Adrta may suspend a User's account or any User account, or temporarily disable access to all or part of the Service in the following circumstances:</p>
            <p>(a) Suspected illegal activity;</p>
            <p>(b) Failure to activate or verify the User’s Adrta account and/or email address, or voluntary closure of the User’s Adrta account, by the User;</p>
            <p>(c) Requests by law enforcement or government agencies.</p>
            <p>1.4.	Users may terminate or close their account by accessing their Adrta account details or by accessing the user management screen or option available on the Website.</p>
            <p>1.5.	Users shall retain ownership of all customer data and are solely responsible for its legality, reliability, integrity, accuracy, and quality.</p>
            <p>1.6.	Users grant ADRTA a royalty-free, non-exclusive, irrevocable right to collect and process customer data, in order to solely provide the Services under the terms of this Agreement. Users warrant that they have necessary rights and permissions to authorize ADRTA for such processing.</p>
            <p>1.7.	Users acknowledge that ADRTA shall process any personal data derived from User’s registration or inquiry in the Website, in accordance with Adrta’s Privacy Policy. This may include using the User's email address to communicate information about the Services or other relevant services that ADRTA believes may be of interest to the User.</p>
            <p>1.8.	ADRTA may occasionally offer Users certain additional services as free beta trials to assess the maturity and suitability of such beta trials and for inclusion in the ADRTA suite of Services. Users acknowledge that ADRTA has sole authority and discretion to determine the testing and evaluation period for these beta services. ADRTA reserves the right to fully or partially discontinue any beta services at any time, with or without prior notice to the Users. Users agree that ADRTA shall not be held liable for any harm arising from the modification, suspension, or discontinuation of beta services. Upon conclusion of the beta trial period, ADRTA shall notify Users to either commence payment for the relevant Service or discontinue use.</p>
            
            <h3 class="h5 pb-2">2. SUPPORT SERVICES</h3>
            <p>2.1.	Adrta shall strive to ensure the availability of the Services, round the clock, seven (7) days a week, except for: (i) scheduled maintenance; and (ii) unscheduled maintenance, provided that ADRTA makes reasonable efforts to notify the User in advance.</p>
            <p>2.2.	As part of the Services, ADRTA shall offer standard customer support services to the User, during normal business hours, at no additional cost, depending upon the User’s subscription plan. These customer support services shall be provided in accordance with ADRTA's policy, as applicable at the time of Service provision. Adrta reserves the right to modify the policy at its sole discretion.</p>
            
            <h3 class="h5 pb-2">3. PRICING AND PAYMENT TERMS</h3>
            <p>3.1.	The Services opted by the User shall be accessible through subscription plans of varying durations including but not limited to one-time and annual maintenance agreement (AMA) or subscriptions being yearly subscriptions or monthly subscriptions and the pricing of the Services may be tiered based on the number of facilities or users. Payments for subscription plans lasting more than or equivalent to a year can be made either via credit card or debit card or other forms of payment. Your subscription shall automatically renew at the end of each subscription period unless you downgrade to a free plan or notify us of your intention not to renew your subscription plan or AMA. In case you opt for automatic renewal, the subscription fee shall be charged to the credit card or debit card or other forms of payment, previously used by you. You have the option to update your payment details if you prefer to use a different credit card or debit card or other forms of payment, for renewal. To avoid renewal, you must notify us at least seven (7) days before the renewal date. Failure to downgrade or notify us of your intention not to renew your subscription plan shall authorize Adrta to charge the subscription fee to the previously used credit card or debit card or other forms of payment. </p>
            <p>3.2.	Occasionally, we may adjust the price of any Service or begin charging for Services currently provided, free of charge, or as per the free trial usage plan mentioned in clause 4 of this Agreement. Any price increases shall take effect at the conclusion of your current billing cycle. You will not be billed for any Service usage unless you have opted for a paid subscription plan.</p>
            <p>3.3.	If Adrta is required to charge any Taxes, such as GST, VAT, sales tax, or similar levies, in accordance with local, state, district, or foreign laws, pertaining to your subscription to our Services <b>("Taxes")</b>, we shall invoice you for such Taxes. You agree to duly pay these Taxes in addition to the subscription fees. Adrta will provide you with an invoice in compliance with applicable laws, enabling you to claim any applicable input tax credit for the Taxes paid by you.</p>
           
            <h3 class="h5 pb-2">4.	FREE TRIAL ACCOUNTS</h3>
            <p>4.1.	Users may access the Services on a free basis, subject to the limitations outlined in the free tier. Only one free basis subscription is permitted per company, individual, or entity, as detailed in sub clause 5.5 of this Agreement.</p>
            <p>4.2.	If the Service is provided on a free or trial basis, ADRTA reserves the right to terminate such free or trial usage with at least one (1) week's prior written notice. Further use of the Service may be subject to payment of the relevant subscription fees.</p>

            <h3 class="h5 pb-2">5.	PERMITTED USAGE</h3>
            <p>5.1.	Provided that the User fulfills the payment obligations outlined in clause 6 and clause 3.1 of this Agreement, and abides by the restrictions delineated in this Agreement, Adrta hereby grants the User a non-exclusive and non-transferable license to utilize the Services strictly within the bounds of the Permitted Usage throughout the subscription period, exclusively for the User’s internal business operations.</p>
            <p>5.2.	The User shall refrain from accessing, storing, disseminating, or transmitting any viruses, or any content during the utilization of the Services that:</p>
            <p>(a)	violates laws, poses harm, threatens, defames, is obscene, infringes, harasses, or exhibits racially or ethnically offensive characteristics;</p>
            <p>(b) facilitates unlawful activities;</p>
            <p>(c) is inherently illegal or causes harm or injury to any individual or property; and</p>
            <p>(d) pertains to adult content, such as sexual or pornographic material. </p>
            <p>5.3. Adrta reserves the right, without liability or prejudice to its other rights or remedies, to deactivate the User’s access to any Content, contravening the terms of this clause, as mentioned in sub clause 5.2 of this Agreement. Adrta shall fully cooperate with law enforcement agencies or court orders requesting or directing Adrta to disclose the identity or locate any individual posting content in breach of sub clause 5.2 of this Agreement. Should the User engage in processing or distributing any content falling under sub-clauses 5.2.1. to 5.2.4. above (regardless of whether processed via the Services) as part of its business activities, Adrta reserves the right to terminate this Agreement immediately, with or without prior notice to the User.</p>
            <p>5.4.	The User shall not save as allowed by any applicable law incapable of exclusion by agreement between the parties:</p>
            <p>(a) except to the extent expressly permitted under this Agreement, attempt to copy, alter, replicate, derive works from, frame, reproduce, download, exhibit, transmit, or distribute all or any part of the Services or product description, in any format or medium, or by any means;</p>
            <p>(b) endeavor to reverse compile, disassemble, reverse engineer, or otherwise convert to human-readable form all or any portion of the Services; </p>
            <p>(c) access any part of the Services and product description to develop a product or service competing with the Services and/or the product description of Adrta; </p>
            <p>(d) utilize the Services and/or product description to provide services to third parties; </p>
            <p>(e) except as stipulated in clause 15 of this Agreement, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or commercially exploit the Services and/or product description, or otherwise make them available to any third party, or</p>
            <p>(f) attempt to gain or aid third parties in gaining access to the Services and/or product description.</p>
            <p>5.5.	The User shall employ all reasonable measures to prevent unauthorized access to, or usage of, the Services and/or the product description and, in case of any such unauthorized access or usage, promptly notify Adrta, within a period of three (3) days. Nevertheless, the User may grant access to its employees, representatives, partners, or assignees, or prospective employees, representatives, partners, or assignees.</p>
            <p>5.6.	The rights granted under this Agreement are exclusive to the User and are not extended to any subsidiaries or holding companies of the User. Only one subscription to the Services may be activated per company, individual, or entity. Replicated subscriptions for any company, individual, or entity shall be deemed a material breach of this Agreement, not subject to remedy, pursuant to clause 12 below.</p>
            <p>5.7.	The User agrees to ensure that its employees, representatives, partners, or assignees comply with this Agreement and acknowledges that the User shall be held responsible and liable for the actions or inactions of its employees, representatives, partners, or assignees to the same extent as if the User had undertaken such actions or inactions itself.</p>
            <p>5.8.	The User bears the responsibility for the security of all issued usernames and passwords (including any of its employees, representatives, partners, or assignees). In case the User suspects unauthorized access to its User account details, the User must immediately contact Adrta to suspend the account.</p>
            <p>5.9.	The User can access the Adrta settings screen to configure its employees, representatives, partners, or assignees and their roles and/or permissions. Upon successfully adding an additional user to the portal, an email verification shall be sent to the additional user, allowing them to set up their password and confirm access to the portal.</p>
            
            <h3 class="h5 pb-2">6.	PERMITTED USAGE - ADJUSTMENT</h3>
            <p>6.1.	The User may, at any point during the subscription term, request additional Permitted Usage beyond the initially allocated amount. Adrta shall provide access to the Services for the additional Permitted Usage as per the terms of this Agreement.</p>
            <p>6.2.	If the User wishes to acquire additional Permitted Usage, they can do so by accessing the payment screen on the Adrta Website and adjusting such Permitted Usage, at the prevailing rates. Any charges for additional Permitted Usage acquired during the initial subscription term or any renewal period shall be prorated accordingly. For enabling features from higher tiers, a request can be sent to support@adrtatech.com, and the feature shall be activated with prorated charges, applied in subsequent renewal invoices.</p>
            <p>6.3.	Should the User decide to decrease their permitted usage, they can initiate this process via the payment screen of their Adrta account. Upon selecting decreased Permitted Usage, the User must specify the resources they wish to discontinue support for, before the conclusion of the initial subscription term or ongoing renewal period. The revised Permitted Usage and resulting adjusted subscription fees shall take effect from the commencement of the subsequent renewal period.</p>
            
            <h3 class="h5 pb-2">7.	THIRD PARTY INTEGRATION</h3>
            <p>7.1.	Adrta Services may integrate with various third-party applications (referred to as "Third-Party Applications"). Accessing and using such Third-Party Applications may require acceptance of terms of service and privacy policies specific to those applications (referred to as "Third-Party Terms"). Users shall be responsible for reviewing and comprehending the Third-Party Terms before utilizing any Third-Party Application. </p>
            <p>7.2.	Users acknowledge and agree that Adrta holds no liability for Third-Party Applications. While Adrta shall endeavor to provide advance notice whenever feasible, Users acknowledge and agree that Adrta reserves the right, at its sole discretion and without notice, to suspend, restrict, disable access to, or remove any Third-Party Application from Adrta Services, without incurring any liability, including, but not limited to, loss of profits, revenue, data, goodwill, or other intangible losses.</p>
            
            <h3 class="h5 pb-2">8.	USER RESPONSIBILITIES</h3>
            <p>8.1.	The User shall provide Adrta with:</p>
            <p>(a)	All necessary cooperation as required by this Agreement; and</p>
            <p>(b)	Access to the required information to facilitate the provision of Services;</p>
            <p>8.2.	The User shall abide by all applicable laws and regulations pertaining to their activities under this Agreement;</p>
            <p>8.3.	The User shall fulfill all other responsibilities outlined in this Agreement promptly and efficiently. In case of any delays in the User's provision of assistance as agreed upon, Adrta may adjust any agreed timetable or delivery schedule as reasonably necessary;</p>
            <p>8.4.	The User shall ensure that the Services and product description are utilized in accordance with the terms of this Agreement and shall be accountable for any breach by its employees, representatives, partners, or assignees;</p>
            <p>8.5.	The User shall obtain and maintain all requisite licenses, consents, and permissions necessary for Adrta, its contractors, and agents to fulfill their obligations under this Agreement, including, but not limited to, providing the Services;</p>
            <p>8.6.	The User shall ensure that their network and systems adhere to the relevant specifications provided by Adrta, from time to time;</p>
            <p>8.7.	The User shall solely manage the procurement and upkeep of their network connections and telecommunications links, from their systems, to Adrta data centers. The User shall be responsible for any issues, conditions, delays, delivery failures, or other losses arising from or related to their network connections or telecommunications links, or caused by the internet.</p>
            
            <h3 class="h5 pb-2">9.	WARRANTY AND LIMITATIONS</h3>
            <p>9.1.	Adrta assures that the Services shall be carried out substantially in line with the product description and with reasonable expertise and care. Adrta also assures that reasonable efforts shall be made to ensure the Third-Party Applications are free from viruses or other destructive programs.</p>
            <p>9.2.	This assurance does not extend to any non-conformance caused by User's misuse of the Services contrary to Adrta's instructions, or modifications made by parties other than Adrta or its authorized contractors or agents. In the event of non-conformance, Adrta will, at its expense, exert commercially reasonable efforts to promptly correct it or provide an alternative method to achieve the desired performance. Such correction or substitution represents the User's exclusive remedy for any breach of the aforementioned assurance. However, it is hereby stated that Adrta:</p>
            <p>(a) does not guarantee uninterrupted or error-free use of the Services, nor that the Services or information obtained through them will meet User's requirements; and</p>
            <p>(b) is not liable for delays, delivery failures, or any loss or damage resulting from data transfer over communication networks, including the internet. Users recognize that the Services may encounter limitations, delays, and other issues inherent in such communication facilities.</p>
            <p>9.3.	Adrta makes no warranties that the Services will be of satisfactory quality, fit for a particular purpose, free from infringement of third-party rights, compatible with all systems, or secure or free of Viruses (despite Adrta's implementation of industry standard security measures).</p>
            <p>9.4.	Users acknowledge that no internet data transmission can be entirely secure. Adrta is not liable for any interception or disruption of communications through the internet or external networks beyond Adrta's control.</p>
            <p>9.5.	This Agreement does not prohibit Adrta from entering into similar agreements with third parties or independently developing, using, selling, or licensing products or services similar to those provided under this Agreement.</p>
            <p>9.6.	Users understand that the Service isn't tailored to meet their specific requirements, and it's their responsibility to ensure the Services' features align with their needs.</p>
            <p>9.7.	Users acknowledge that it's impractical to test the Service under every operating combination and environment, and it's impossible to create an error-free Service for all scenarios.</p>
            
            <h3 class="h5 pb-2">10.	CONFIDENTIALITY </h3>
            <p>10.1.	Each party (the Receiving Party) shall maintain the confidentiality of the other party's (the Disclosing Party's) confidential information. For Adrta, as the Disclosing Party, this encompasses all information (of any type or format, acquired by the User in any manner) related to Adrta's business, finances, technology, intellectual property rights, assets, strategies, products, and clients, marked as confidential at the time of disclosure or reasonably deemed confidential given its nature or manner of disclosure <b>(“Confidential Information”)</b>.</p>
            <p>10.2.	The Receiving Party shall not, without prior written consent from Adrta, utilize, disclose, replicate, or alter the Disclosing Party's Confidential Information (or allow others to do so), except as strictly necessary for fulfilling obligations under this Agreement. If disclosure to officers, employees, agents, professional advisers, or contractors is necessary, the Receiving Party must ensure they are informed and agree in writing to uphold the same confidentiality obligations as the Receiving Party.</p>
            <p>10.3.	However, Confidential Information shall not include the following being:</p>
            <p>(a)	Information already known to the Receiving Party before being shared by the Disclosing Party;</p>
            <p>(b)	Information entering or subsequently entering the public domain (without fault of the Receiving Party);</p>
            <p>(c)	Information received by the Receiving Party without disclosure or use restrictions from a third party lawfully entitled to disclose it without such restrictions;</p>
            <p>(d)	Information developed by the employees of Receiving Party without access to, use, or knowledge of information, shared by the Disclosing Party;</p>
            <p>(e) Information explicitly agreed upon in writing as not confidential by both parties.</p>
            <p>10.4.	Either party may disclose any confidential information required by law, court order, governmental, regulatory, or supervisory authority, or any other competent jurisdiction.</p>
            <p>10.5.	The User hereby grants Adrta a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right and license to use any comments, suggestions, proposals, or recommendations <b>("Feedback")</b> provided by or on behalf of the User, regarding the Services. Such Feedback shall not be considered the User's Confidential Information.</p>
            <p>10.6.	Except as provided elsewhere in this Agreement, no modification to this Agreement shall be binding unless made in writing and signed by the parties (or their authorized representatives).</p>
            
            <h3 class="h5 pb-2">11.	INTELLECTUAL PROPERTY RIGHTS</h3>
            <p>11.1.	The User acknowledges and agrees that Adrta and/or its licensors retain all intellectual property rights associated with the Services and the product description. Unless expressly provided herein, this Agreement does not confer upon the User any rights or licenses, including patents, copyrights, database rights, trade secrets, trade names, or trademarks (whether registered or unregistered), concerning the Services or the product description.</p>
            <p>11.2.	Furthermore, without limiting the preceding clause, all trademarks, logos, and service marks (collectively referred to as "Trademarks") visible on the Services belong to Adrta Technologies Private Limited, whether registered or unregistered, or are used under license by Adrta Technologies Private Limited from the respective trademark owners.</p>
            <p>11.3.	Any other trademarks are proprietary marks registered to their respective owners.</p>
            <p>11.4.	It should be noted that nothing within the Services should be interpreted as granting permission or rights to utilize any trademarks showcased on the Service without the written consent from Adrta Technologies Private Limited or the relevant trademark owner.</p>
            
            <h3 class="h5 pb-2">12.	TERM AND TERMINATION</h3>
            <p>12.1 This Agreement shall commence on the effective date and continue for the initial subscription term, unless terminated by either party. Thereafter, it shall automatically renew for successive periods equivalent to the initial subscription term (each a renewal period), unless:</p>
            <p>(a) Either party notifies the other party of termination before the end of the initial subscription term or any renewal period;</p>
            <p>(b) Termination occurs in accordance with the provisions of this Agreement.</p>
            <p>12.2.	The initial subscription term and any subsequent renewal periods together constitute the subscription term. Users may terminate the Services by email or other forms of communication, as mentioned in the Website. This clause does not apply to free or trial accounts as mentioned in clause 4 of this Agreement, which can be terminated by Adrta, at any time, with one (1) week’s notice to the User.</p>
            <p>12.3.	Either party may terminate this Agreement immediately if:</p>
            <p>(a) The other party fails to pay any amount due under this Agreement within a period of seven (7) days of being notified;</p>
            <p>(b) The other party commits a material breach and fails to remedy it within fourteen (14) days of notification;</p>
            <p>(c) The other party's conduct consistently breaches the terms of this Agreement;</p>
            <p>(d) The other party faces insolvency or similar proceedings;</p>
            <p>(e) The other party enters into negotiations with creditors, or proposes or enters into a compromise with creditors;</p>
            <p>(f) Winding up proceedings are initiated against the other party;</p>
            <p>(g) An administrator is appointed over the other party;</p>
            <p>(h) A receiver is appointed over the other party's assets;</p>
            <p>(i) A creditor takes enforcement action against the other party's assets;</p>
            <p>(j) Similar events occur in any jurisdiction to which the other party is subject; or</p>
            <p>(k) The other party suspends or ceases a substantial part of its business.</p>
            <p>12.4.	Upon termination:</p>
            <p>(a) All licenses and rights to use the Services shall cease immediately;</p>
            <p>(b) User's access to any data derived from the Services shall ends;</p>
            <p>(c) Each party returns or discontinues the use of any equipment, property, or other items belonging to the other party; and</p>
            <p>(d) Any accrued rights, remedies, obligations, or liabilities remain unaffected.</p>   

             <h3 class="h5 pb-2">13.	INDEMNIFICATION </h3>
             <p>13.1	You agree to defend, indemnify and hold harmless Adrta and its contractors, affiliates and suppliers, from and against any and all claims, damages, obligations, losses, liabilities, costs, debt and expenses, including but not limited to attorney’s fees arising from or in connection with: </p>         
             <p>(a) your use of this Website in violation of these Terms and Conditions; </p>         
             <p>(b) any breach by you of these Terms and Conditions or any representation and warranty made by you herein;</p>         
             <p>(c) any comment, post, or material you submit on this Website, </p>         
             <p>(d) your use of materials or features available on this Website, except to the extent a claim is based upon infringement of a third-party right by materials created by Adrta; or </p>         
             <p>(e) a violation by you of applicable law or any agreement or terms with a third party to which you are subject. </p>         
                   
             <p>13.2.	Adrta reserves the right, in the defense or settlement of any claim, to take measures to enable the User to discontinue using the Services. This may involve replacing or modifying the Services to ensure they do not infringe upon any rights. If such actions are deemed impractical at a reasonable cost, Adrta may, at its sole discretion, terminate this Agreement upon providing the User with two (2) business days' notice. Adrta shall bear no additional liability or obligation to pay liquidated damages or other extra costs to the User in such instances.</p>         
             <p>13.3.	Adrta, its employees, agents, and subcontractors shall not be liable to the User in cases where alleged infringement is based on:</p>         
             <p>(a) Modifications to the Services or product description by parties other than Adrta;</p>         
             <p>(b) User's usage of the Services or product description in violation of Adrta's instructions;</p>         
             <p>(c) User's continued use of the Services or product description following notice of alleged or actual infringement from Adrta or any appropriate authority.</p>         
             <p>13.4 Adrta reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Adrta in asserting any available defenses.</p>
             
             <h3 class="h5 pb-2">14.	LIMITATION OF LIABILITY </h3>
             <p>14.1.	TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES, SHALL ADRTA AND ITS CONTRACTORS, AFFILIATES OR SUPPLIERS, BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, EQUITABLE OR CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOSS OF USE, DATA OR PROFITS, OR ANY OTHER DAMAGES WHATSOEVER, ARISING FROM YOUR USE OF, OR INABILITY TO USE, THIS WEBSITE OR ITS CONTENTS OR ANY WEBSITE OR CONTENT LINKED FROM THIS WEBSITE. THIS LIMITATION OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED IN CONTRACT, WARRANTY, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL THEORIES, EVEN IF ADRTA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
             <p>14.2.	BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU, IF YOU LIVE IN SUCH JURISDICTION. IN SUCH JURISDICTIONS, THE LIABILITY OF ADRTA AND ITS CONTRACTORS, AFFILIATES AND SUPPLIERS IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.</p>
             <p>14.3.	YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ADRTA AND ITS CONTRACTORS, AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE WEBSITE, ITS CONTENT, ITS PRODUCTS OR ITS SERVICES, OR WITH THE WEBSITE TERMS AND CONDITIONS, PRIVACY POLICY, OR ANY OTHER POLICY OR AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE, ITS CONTENT, ITS PRODUCTS AND ITS SERVICES.</p>
             <p>14.4.	BY USING OR VIEWING THIS WEBSITE AND ITS CONTENTS, YOU AGREE TO THIS LIMITATION OF LIABILITY AND RELEASE ADRTA AND ITS CONTRACTORS, AFFILIATES AND SUPPLIERS FROM ANY AND ALL CLAIMS.</p>


             <h3 class="h5 pb-2">15.	RELATIONSHIP BETWEEN THE PARTIES</h3>
             <p>15.1.	This Agreement does not aim to establish a partnership between Adrta and the User, nor does it authorize either party to act as an agent for the other. Neither party has the authority to act on behalf of the other or bind them in any manner, including making representations or warranties, assuming obligations, or exercising rights or powers.</p>
             
             <h3 class="h5 pb-2">16.	FORCE MAJEURE</h3>
             <p>16.1.	Adrta shall not be liable to the User under the terms of this Agreement if it is unable to perform its obligations due to circumstances beyond its reasonable control. Such circumstances may include, but are not limited to, strikes, lock-outs, failure of utilities or transportation, acts of god, war, riot, civil commotion, government regulations, accidents, machinery breakdowns, fires, floods, storms, or supplier defaults. Adrta shall promptly notify the User of such events and their expected duration. </p>
             
             <h3 class="h5 pb-2">17.	GOVERNING LAW AND JURISDICTION</h3>
             <p>17.1.	This Website (excluding any linked sites or subdomains) are controlled by Adrta Technologies Private Limited from its office within the state of Gujarat, India. It can be accessed from all over India, as well as from other countries around the world. As each country or jurisdiction has laws that may differ from those of Gujarat, India, by accessing this Website, Adrta and you, both agree that the statutes and laws of the state of Gujarat, India, without regard to the conflict of laws principles thereof, will apply to all matters relating to the use of this Website.</p>
             <p>17.2.	The nearest court to Sola, Ahmedabad, Gujarat shall have exclusive jurisdiction over any case or controversy arising from or relating to this Website, including but not limited to the Privacy Policy or these Terms and Conditions. By using this Website, you hereby submit to the exclusive jurisdiction and venue of these courts and consent irrevocably to personal jurisdiction in the courts sitting in Sola, Ahmedabad, Gujarat with respect to such matters and any other dispute relating to the Website, and you waive any defense of forum non-conveniens.</p>
             <p>17.3.	In any action to enforce these terms, the prevailing party will be entitled to attorneys’ fees and costs. Any cause of action brought by you against Adrta Technologies Private Limited or its affiliates must be instituted within one (1) year after the cause of action arises or be deemed forever waived and barred.</p>
             
             <h3 class="h5 pb-2">18.	INTERNATIONAL USERS</h3>
             <p>18.1.	The Website is controlled, operated and administered by Adrta Technologies Private Limited, from our offices in Sola, Ahmedabad, Gujarat, India. Adrta does not represent that materials, products or Services, available on this Website are appropriate or available for use in other locations. If you access the Website from a location outside the state of Gujarat, India, you are responsible for compliance with all local laws. You agree that you will not use the Website and its contents in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
             
             <h3 class="h5 pb-2">19.	ENTIRE AGREEMENT</h3>
             <p>19.1.	This Agreement represents the complete and exclusive understanding between Adrta and the User regarding the Services, and it supersedes and replaces any prior agreements, terms, or conditions related to the Services. Purchase orders submitted by the User are solely for specifying the Services desired or purchased. Any additional terms in a purchase order sent to Adrta by the User shall not apply.</p>
             <p>19.2.	Both parties acknowledge that they are not relying on any promises, assurances, statements, warranties, or understandings not explicitly stated in this Agreement, whether from individuals involved in this Agreement or not, regarding the subject matter herein.</p>
             
             <h3 class="h5 pb-2">20.	MODIFICATIONS</h3>
             <p>20.1.	We reserve our right to change, modify or update these Terms and Conditions, at any time in our sole discretion, and you are hereby directed to check the Terms and Conditions, from time to time, in order to be updated about the latest changes and/or amendments being made in the Terms and Conditions. All changes are effective immediately when we post them and apply to, all access to, and use of the Website and its contents, products and services thereafter. By continuing to use the Website after we post any such changes, you accept and agree to be bound by the Terms and Conditions as modified, so you are hereby directed to check these Terms and Conditions from time to time to be aware of any modifications being made in the Terms and Conditions.</p>
             
             <h3 class="h5 pb-2">21.	ASSIGNMENT</h3>
             <p>21.1.	The User shall not, without prior written consent from Adrta, assign, transfer, charge, subcontract, or otherwise dispose of any of its rights or obligations under this Agreement.</p>
             <p>21.2.	Adrta reserves the right to assign, transfer, charge, subcontract, or otherwise deal with any of its rights or obligations under this Agreement at any time.</p>
             
             <h3 class="h5 pb-2">22.	SEVERABILITY </h3>
             <p>22.1.	If any portion of these Terms and Conditions is construed to be invalid, void or unenforceable for any reason, then that provision shall be deemed severable from these Terms and Conditions and it shall not affect the validity or enforceability of the remainder of the Terms and Conditions which shall be given full force and effect.</p>
             
             <h3 class="h5 pb-2">23.	WAIVER </h3>
             <p>23.1.	Any failure by Adrta to enforce or exercise any provision of these Terms and Conditions shall not constitute a waiver of that right or provision and shall not be construed as a waiver of any subsequent breach by you.</p>
             
             <h3 class="h5 pb-2">24.	CONTACT INFORMATION</h3>
             <p>24.1.	If you have any questions about this Terms and Conditions Agreement or any clauses contained herein, please feel free to contact us at support@adrtatech.com.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsDetails;
