import React from "react";

const PriceCard = ({price,businessprice,validity}) => {    

  return (
    
    <section className="price-two ptb-60 pt-0">
      <div className="container">  
        <div className="row  justify-content-center">
          <div className="col-lg-4 col-md-6 ">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow  mb-4 mb-lg-0 overflow-hidden d-flex flex-column h-100">
            <h3 className="package-name d-block p-5 pt-3 pb-3 mb-0" >Personal</h3>
            <div className="p-5 pt-3 d-flex flex-column h-100">
              <div className="pricing-header pb-1 mb-3">                
                <h4 className="display-6 fw-semi-bold">
                {price}
                <span>/{validity}</span>
                </h4>
               
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check bold text-success me-2"></i>
                    25 documents for eSignature
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Single User
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Real-time audit-trail
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Download unlimited signed files
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Automatic Reminders to signer
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Set expiration date
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                    Unlimited signature fields per document
                  </li>
             
                </ul>
              </div>
              <a
                href="request-demo.html"
                className="btn btn-success  mt-auto"
              >
               Buy Now
              </a>
            </div>
            </div>
          </div>
     
          <div className="col-lg-4 col-md-6 ">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow  mb-4 mb-lg-0 overflow-hidden d-flex flex-column h-100">
            <h3 className="package-name1 d-block p-5 pt-3 pb-3 mb-0" >Business</h3>
            <div className="p-5 pt-3 d-flex flex-column h-100">
              <div className="pricing-header pb-1 mb-3">                
                <h4 className="display-6 fw-semi-bold">
                {businessprice}<span>/{validity}</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check bold text-success me-2"></i>
                    Unlimited documents for eSignature
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Buy up to 50 users online. For more than 50 users, Contact Sales
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Admin Console screen
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Real-time audit-trail
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Download unlimited signed files
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>                     
                  Signing workflows
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Automatic Reminders to signer
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Set expiration date
                  </li>
                  <li>
                  <i className="far fa-check bold text-success me-2"></i>
                  Unlimited signature fields per document
                  </li>
             
                </ul>
              </div>
              <a
                href="request-demo.html"
                className="btn btn-success  mt-auto"
              >
               Buy Now
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceCard;
