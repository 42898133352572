import React, {  } from "react";
import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";
import PaymentCustomer from '../../components/customer/PaymentCustomer'



const HeroOne = () => {
  // const [isOpen, setOpen] = useState(false);

  return (
    <>
      <section className="hero-section ptb-30 text-white bg-gradient-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-10">
              <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                <h1 className="fw-bold display-5">Pioneering innovation, <span className="bg-gradient-three">delivering excellence.</span></h1>
                <HeroTitle                 
                  desc="We bring a personal and effective approach and deliver with a better user experience, quality, tailor made and cost effectively solutions."
                />
                <div
                  className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                
                  <Link to="/request-for-demo" className="btn btn-primary me-3">
                    Request a demo
                  </Link>
              
                </div>              

              </div>
            </div>
            <div className="col-lg-5 col-md-8 mt-5">
              <div className="hero-img position-relative circle-shape-images">
                <img
                  src="assets/img/hero-1.png"
                  alt="adrta banner"
                  className="img-fluid position-relative z-5"
                />
              </div>
            </div>
          </div>
        </div>
        <PaymentCustomer />
      </section>
    </>
  );
};

export default HeroOne;
