import React from 'react';
import AboutPageHero from '../components/about/AboutPageHero';
import PageMeta from '../components/common/PageMeta';
import FeatureImgThree from '../components/features/FeatureImgThree';
import AboutusFour from '../components/features/AboutusFour';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import TimelineAboutus from '../components/features/TimelineAboutus';

const About = () => {
  return (
    <Layout>
      <PageMeta title='About Adrta | Pioneering Software for Business' />
      <Navbar classOption='navbar-light' />
      <AboutPageHero />
      <FeatureImgThree />
      <AboutusFour />
      <TimelineAboutus />
      <FooterOne  />
    </Layout>
  );
};

export default About;
