import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgEight = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title='From ambition to achievement'
                  description='We firmly believe that your success is our ultimate goal. With a dedicated team of experts, we are committed to providing unwavering support, guidance, and encouragement to help you reach new heights in your endeavours.
                  '
                />
              </div>
           
            </div>
            <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='text-center overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/desk-zentixs.jpg'
                    alt=''
                    className='img-fluid rounded-custom '
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgEight;
