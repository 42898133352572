import React from 'react';
import SectionTitle from '../common/SectionTitle';
import { Link } from 'react-router-dom';

const FeatureImgFour = () => {
  return (
    <>
      <section className='feature-section pt-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
          <div className='col-lg-5'>
              <div className='pr-lg-4 position-relative' data-aos='fade-up'>
              <img
                      src='assets/img/career-zentixs.png'
                      alt='shape'
                      className='img-fluid'
                    />         

              
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title='We are proud to design your life and career to make a future.'            
                  leftAlign
                />
              </div>
              <p className='text-dark-gray'>
              Step into a culture that thrives on the exhilarating journey of exploring innovative technologies in the company of encouraging teammates. Join us as we embark on a mission to redefine work life, infusing it with simplicity, freedom from stress, and a refreshingly nonpolitical atmosphere. Here, equilibrium between personal and professional domains is cherished, fostering a harmonious balance that fuels your growth.</p>
              <div className='action-btns  pt-4'>
              <Link className="btn btn-outline-primary" to="/career">Join with us</Link></div>    
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgFour;
