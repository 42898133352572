import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import DocsContent from '../components/Product/DocsContent';
import Customer from '../components/customer/Customer';
import DocsContentTwo from '../components/Product/DocsContentTwo';
import HeroDoc from '../components/Product/HeroDoc';
import DocsContentThree from '../components/Product/DocsContentThree';
import FaqDocs from '../components/faqs/FaqDocs';
import DocsContentFour from '../components/Product/DocsContentFour';
import TrustedUsers from '../components/work-process/TrustedUsers';



const Docs = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Docs | Document Review Control Managment Software | Adrta' />
      <Navbar/>
      <HeroDoc />     
      <Customer />   
      <DocsContent />
      <DocsContentTwo />
      <DocsContentThree />
      <DocsContentFour />         
      <FaqDocs />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-docs"
      /> 
      <FooterOne footerLight />
    </Layout>
  );
};

export default Docs;
