import React from 'react';
import './animation.css'


const Customer = () => {
  return (
    <>  

        <div className='customer-section ptb-120 pb-0'  data-aos="fade-up"
     data-aos-anchor-placement="center-center">
          <div className='container'>
          <div className="row justify-content-center">
            <div className="col-auto">
              <h5 className="mb-5 mt-0">
                Trusted by the best companies in the world
              </h5>
            </div>
          </div>
            <div className='row justify-content-center'>
              <div className='col-lg-9 col-12'>
                <ul className='customer-logos-grid text-center list-unstyled mb-0 animate  delay-1 text-center'>
                  <li className='one animate delay-1'>
                    <img
                      src='assets/img/brand-logo/jai-research-foundation.jpg'
                    
                      alt='clients logo'
                      className='img-fluid                    
                     m-auto'
                      data-aos='fade-up'
                    />
                  </li>
                  <li className='one animate delay-2'>
                    <img
                      src="assets/img/brand-logo/accutest.jpg"
                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='50'
                    />
                  </li>
                  <li className='one animate delay-3'>
                    <img
                      src="assets/img/brand-logo/zenrise.jpg"
                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='100'
                    />
                  </li>
                  <li className='one animate delay-4'>
                    <img
                       src="assets/img/brand-logo/apotex.jpg"
                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='150'
                    />
                  </li>
                  <li className='one animate delay-5'>
                    <img
                     src="assets/img/brand-logo/alkem.jpg"                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='200'
                    />
                  </li>
                  <li className='one animate delay-6'>
                    <img
                     src="assets/img/brand-logo/zydus.jpg"                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='250'
                    />
                  </li>
                  <li className='one animate delay-7'>
                    <img
                      src="assets/img/brand-logo/lupin.jpg"                  
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='300'
                    />
                  </li>
                  <li className='one animate delay-8'>
                    <img
                       src="assets/img/brand-logo/apcer.jpg"                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='350'
                    />
                  </li>
                  <li className='one animate delay-9'>
                    <img
                         src="assets/img/brand-logo/axis-clinicals.jpg"                    
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='350'
                    />
                  </li>
                  <li className='one animate delay-10'>
                    <img
                       src="assets/img/brand-logo/cliantha.jpg"                     
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='350'
                    />
                  </li>
                  <li className='one animate delay-11'>
                    <img
                       src="assets/img/brand-logo/clianslabs.jpg"                      
                      alt='clients logo'
                      className='img-fluid  m-auto'
                      data-aos='fade-up'
                      data-aos-delay='350'
                    />
                  </li>
              
                </ul>
              </div>
            </div>
          </div>
        </div>

    </>
  );
};


export default Customer;
