import React from 'react';
import SectionTitle from '../common/SectionTitle';
import { Link } from 'react-router-dom';

const WorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='How can zentixs desk help your business with customer service?'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-12'>

              <div data-aos="fade-up">
                <div className="section-heading mb-0" data-aos="fade-up">
                  <p className="desc1">The Zentixs desk, crafted with precision by Adrta Technologies Private Limited, the Zentixs Desk is an all-encompassing solution tailored for managing customer inquiries and concerns. Seamlessly overseeing query intake, arrangement, and resolution, this advanced platform ensures a methodical approach to addressing each issue.</p>
                  <p className="desc1">The streamlined customer support, meticulously designed by Adrta Technologies, optimizes efficiency by processing complaints via a ticket system. This tailored approach guarantees a smooth workflow, ultimately enriching the overall customer journey. For critical concerns, alternative channels like email or verbal communication are available.</p>
                  
                  <Link to="/Support" className="read-more-link text-decoration-none">Know More Zentixs Desk
                  <span className="far ms-2 mb-1">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span></Link>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Classic Support</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Premium Support</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Enterprise Support</h6>
                  </li>
                </ul>
                <img
                  src='assets/img/desk-zentixs.jpg'
                  alt='zentixs desk'
                  className='img-fluid rounded-custom'
                />
              </div>

            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
