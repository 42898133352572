import React from 'react';
import SectionTitle from '../common/SectionTitle';

const DeskContenFive = () => {
  return (
    <>
      <section className='feature-section-two '>
        <div className='container'>
          <div className='row align-items-center  justify-content-center'>
          <div className='col-lg-4 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/desk3.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
              <div className='mb-5'>
               <h3 className='h4'>User Roles and Permissions</h3>
               <p className='lead'>Different user roles with varying permissions to control access to sensitive information and functionalities.</p>
              </div>
              <div>
               <h3 className='h4'>Audit Trails</h3>
               <p className='lead'>Keeping logs of all actions taken within the system for accountability and compliance purposes.</p>
              </div>
              </div>
            </div>
         
          </div>
        </div>
      </section>
    </>
  );
};

export default DeskContenFive;
