import React from 'react';

const LimsContent = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Generate leads with <br/>strategic and personalized <br/>campaigns</h2>
               <p className='mt-3 lead'>Design effective landing pages and plan automated multichannel campaigns to reach out to leads at the right time and produce more conversions than other conventional methods. Build your pipeline with targeted marketing approaches to keep your sales team busy.

</p>
                
   
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/lms1.jpg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LimsContent;
