import React from 'react';

const DeskContentSix = () => {
    return (
        <>
            <section className='ptb-120 pt-0'>
                <div className='container'>
                    <div class="row">
                        <div class="col-lg-6 col-md-10">
                            <div class="section-heading" data-aos="fade-up">
                                <h2 className='title2'>With all the Features You Need</h2>
                                <p className='mb-0'>Zentixs Desk is a comprehensive ticketing and compliance software tailored for research and healthcare organizations.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                                <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_1.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Ticket Creation and Assignment</h3>
                                    <p>
                                    Users can create tickets easily via email, web forms, or directly in the application. Tickets can be assigned to specific team members based on expertise or workload.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                            <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_2.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Categorization and Prioritization</h3>
                                    <p>
                                    Tickets can be categorized by type (e.g., technical issue, complaint, request) and prioritized (low, medium, high) to ensure critical issues are addressed promptly.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                            <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_3.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Status Tracking</h3>
                                    <p>
                                    Users can track the status of each ticket in real time, from "Open" to "In Progress," "Resolved," or "Closed." <br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                            <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_4.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Automated Notifications</h3>
                                    <p>
                                    Automated notifications can be sent to clients and team members regarding ticket updates, status changes, and resolutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                            <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_5.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">SLA Management</h3>
                                    <p>
                                    Service Level Agreements (SLAs) can be integrated to define response and resolution times, helping to manage client expectations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-3  mt-4">
                            <div className='mb-3'>
                                    <img src='assets/img/icon/d_icon_6.svg' />
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Root Cause Analysis</h3>
                                    <p>
                                    Tools for conducting root cause analysis to identify underlying issues leading to complaints, helping to prevent future occurrences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default DeskContentSix;