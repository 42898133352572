import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <PageMeta title="Adrta | Contact Us | SaaS Business Suite" />
      <Navbar />
      <PageHeader
        title="We’d love to hear from you"
        desc="Have questions about our products, features, trials, or pricing? Need a demo?
        Our teams will help you."
      />
      <ContactBox />
      <FooterOne     
      />
    </Layout>
  );
};

export default Contact;
