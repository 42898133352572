import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../common/HeroTitle';
import Reveal from 'react-reveal/Reveal';


const Herotechnologies = () => {

  return (
    <>
      <section
        className='hero-section bg-technologies tex ptb-120 text-white'
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5 hero-doc'>
                <HeroTitle
                  title='Creating tomorrows innovations today'
                  desc='The strategy and technology we employ empower our clients to embrace change, drive progress, and unlock growth opportunities. '
                />

              </div>
            </div>
            <div className='col-lg-6 col-md-8'>
              <Reveal effect="fadeinright">
                <div className='hero-img position-relative circle-shape-images'>
                  {/* <!--animated shape end--> */}
                  <img
                    src='assets/img/technologies-hero.png'
                    alt='hero img'
                    className='img-fluid position-relative z-5'
                  />
                </div>
              </Reveal>
            </div>
          </div>

        </div>

      </section>
    </>
  );
};

export default Herotechnologies;
