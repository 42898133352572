import React from 'react';
import SectionTitle from '../common/SectionTitle';

const EyeContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative '>
                <img
                  src='assets/img/audit-02.jpg'
                  className='img-fluid rounded-custom'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
                <div className='mb-32'>
                  <SectionTitle
                   title={
                    <>
                      Third-Party Audit<br />Management Features
                    </>
                  }         
                    leftAlign
                  />
                </div>
                <ul class="list-unstyled mb-0 text-dark-gray eye-list ps-0">
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Tracking of Findings: Effectively track third-party audit findings for documentation and monitoring.</li>
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>CAPA Management: Manage Corrective and Preventive Actions (CAPA) linked to findings.</li>
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Assignment to Stakeholders: Assign findings to relevant stakeholders for accountability.</li>
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>User Notifications: Automatic notifications to users when findings are assigned.</li>
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Resolution Guidance: Provides recommended resolutions for addressing findings.</li>
                  <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Closure of Findings: Allows formal closure of findings after corrective actions are implemented.</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EyeContentThree;
