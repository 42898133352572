import React from 'react';
import { Link } from 'react-router-dom';

const LogContentFour = () => {
  return (
    <>
      <section className='customer-review ptb-120 light-red-bg'>
        <div className='container'>
          <div className='row'>
            <div className='section-heading text-center' data-aos='fade-up'>
              <h2 className='fw-medium h4 title3'>
                That's not all!
              </h2>
            </div>
          </div>
          <div className='row g-5'>
            <div className='col-lg-6 col-md-6'>
              <div
                className='
                  review-info-wrap                 
                  rounded-custom
                  d-flex
                  flex-column
                  h-100                 
                '
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <h4>Error Prevention and Validation</h4>
                <div className='review-info-content mt-2'>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Incorporates checks and validation rules to prevent erroneous data entry.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Prompts users with warnings or prevents submission of incorrect or incomplete data.
                      </span>
                    </li>
                  </ul>
                </div>
                <h4 className='mt-5'>Digital Attachments</h4>
                <div className='review-info-content mt-2'>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Allows for the attachment of digital documents, images, or other media to log entries.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Useful for providing additional context or evidence to support the entry.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div
                className='
                  review-info-wrap                  
                  rounded-custom
                  d-flex
                  flex-column
                  h-100                
                '
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <h4>Training and Competency Tracking</h4>
                <div className='review-info-content mt-2'>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Tracks employee training and certifications, ensuring that only qualified personnel can perform and log specific tasks.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Alerts managers when certifications are due for renewal to maintain compliance with regulatory standards.
                      </span>
                    </li>
                  </ul>
                </div>
             
                <h4 className='mt-5'>Version Control</h4>
                <div className='review-info-content mt-2'>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-danger"></i>
                      <span>Keeps track of different versions of entries and documents, providing clarity on the evolution of recorded data.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>        
                             
            </div>
          
          </div>
        </div>
      </section>
    </>
  );
};

export default LogContentFour;