import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import PageHeader from '../../components/common/PageHeader';
import PrivacyDetails from '../../components/legal/PrivacyDetails';


const Privacy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <PageHeader
        title="Privacy Policy" desc="Last Updated: July 29, 2024" />      
      <PrivacyDetails />      
      <FooterOne />
    </Layout>
  );
};

export default Privacy;
