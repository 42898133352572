import React from 'react';

const SagaContentFive = () => {
    return (
        <>
            <section className='ptb-120 bg-black saga-section'>
                <div className='container middle-animated'>
                    <div className="row justify-content-center ">
                        <div className='col-md-8 text-center'>

                            <h2 className='title2 pb-2 text-white mb-30'>Why choose Zentixs Saga as <br /> your data preparation software?
                            </h2>
                            <div className='pb-0'>
                    <div className='feature-content-wrap'>
<ul className='list-unstyled  eye-list saga-list text-start'>
  <li className='py-1 font-600'>
  <i className="fa-sharp fa-solid fa-circle-check text-light-green"></i>
  You have a web application, but want a full-featured office editor to handle document management from within the app.

  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green "></i>
  Your business policy prohibits keeping customer data in a public cloud. So you need a solution that allows you to store these documents on your own servers, to ensure data compliance.

  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Business users should have a seamless experience when they view, edit, and collaborate on Word, Excel, and Powerpoint files online.

  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  You want a document management solution that's easy to implement with minimal configuration and doesn't involve additional hardware costs.

  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  The integrated office solution should have an affordable and flexible pricing model.

  </li>


</ul>
</div>
                    </div>
                        </div>
                    </div>
                 

                </div>
                <div className="container-star">
                    <div className="circle-container">
                    <div className="circle"></div>
                </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                        </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                        </div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                    <div className="circle-container"><div className="circle"></div></div>
                </div>
            </section>
        </>
    );
};

export default SagaContentFive;