import React from 'react';
// import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-60 pb-120'} `}>
        <div className='container'>

        <div class="row justify-content-center">
        <div className='col-md-10'>
        <h2 className='title2 pb-2'>A unique touch, tailored to your every need.</h2>
        <div class="row">
        <div className='col-md-6'>             
              <div>
               <p>
               Our team comprises experienced professionals with profound expertise in pharmaceuticals, research, laboratories, and healthcare industries, enabling us to understand industry complexities and design tailored solutions for research professionals.               
               </p>
               <p>We offer a comprehensive suite of software products that integrate seamlessly with existing systems, simplifying workflows and boosting productivity.</p>
               <p>Data security and compliance are paramount, with our software featuring robust measures to protect sensitive data while adhering to global regulatory standards.</p>
              </div>
            </div>
    <div className='col-md-6'>
      <p>A seamless experience is prioritized through our user-centric design, featuring intuitive interfaces and user-friendly features, all designed to support research teams in their core tasks.</p>
      <p>By seamlessly adapting to the specific needs of  multinational organization, our scalable and flexible solutions ensure that tailored support accompanies their growth journey.</p>
            </div>
        </div>
        </div>
    </div>


    <div className='ptb-120 pb-0'>
    <img
                  src='assets/img/aboutus-focus-on.jpg'
                  alt='zentixs aboutus'
                  className='img-fluid'
                />
    </div>

        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
