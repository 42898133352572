import React from 'react';
import { Link } from 'react-router-dom';

export default function SignContent() {
  return (
    <section className="digi-services pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mb-5">            
              <h2>
              <span className="text-yellow">Electronic signature</span> digital signature based document signing solution for enterprises.               
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-xl-4 d-flex align-items-stretch">
            <div className="digi-service-single mb-4 mb-xl-0 ">
              <img src="assets/img/icon/s-1.svg" alt="icon" />
              <h3 className="h5">Track and monitor document’s state
</h3>
              <p>
              - Control on each step of the signature process. <br />
              - Initiator will be notified via an email with document's current status.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-4 d-flex align-items-stretch">
            <div className="digi-service-single mb-4 mb-xl-0 ">
              <img src="assets/img/icon/s-2.svg" alt="icon" />
              <h3 className="h5">Send well formed system generated Reminders.
</h3>
              <p>
              - Notify recipient with software generated email with document link and it's expiration time.<br />
              - You will be notified via an email when your document is signed by any or all of your signers with details.
              </p>

            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-4 d-flex align-items-stretch">
            <div className="digi-service-single mb-4 mb-xl-0 ">
              <img src="assets/img/icon/s-3.svg" alt="icon" />
              <h3 className="h5">Complete audit trail
</h3>
              <p>
              - Zentixs sign will generate completion certificate at the end of signing process.<br />

              - Every signing activity on your documents such as by whom and when, recording comprehensive data such as timestamp, email address, device IP.
              </p>
           
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}
