import React from 'react';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import OpenJobs from '../components/career/OpenJobs';
import CareerPromo from '../components/career/CareerPromo';

const Career = () => {
  return (
    <Layout>
      <PageMeta title="Adrta Jobs | we are hiring! See all open positions | Careers" />
      <Navbar />   
      <CareerPromo />
      <OpenJobs />
      <FooterOne />
    </Layout>
  );
};

export default Career;
