import React from "react";


const TestimonialFive = () => {
  return (
    <>
      <section className="testimonial-section bg-light ptb-1007 ptb-120">
        <div className="container">
          <div className="row justify-content-center align-content-center">
            <div className="col-md-10 col-lg-6">
              <div className="section-heading text-center" data-aos="fade-up">
                <h2>What’s Clients Say</h2>               
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/axis-logo.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                  I am Dr. Ravinder Sreedasyam from AXIS Clinicals. Looking for Quality assurance. I have contact to Mr. Ramesh Patel and parallely we have start electonic training software. Our manual work process completely match with software. He has been delivered the way of the software is purly how we are thinking to the manually projected. We have taken document issuance control and other softwares. They have integrate the software not only haydrabad but all Axis clinical's branches. So, Adrta team has done wonderful job. We are using Adrta software more than 5 years without any concern. Many regulatories has seen Adrta software but there is no any observation found yet.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/1.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Dr. Ravinder Sreedasyam</h6>
                    <span>AXIS Clinicals Limited</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/sycon.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                I am thrilled to share my experience with Adrta Technologies Private Limited. Their software solution has been an absolute game-changer for our business. The level of compliance it offers across all regulations is truly exceptional, giving us peace of mind in a complex landscape. What truly sets them apart is their commitment to after-sales service. Their support team has consistently gone above and beyond to assist us, ensuring that our transition and ongoing usage have been nothing short of seamless.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/2.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Mr. Viral Patel</h6>
                    <span>Sycon Clinical Research Pvt. Ltd.</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/alkem.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                Having personally utilized various software solutions from Adrta Technologies Private Limited, I can attest to their seamless functionality without any disruptions. Throughout the entire journey of implementing and maintaining new software solutions, Adrta's support has been truly commendable. Their service is devoid of any time gaps, and they have consistently delivered what was promised, in alignment with their proficient, courteous, and readily accessible team. I extend my best wishes to Adrta Technologies for continued excellence in their future endeavors
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/3.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Alkem Laboratories limited</h6>
                    <span>Mumbai, Maharashtra</span>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/vitely-bio.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                We sincerely appreciate your efficient, gracious customer service, the level of detail and accountability your firm has demonstrated in the implementation of each module. In addition to this, your team's level of dedication and professionalism has impressed us.
                </p>
                <p>Moreover, we are really impressed by the timely support that you have been providing us. This has been greatly helping us to complete our tasks on time. We are very hopeful that you will continue to do so.
</p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/4.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Vitely Bio LLP</h6>
                    <span>Ahmedabad, Gujarat</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/intervein-research-lab.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                I am truly impressed by the exceptional software and unparalleled service provided by Adrta Technologies Private Limited. As a professional working in a highly regulated industry, compliance is non-negotiable. Their all modules of the zentixs software are not only meets but exceeds all regulatory requirements, providing me with a sense of security and peace of mind. I wholeheartedly recommend Adrta Technologies Private Limited to anyone seeking a compliance solution backed by top-notch service. With them, you're not just a client; you're a valued partner on a journey towards regulatory success.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/5.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Mr. Kartik Vikani</h6>
                    <span>Intervein Research Labs Pvt. Ltd.</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/cliantha-research-limited.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                Our interaction with Adrta's services has been  positive.  Throughout the phases of customization, testing, and validation, their support and technical proficiency have been exemplary.   The team's competence shines through, as they are not only adept but also highly attuned to the unique requirements of the clients.   With this experience in mind, we eagerly anticipate a sustained and fruitful partnership with Infinium in the long run. 
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/6.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Cliantha Research Limited</h6>
                    <span>Ahmedabad, Gujarat</span>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/zydus.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                Our partnership with Adrta Technologies Private Limited for our software needs has been exceptional. Notably, their post-sale support redefines customer service, marked by dedication, rapid response, and effective solutions.  Amid ever-evolving technology and regulations, Adrta Technologies Private Limited is a reliable and innovative partner, ensuring our peace of mind and progress. Their strong commitment to regulatory compliance instils trust in their solutions. Their holistic software approach and unparalleled post-sale service set them apart as industry leaders.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/7.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">Zydus Research Centre</h6>
                    <span>Ahmedabad, Gujarat</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/jrf-global.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                Our partnership with Adrta Technologies Private Limited has been remarkable. Their unwavering commitment to compliance, coupled with exceptional after-sale services, sets them apart as an innovative industry leader. With a holistic approach to software solutions and a dedication to our peace of mind, they've proven to be a reliable and invaluable partner in our journey.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/8.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">JRF Global</h6>
                    <span>Valsad, Gujarat</span>

                  </div>
                </div>
              </div>
              <div className="p-4 bg-white rounded-custom position-relative border border-2 mb-3">
                <img
                  src="assets/img/clients/zenrise.png"

                  alt="produxtImage"
                  height={50}
                  className="mb-2"
                />
                <p>
                partnering with Adrta, a regulatory-compliant software provider, has been a game-changer for our operations. Their expert team ensures that every aspect of our software solution meets industry regulations, giving us the confidence to navigate complex landscapes seamlessly.
                </p>

                <div className="author d-flex align-items-center">
                  <div className="author-img me-3">
                    <img
                      src="assets/img/testimonial/9.jpg"
                      alt="author "
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <div className="author-info ">
                    <h6 className="m-0">ZenRise Clinical Research</h6>
                    <span>Miyapur, Hyderabad</span>

                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialFive;
