import React from 'react';
import { Link } from 'react-router-dom';

const OpenJobs = () => {
  return (
    <>
      <section id='open-positions' className='open-jobs ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
          <div className="row justify-content-center ptb-100 pt-0">
            <div className="col-lg-8 col-md-8">
            <div className="subscribe-info-wrap text-center position-relative z-2">
            <div className="section-heading">
            <h2 className="title2">Collaborate with us to pioneer the future </h2>
            <p>Join a culture of collaboration and innovation where our people come first. Your future is just a click away.</p>
            </div>
            <div className="form-block-banner mw-60 m-auto mt-5">
            <a className="btn btn-primary" href="mailto:hr@adrtatech.com">Email Us</a>
            </div>
            </div>
            </div>
            </div>
         
          </div>
          <div className='row'>
          <div className='col-md-7'>
          <div className='row justify-content-between '>
            <div className='col-lg-5 col-md-12 mb-5'>
             <h2 className='title3'>Apply online.</h2>
             <p>Excited to be a part of our team? We're looking forward to your application. Highlight your skills in your CV, and we'll make sure it gets to the right people for review.</p>
            </div>
            <div className='col-lg-5 col-md-12 mb-5'>
            <h2 className='title3'>We’ll get in touch.</h2>
            <p>A member of our talent acquisition team will assess your compatibility with the position and will be in touch with you shortly.
</p>
            </div>
            <div className='col-lg-5 col-md-12'>
            <h2 className='title3'>A good fit?</h2>
            <p>We want to see the real you. Ask us questions and we’ll do the same. We want to ensure a great fit for both of us – because there are two sides to every great career story.</p>
            </div>
            <div className='col-lg-5 col-md-12'>
            <h2 className='title3'>It's decision time.</h2>
            <p>Either way, you’ll hear from us. If the timing isn’t quite right we’ll keep you on file and notify you of other opportunities when they arise. And if we think you’re a great fit, welcome aboard.



</p>
            </div>
          </div>
          </div>
          <div className='col-md-5'>
          <img
              src='assets/img/career.jpg'
              alt='career'
              className='img-fluid'
             
            />   
          </div>
          </div>
          <div >
          <p className='mt-5 lead text-dark'>If you're looking for a specific role then please let us know by writing and sending your resume to hr@adrtatech.com. <br/>Don't forget to mention the respective role in the subject of the mail.</p>
        </div>
        </div>
      </section>
    </>
  );
};

export default OpenJobs;
