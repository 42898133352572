import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import LogContent from '../components/Product/LogContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import HeroLog from '../components/Product/HeroLog';
import LogContentTwo from '../components/Product/LogContentTwo';
import LogContentThree from '../components/Product/LogContentThree';
import LogContentFour from '../components/Product/LogContentFour';
import FaqTwo from '../components/faqs/FaqTwo';
import TrustedUsers from '../components/work-process/TrustedUsers';

const Logbook = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Log | Electronic Logbook Software (eLogs) | Adrta' />
      <Navbar/>
      <HeroLog />
      <CustomerBrand  />
      <LogContent />
      <LogContentTwo />
      <LogContentThree />     
      <LogContentFour />
      <FaqTwo />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section"
      />       
      <FooterOne footerLight />
    </Layout>
  );
};

export default Logbook;
