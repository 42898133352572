import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LmsContentFive = () => {
  return (
    <>
      <section className='feature-section'>
        <div className='container-fluid'>  
             <div className="feature-container mx-xl-5 position-relative bg-gradient ptb-120 rounded-custom">
      <img
        src="assets/img/feature-bg-1.jpg"
        alt="feature-bg"
        className="feature-bg-mockup position-absolute w-100 h-100 left-0 right-0 top-0 bottom-0 rounded-custom"
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="feature-bg-img-content position-relative z-5">
              <div className="section-heading" data-aos="fade-up">
                <h2>Ensuring Excellence Through Compliance.</h2>
                <p>
                At Zentixs Talent Software, we help life science professionals understand and meet important regulations. Our training makes compliance simple and effective, promoting quality.
                </p>
              </div>
              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5 mb-0">
                <li className="py-3">
                  <h5 className="feature-number text-primary mb-2">Training Certification</h5>
                  <p>Issues certificates to employees upon successful completion of training, documenting their skills and compliance.</p>
                </li>
                <li className="py-3">
                  <h5 className="feature-number text-primary mb-2">Continuous Improvement</h5>
                  <p>Regularly revises training materials based on participant feedback to enhance training effectiveness and relevance.</p>
                </li>
                <li className="py-3">
                  <h5 className="feature-number text-primary mb-2">Training Compliance Audits</h5>
                  <p>Ensures the training program meets audit requirements, validating adherence to regulatory and organizational standards.</p>
                </li>
                <li className="py-3">
                  <h5 className="feature-number text-primary mb-2">Training Gap Analysis</h5>
                  <p>Identifies skill gaps among employees and helps create targeted training plans to address these deficiencies.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
      </section>
    </>
  );
};

export default LmsContentFive;
