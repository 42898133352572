import React from 'react';
import SectionTitle from '../common/SectionTitle';

const IbookContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Reduce excess inventory, stop stockouts and boost profits</h2>
            <p>Inventory management is a tough balancing act. You have to know what’s in stock, which orders are coming in, and when you might need to order more. If you’re having trouble keeping up with inventory and orders, Ibook is the software for you.

</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/ibook1.jpg'
                  alt='ibook'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IbookContentTwo;
