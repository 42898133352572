import { useState } from "react";
import React from "react";
import PriceCard from '../prices/PriceCard';

const PriceThree = () => {    
  const [selectMonthly, setSelectMonthly] = useState(true);
  console.log(selectMonthly);

  return (
    
    <section className="price-two ptb-60 PricingApp">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
          <div className="section-heading text-center mb-5">
             
             <h2 className="title2">Zentixs Sign pricing and plans</h2>
             <p>
             Simple pricing. No hidden fees. Advanced features for you business.
             </p>
           </div>
            {/* Header */}
        <header>        
          <div className="header-row">
            <p className="mb-0 fw-semi-bold">Annually</p>
            <label className="price-switch">
              <input
                className="price-checkbox"
                onChange={() => {
                  setSelectMonthly((prev) => !prev);
                }}
                type="checkbox"
              />
              
              <div className="switch-slider"></div>
            </label>
            <p className="mb-0 fw-semi-bold">Monthly</p>
          </div>
        </header>
            </div>
        </div>

        <PriceCard 
        price={selectMonthly ? "₹ 650" : "₹ 550"}
        businessprice={selectMonthly ? "₹ 900" : "₹ 800"}
        validity={selectMonthly ? "month" : "month billed annually"}
        
        />
      </div>
    </section>
  );
};

export default PriceThree;
