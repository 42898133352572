import React from 'react';

const SprintContentFour = () => {
    return (
        <>
            <section className='ptb-120 ping-bg-two'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-11'>
                            <div className='text-center'>
                                <h2 className='title2 pb-2 text-white'>A single source of truth for your business</h2>
                                <p className='text-white'>
                                    Run your agency, consultancy or any other professional services <br /> business on one seamlessly integrated platform.
                                </p>
                            </div>
                            <div className='pb-0'>
                                <div className='row mt-5'>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4  sprint-f">
                                            <div className="icon-box me-4">
                                                <i className="fa-light fa-briefcase text-white"></i>

                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Project management</h3>
                                                <p className='text-white'>Deliver projects from quote to cash</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4 sprint-f">
                                            <div className="icon-box me-4 ">
                                                <i className="fa-light fa-file-chart-column text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Quoting & Budgeting</h3>
                                                <p className='text-white'>Create proposals and estimate projects</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4  sprint-f">
                                            <div className="icon-box me-4">
                                                <i className="fa-sharp fa-light fa-signal-bars text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Sales & CRM</h3>
                                                <p className='text-white'>Manage customer accounts and track pipeline</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4  sprint-f">
                                            <div className="icon-box me-4">
                                                <i className="fa-light fa-chart-user text-white" ></i>

                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Resource planning</h3>
                                                <p className='text-white'>Plan and forecast work for optimal utilization</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4  sprint-f">
                                            <div className="icon-box me-4">
                                                <i className="fa-light fa-briefcase-arrow-right text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Retainers</h3>
                                                <p className='text-white'>Track and manage recurring client work</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start mb-4  sprint-f">
                                            <div className="icon-box me-4">
                                                <i className="fa-light fa-circle-dollar-to-slot text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Cost management</h3>
                                                <p className='text-white'>Track bills and POs to keep costs under control</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start  sprint-f">
                                            <div className="icon-box me-4 mb-0">
                                                <i className="fa-light fa-timer text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Time tracking</h3>
                                                <p className='text-white'>Know where time is spent in detail</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start  sprint-f">
                                            <div className="icon-box me-4 mb-0">
                                                <i className="fa-sharp fa-light fa-file-lines text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Invoicing</h3>
                                                <p className='text-white'>Automate billing and collect payments</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-start sprint-f">
                                            <div className="icon-box me-4 mb-0">
                                                <i className="fa-sharp fa-light fa-chart-mixed  text-white"></i>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="h5 text-white">Reporting & Dashboard</h3>
                                                <p className='text-white'>Gain insights on progress, results and future outlook</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SprintContentFour;