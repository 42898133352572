import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Reveal from 'react-reveal/Reveal';


const CaliberContentThree = () => {
  return (
    <>
      <section className='why-choose-us  ptb-120'>
        <div className='container'>
          <div className='row  justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
            <Reveal effect="fadeinbottom">
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/caliber1.jpg'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
              </Reveal>
            </div>
            <div className='col-lg-5 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
                <div className='mb-32'>
                  <SectionTitle
                    title='Efficient Email Communication for Approvals
                    '                   
                    leftAlign
                  />
                </div>
                <p>The software's functionality extends to email communication for new instrument requests and subsequent approval processes. When a new instrument request is initiated, the software triggers automated emails to relevant stakeholders, notifying them of the request and prompting them to review and provide necessary approvals. As the request progresses through departmental and quality assurance stages, automated email updates keep stakeholders informed about the status and any required actions

</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaliberContentThree;
