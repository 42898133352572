import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTrack = () => {
  return (
    <>
      <section className='faq-section ptb-120 pt-0'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                     What is Zentixs Track?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
<p>Zentixs Track is a Quality Management System (QMS) software designed to facilitate change control, CAPA management, deviation, incident reporting, risk management, and market complaint handling in regulated industries.</p>
<p>Zentixs Track is suitable for organizations in regulated sectors, including pharmaceuticals, healthcare, and manufacturing, where quality management and compliance are essential.</p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                     What are the key features of Zentixs Track?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    <ul>
                    <li>Comprehensive risk management tools (FMEA).</li>
                    <li>Change Control for managing modifications to processes.</li>
                    <li>CAPA management for tracking corrective and preventive actions.</li>
                    <li>Incident Management for reporting and resolving incidents.</li>
                    <li>Market Complaint handling for efficient resolution of customer complaints.</li>
                    <li>Deviation Management for logging and investigating deviations.</li>
                    <li>Out of Specification (OOS) management for handling OOS results.</li>
                    <li>Out of Trend (OOT) and Out of Trend Category (OOTC) analysis for trend monitoring.</li>                  
                    </ul>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                     How does the software help with risk management?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Track allows users to identify, assess, and mitigate risks through structured tools and methodologies, ensuring compliance and safety.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What is the process for initiating Change Control in Zentixs Track?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    <p>Users can initiate a Change Control request by accessing the "Change Control" module, completing the request form, and submitting it for evaluation by stakeholders.</p>
                    <p>All changes go through a systematic review process, including impact assessments and approvals, which are logged in the system for transparency and traceability.</p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                     What types of issues require a CAPA?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    CAPA should be initiated for any non-conformances, significant deviations, incidents, or feedback that may impact product quality or regulatory compliance.
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='accordion faq-accordion' id='accordionExample2'>

            <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-6'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-6'
                      aria-expanded='false'
                    >
                    How does Zentixs Track facilitate CAPA tracking?
                    </button>
                  </h5>
                  <div
                    id='collapse-6'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-6'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Users can create CAPA records, assign responsibilities, set deadlines, and monitor progress until closure, ensuring accountability and timely resolution.
                   </div>
                  </div>
                </div>


                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                    How can I report an incident in Zentixs Track?
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Users can report incidents through the "Incident Management" module by completing a form that captures the details of the incident, including actions taken and individuals involved.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                     What is the procedure for managing deviations?
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Deviations are reported through the "Deviation Management" module, where users provide details about the deviation and its impact, followed by an investigation and corrective action as needed.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                     How does Zentixs Track handle Market Complaints?
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Market Complaints can be logged in a dedicated module, enabling organizations to document, investigate, and resolve complaints effectively while maintaining compliance with regulatory requirements.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                     What information is essential for a Market Complaint report?
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    A Market Complaint report should include the complainant's details, product specifics, a description of the complaint, and any relevant documentation to support the investigation.
                    </div>
                  </div>
                </div>

              
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTrack;
