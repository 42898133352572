import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqTwo from '../components/faqs/FaqTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import EyeContent from '../components/Product/EyeContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import EyeContentTwo from '../components/Product/EyeContentTwo';
import EyeContentThree from '../components/Product/EyeContentThree';
import EyeContentFour from '../components/Product/EyeContentFour';
import HeroEye from '../components/Product/HeroEye';
import EyeContentFive from '../components/Product/EyeContentFive';
import FaqEye from '../components/faqs/FaqEye';
import TrustedUsers from '../components/work-process/TrustedUsers';

const Eye = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Eye | Department Process, Audit Management Software | Adrta' />
      <Navbar/>
      <HeroEye />     
      <CustomerBrand />
      <EyeContent/>
      <EyeContentTwo />
      <EyeContentFive />
      <EyeContentThree />
      <EyeContentFour />     
      <FaqEye />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-eye"
      /> 
      <FooterOne footerLight />
    </Layout>
  );
};

export default Eye;
