import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';

const HeroDoc = () => {
 
  return (
    <>
      <section
        className='hero-section green-bg text-white ptb-120'
        
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5 hero-doc'>
                <HeroTitle
                  title='Achieve Document Excellence and Compliance with Zentixs Docs.'
                  desc='It simplifies the creation, review, and distribution of essential documents while maintaining data integrity, security, and compliance.'                  
                />          
              
              </div>
            </div>
            <div className='col-lg-6 col-md-8'>
            
              <div className='hero-img position-relative circle-shape-images'>             
                {/* <!--animated shape end--> */}
                <img
                  src='assets/img/docs.svg'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                />
              </div>
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroDoc;
