import { Link } from 'react-router-dom';
import React from 'react';


const DocsContent = () => {

  return (
    <>
      <section className='app-two-feature ptb-120'          
      >       

        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center' >
                <h2 className='title2'>Explore Premium Features</h2>
                <p>
                Launch a limitless exploration of document creation through our intuitive digital arena
                </p>
              </div>
            </div>
          </div>
          
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-12'>
              <div className='row g-4'>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-layer-group"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Template Management</h3>
                      <p className='mb-0'>
                      Utilizes standardized templates to ensure consistency in regulatory documents, such as SOPs and other documents. This minimizes formatting errors and adheres to industry guidelines. 
                      </p>
                    
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-sync"></i>

                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Document Lifecycle</h3>
                      <p className='mb-0'>
                      Manages documents from creation to retirement, ensuring compliance at every stage. This includes efficient handling of drafting, review, approval, and archiving processes. Streamlined lifecycles help maintain accountability and regulatory adherence.
                      </p>                     
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-six me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-project-diagram"></i>

                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Pre-Defined Workflow</h3>
                      <p className='mb-0'>
                      Establishes clear approval processes that involve all relevant stakeholders, enhancing inter-departmental collaboration. This consistency ensures compliance with company policies and regulatory standards. Defined workflows reduce delays and improve efficiency.
                      </p>                    
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-five me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-shield-alt"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Secure Repository</h3>
                      <p className='mb-0'>
                      Stores documents in a secure digital environment with strict access controls. This protects sensitive information from unauthorized access. Compliance with regulatory data integrity standards is maintained through secure document management.
                      </p>                    
                    </div>
                  </div>
                </div>  
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-code-branch"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Version Control</h3>
                      <p className='mb-0'>
                      Tracks document revisions, ensuring only authorized personnel can make changes. This prevents accidental overwrites and keeps a clear history of all document updates and revision. Effective version control is essential for maintaining compliance during audits.
                      </p>
                     
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start h-100'>
                    <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-chalkboard-teacher"></i>

                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Automated Training</h3>
                      <p  className='mb-0'>                     
                      Integrates with Learning Management Systems (LMS) to deliver timely updates on new or revised SOP. This ensures staff are informed and trained on the latest compliance requirements.  
                      </p>                    
                    </div>
                  </div>
                </div>           
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocsContent;
