import React from 'react';


const HeroSaga = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden'      
      >
               <img
                  src='assets/img/saga-wave.svg'
                  alt=''
                  className='saga-wave'
                />
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <h2 className='fw-bold display-5'> Archiving the Past, <br/><span className='text-orange'>Shaping the Future</span></h2>
                <p className='text-dark lead'> Uniquely network business experiences for resource sucking
                  solutions. Dynamically re-engineer cooperative networks via
                  cross-media expertise.</p>
                
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >     
                </div>
              </div>
            </div>
            <div className='col-lg-9'>
     
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='200'
              >
            
                <img
                  src='assets/img/dashboard-img.png'
                  alt=''
                  className='img-fluid position-relative rounded-custom mt-5'
                />
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default HeroSaga;
