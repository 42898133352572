import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import FeatureImgContentThree from '../components/feature-img-content/FeatureImgContentThree';
import FeatureImgEight from '../components/features/FeatureImgEight';
import PromoThree from '../components/promo/PromoThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import Herotechnologies from '../components/technologies/Herotechnologies';
import TechnologiesContentOne from '../components/technologies/TechnologiesContentOne';
import TechnologiesContentTwo from '../components/technologies/TechnologiesContentTwo';
import TechnologiesContentThree from '../components/technologies/TechnologiesContentThree';

const Technologies = () => {
  return (
    <Layout>
      <PageMeta title="Service Details-Software &amp; IT Solutions HTML Template" />
      <Navbar />
      <Herotechnologies /> 
      <TechnologiesContentOne />   
      <TechnologiesContentTwo /> 
      <TechnologiesContentThree />    
      <FooterOne />
    </Layout>
  );
};

export default Technologies;
