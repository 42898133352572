import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import CustomerBrand from '../components/customer/CustomerBrand';
import SagaContentThree from '../components/Product/SagaContentThree';
import HeroSaga from '../components/Product/HeroSaga';
import SagaContent from '../components/Product/SagaContent';
import SagaContentTwo from '../components/Product/SagaContentTwo';
import SagaContentFour from '../components/Product/SagaContentFour';
import SagaContentFive from '../components/Product/SagaContentFive';
import FaqTwo from '../components/faqs/FaqTwo';

const Archival = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Saga | Document Archiving Software | Adrta' />
      <Navbar/>
      <HeroSaga />
      <CustomerBrand />
      <SagaContent />
      <SagaContentTwo />      
      <SagaContentThree />
      <SagaContentFour />
      <SagaContentFive />
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Archival;
