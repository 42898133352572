import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqOne from '../components/faqs/FaqOne';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

import PageHeader from '../components/common/PageHeader';
import QmsContentTwo from '../components/Product/QmsContentTwo';
import QmsContentThree from '../components/Product/QmsContentThree';
import QmsContentFour from '../components/Product/QmsContentFour';
import AllproductsContent from '../components/Product/AllproductsContent';
import Customer from '../components/customer/Customer';

const AllProduct = () => {
  return (
    <Layout>
      <PageMeta title='Adrta | Pioneering Software for Business' />
      <Navbar/>
      <PageHeader 
        title="Zentixs Suit"
        desc="Get more done with zentixs suit. Now integrated with Zentixs all products , all in one place."      />      
      <AllproductsContent />
      <Customer />    
      <FooterOne 
      style={{ marginTop: "120px" }}
      />
    </Layout>
  );
};

export default AllProduct;
