import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${cardDark ? 'bg-dark' : 'bg-light-gray-soft'
          }`}
      >
        <div className='container'>
          
            <div className='row align-items-center justify-content-between'>            
            <div className='col-lg-4 col-md-6'>
              <div className="img-wrap">
                <img
                  src="assets/img/product-zentixs-img01.png"
                  className="img-fluid"
                  alt="produxtImage"
                />
              </div>
            </div>
            <div className='col-lg-8 col-md-6'>
            <div className='col-md-12 tab-one-heading'>
              {cardDark ? (
                <SectionTitle
                  title='Best Services Grow Your Business Value'
                  description='Ongoing cum trendy software on your way..'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  title='All Zentixs Apps'
                  description='Ongoing cum trendy software on your way..'
                  centerAlign
                />
              )}
            </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                <Link
                to='/zentixs-docs-sop-document-review-control'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                  <div
                    className='feature-card  rounded-custom p-4 bg-white'
                  >
                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs Docs</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/zentixs-docs-sop-document-review-control'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>
                      <p className='mb-0'>
                      Revolutionize your workflow and effortlessly organize your document with our cutting-edge solution
                      </p>
                    </div>
                  </div>
                </Link>
                </div>
                <div className='col-md-6 mb-4'>
                <Link
                to='/zentixs-talent-lms-training-management'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                  <div
                    className='feature-card  rounded-custom p-4 bg-white'
                  >

                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs Talent</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/zentixs-talent-lms-training-management'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>
                      <p className='mb-0'>
                      Cultivating quality, and constructing a more intelligent organization through our platform.
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className='col-md-6 mb-4'>
                <Link
                to='/zentixs-track-qms-quality-management-incident-capa-change-control'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                  <div
                    className='feature-card  rounded-custom p-4 bg-white'
                  >
                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs Track</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/zentixs-track-qms-quality-management-incident-capa-change-control'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>
                      <p className='mb-0'>
                     Transform your business: deliver innovations, streamline compliance, and focus on quality.
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className='col-md-6 mb-4'>
                <Link
                to='/zentixs-eye-department-process-supplier-vendor-audit'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                  <div
                    className='feature-card  rounded-custom p-4 bg-white'
                  >
                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs Eye</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/zentixs-eye-department-process-supplier-vendor-audit'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>
                      <p className='mb-0'>
                      Focusing on crucial risks that directly affect the business and its strategic goals.
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className='col-md-6 mb-4'>
                <Link
                to='/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                  <div
                    className='feature-card  rounded-custom p-4 bg-white'
                  >

                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs Labs</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>
                      <p className='mb-0'>
                      Enabling automated integration of data for enhanced compliance and security with a traceable audit trail.
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className='col-md-6 mb-4'>
                <Link
                to='/AllProduct'
                className='position-relative text-decoration-none overflow-hidden m-0'
              >
                                  <div
                    className='feature-card  rounded-custom p-4 bg-white h-100' 
                  >
                    <div className='feature-content'>
                      <div className="align-items-center d-flex">
                        <h3 className='h5 flex-grow-1'>Zentixs All Product</h3>
                        <div className="flex-shrink-0">
                          <Link
                            to='/AllProduct'
                            className='link-with-icon text-decoration-none'
                          >
                            <i className='far fa-arrow-right'></i>
                          </Link>
                        </div>
                      </div>                     
                    </div>
                  </div>
                </Link>
                </div>          



              </div>
            </div>
          </div>





        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
