import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LmsContentThree = () => {
  return (
    <>
      <section className='feature-section bg-2 ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-6'>
              <div
                className='image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0'
                data-aos='fade-right'
              >
                <img
                  src='assets/img/talent.svg'
                  alt='feature img'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-6'>
              <div
                className='feature-content-wrap'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <SectionTitle
                  subtitle='Advanced Features'
                  title='Put your team on the fast track to proficiency.'
                  leftAlign
                />

                <div className='d-flex flex-column gap-3'>

                <div>
                  <h5>Assessment Methods</h5>
                  <ul class="custom-bullet-list  text-dark">
                    <li>Implements quizzes, tests, and practical demonstrations to evaluate employee understanding and application of training content.</li>
                  </ul>
                </div>

                <div>
                  <h5>Documentation</h5>
                  <ul class="custom-bullet-list  text-dark">
                    <li>Provides guidelines for recording training completion and assessment results, maintaining accurate records for tracking and audits.</li>
                  </ul>
                </div>

                <div>
                  <h5>Evaluation of Training Effectiveness</h5>
                  <ul class="custom-bullet-list  text-dark">
                    <li>Analyzes feedback and performance data to assess whether training meets its objectives and improves employee performance.</li>
                  </ul>
                </div>

                <div>
                  <h5>On-the-Job Training (OJT)</h5>
                  <ul class="custom-bullet-list  text-dark mb-0">
                    <li>Delivers practical training in the workplace, integrating theory with hands-on experience under supervision.</li>
                  </ul>
                </div>              
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LmsContentThree;
