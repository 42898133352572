import React from 'react';
import SectionTitle from '../common/SectionTitle';

const TechnologiesContentTwo = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'ptb-120'
        } `}
      >
        <div className='container'>
    
          <div className='row  justify-content-between'>

            <div className='col-lg-6 order-lg-2'>
              <div className='sticky-sidebar'>
          
                  <img
                    src='assets/img/technologies-man.png'
                    alt=''
                    className='img-fluid rounded-custom '
                  />
                 
                
              </div>
            </div>
            <div className='col-lg-5 order-lg-1 mb-7 mb-lg-0'>
              <div className='mb-5 box-content'>
                <div className='icon-box'>
                <img
                    src='assets/img/development-methodologies.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Software Development Methodologies</h3>
                <p>Agile and Scrum methodologies ensure iterative development, collaboration, and adaptability throughout the project lifecycle, delivering working software incrementally.</p>                    
              </div>  
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/tools.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>IT Project Management Tools</h3>
                <p>Utilization of tools like Jira, Trello, and Asana for efficient task tracking, issue management, and team collaboration, streamlining project workflows and improving productivity.</p>                    
              </div>  
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/version.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>Version Control Systems</h3>
                <p>Proficiency in Git and SVN for source code management, enabling effective collaboration, branching, and code review processes to maintain code quality.</p>                    
              </div>
              <div className='mb-5 box-content'>
              <div className='icon-box'>
                <img
                    src='assets/img/devops.png'
                    alt=''                  
                  />
                 
                </div>
                <h3>DevOps Practices and Tools</h3>
                <p>Implementation of DevOps practices, including Docker, Kubernetes, and CI/CD pipelines, to automate software delivery, enhance collaboration, and ensure rapid deployment with quality assurance.</p>                    
              </div>        
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologiesContentTwo;
