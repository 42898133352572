import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqOne from '../components/faqs/FaqOne';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import QmsContent from '../components/Product/QmsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import PageHeader from '../components/common/PageHeader';
import QmsContentTwo from '../components/Product/QmsContentTwo';
import QmsContentThree from '../components/Product/QmsContentThree';
import QmsContentFour from '../components/Product/QmsContentFour';
import HeroIbook from '../components/Product/HeroIbook';
import IbookContent from '../components/Product/IbookContent';
import IbookContentTwo from '../components/Product/IbookContentTwo';
import IbookContentThree from '../components/Product/IbookContentThree';
import IbookContentFour from '../components/Product/IbookContentFour';
import FaqTwo from '../components/faqs/FaqTwo';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs IBook | Inventory management | Online Inventory  Software | Adrta' />
      <Navbar/>
      <HeroIbook />
      <IbookContent />
      <IbookContentTwo />   
      <IbookContentThree />
      <IbookContentFour />
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
