import React from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';

const HeroLims = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden dark-purple-bg'   
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <h1 className='fw-bold display-5'><span className='title-color-light-blue'>A LIMS that is user friendly,</span> compliant & configurable</h1>
                <p className='lead text-white'>Uniquely network business experiences for resource sucking
                  solutions. Dynamically re-engineer cooperative networks via
                  cross-media expertise.</p>
               
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <Link to='/request-for-demo' className='btn btn-danger me-3'>
                  Request a demo
                  </Link>               
                </div>
              </div>
            </div>
            <div className='col-lg-9 dark-purple-bg2'>
            <Reveal effect="fade"> 
            <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12  mah-badge1">
                        <div className="p-4 mb-4 mb-lg-0">
                            <img src="assets/img/icon/lims1.png" alt="icon" />
                            <h6 className="mt-3 text-white text-uppercase">Quality</h6>
                        
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mah-badge2">
                        <div className="p-4 mb-4 mb-lg-0">
                        <img src="assets/img/icon/lims3.png" alt="icon" />
                            <h6 className="mt-3 text-white text-uppercase">Compliance</h6>
                          
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mah-badge3">
                        <div className="p-4 mb-4 mb-lg-0">
                        <img src="assets/img/icon/lims2.png" alt="icon" />
                            <h6 className="mt-3 text-white text-uppercase">Automation</h6>
                          
                        </div>
                    </div>
                
                </div>
                </Reveal>
            </div>
          </div>
        </div>
        <div
          className='
      bg-dark
      position-absolute
      bottom-0
      h-25
      bottom-0
      left-0
      right-0
      z--1
      py-5
    '
        ></div>
      </section>
    </>
  );
};

export default HeroLims;
