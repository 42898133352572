import React from 'react';
import { Link } from 'react-router-dom'

export default function LmsContent() {
    return (
        <section className="benifits-area pt-60 pb-120">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-5">
                        <div className="benifits-left position-relative mb-5">
                            <div>
                                <img
                                    src="assets/img/lms-img-01.jpg"
                                    className="img-fluid text-center"
                                    alt="girl"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="digi-newsletter">
                            <h2 className='title2 mb-4'>Optimizing GxP Training Efficiency
                            </h2>
                        </div>
                        <div className="benifits-right mt-5 mt-lg-0">
                            <div className="row g-5">
                                <div className="col-lg-6">
                                    <h5>New Employee Orientation</h5>
                                    <ul class="custom-bullet-list">
                                        <li>Provides onboarding training to familiarize new hires with company policies, safety protocols, and compliance expectations.</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <h5>SOP Training</h5>
                                    <ul class="custom-bullet-list">
                                        <li>Ensures employees are knowledgeable about Standard Operating Procedures, reinforcing adherence to compliance rules.</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Training Needs Assessment</h5>
                                    <ul class="custom-bullet-list">
                                        <li>Identifies skills and knowledge gaps among employees to tailor training to specific roles and regulatory requirements.</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Training Scheduling</h5>
                                    <ul class="custom-bullet-list text-dark">
                                        <li>Plans and manages regular and flexible training sessions, accommodating employee availability and compliance deadlines.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

