import React from 'react';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const PrivacyDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8'>
              <p>Your privacy is important to us, so Adrta, owned by Adrta Technologies Private Limited, its subsidiaries and its affiliates, based in Ahmedabad, Gujarat, having website URL: https://adrtatech.com/ has created the following Privacy Policy to let you know what information we collect when you visit our website, why we collect it and how it is used.</p>
              <p>The terms <b>"you," "your," "yours"</b> and <b>“User”</b> refer to the customer and/or organization using our website. The terms "Business," "we," "us,", “Website”, “Site”, “Adrta”, and "our" refer to Adrta, its website and mobile application, having website URL: <a href='#'>https://adrtatech.com/</a> (hereinafter referred to as <b>“Website”</b>). This Privacy Policy is governed by our Terms & Conditions and is an extension of the Terms & Conditions. By using this website, you consent to the data practices described in this statement. We may periodically make changes or modifications to this Privacy Policy that we will include on this page. It is your responsibility to review this Privacy Policy frequently and remain informed about any changes to it, so we encourage you to visit this page often.</p>
              <p>It is hereby declared through this Privacy Policy, that we shall collect your information through our Website to better understand you and to further improve our Website. This Privacy Policy is governed by the Digital Personal Data Protection (DPDP) Act, 2023 of India,  Central Consumer Protection Authority (CCPA) of California and General Data Protection Regulation (GDPR) of the European Union (EU), and other applicable statutes, legislations and industry regulations and if you are using this website from outside the territory of India, California and/or EU and this Privacy Policy is not compliant with the applicable legislation or statutes of your domicile country, please refrain from using this Website. Please read this Privacy Policy to be aware of the kind of information being collected and how the information is being used and/or processed.  </p>


              <h3 class="h5 pb-2">1.	INFORMATION COLLECTED AND CONTROLLED</h3>
              <p>1.1.	The information disclosed by you is used for internal purposes only. We do not sell, exchange, or release your personal information (name, e-mail address, personal data) in any way, shape or form. Your information is not shared with any other third party or company outside of agreed terms and conditions, between us and such third party or company. </p>
              <p>1.2.	We only gather necessary information, such as details provided during account creation, event registration, customer support requests, or purchases. While we retain your name and contact details, your credit card number or debit card number is not stored, unless authorized and secured within our payment gateways.</p>
              <p>1.3.	During website or software usage, basic data like site navigation and feature usage is automatically logged into, to enhance our services and inform product development.</p>
              <p>1.4.	Indirect information about you may be received through referral programs, reselling partners, or authentication services like LinkedIn, Okta, Duo, Google and/or any other such authentication services, and we utilize this data to fulfill your requests. Additionally, interactions with our brand being Adrta on social media, such as likes, comments, or follows, grant us access to your social media profile information, even if you subsequently remove your profile from such social media platforms.</p>

              <h3 class="h5 pb-2">2.	INFORMATION WE COLLECT</h3>
              <p>2.1.	We gather information about you only when necessary, for legitimate purposes only. Adrta may have information about you if (i) you provided it yourself, (ii) Adrta automatically collects it, or (iii) Adrta obtains it from a third party. Below, we detail scenarios within each category and the associated information we collect: </p>
              <p><b>(a) Information Provided By You:</b></p>
              <p>(I)	Upon signing up for an account to access the Adrta services, we request details such as your name, contact number, email address, company name, country and/or any other information we deem necessary. While additional information like a photo, time zone, and language may be provided, they're not mandatory for account creation. Additionally, you may opt to set a security question and security answer, for password reset purposes.</p>
              <p>(II)	We record information submitted during event registrations, newsletter subscriptions, product downloads, participation in contests or surveys, or when requesting customer support or quotes.</p>
              <p>(III)	When making purchases, we require your name, contact details, and payment information. Credit card, debit card and/or other payment method details are not stored, only the cardholder's name, address, expiry date, last four digits of the card number, and/or any other partial information may be retained for future payment processing, with your exclusive consent.</p>
              <p>(IV)	If authorized, we may include your name and other personal information in testimonials posted on our Website. You have the opportunity to review and approve these testimonials, before the posting of such testimonials.</p>
              <p>(V)	Interactions via email, telephone, or chat with our sales and support teams may be recorded, analyzed, and utilized to enhance customer interactions and improve our services.</p>

              <p><b>(b)	Information Collected Automatically</b></p>
              <p>(I) Information such as IP address, browser type, language preference, referring URL, and device details are collected during website visits.</p>
              <p>(II) Temporary and permanent cookies are employed to identify users, track website navigation, and improve overall user experience. We primarily use first-party cookies and refrain from using third-party tracking technologies on our websites.</p>
              <p>(III) Data on product usage, errors, performance, and device details are collected to understand User needs and improve our products and services.</p>

              <p><b>(c)	Information Obtained from Third Parties</b></p>
              <p>(I)	Logging in via federated authentication service providers may grant us access to your personal information, like name and email address.</p>
              <p>(II) Information provided by referral service providers or reselling partners, or shared at events sponsored by Adrta, may be received. Requests to remove such information can be made by sending a request to the email address being privacy@adrtatech.com.</p>
              <p>(III) 2.1.3.3.	Information about you may be obtained from reselling partners, service providers, or review sites, if you express interest in Adrta's products or services, through them.</p>
              <p>(IV) 2.1.3.4.	Publicly available information about you from social media interactions, review sites, or other sources may be collected to improve our products and services, understand user reactions, or reproduce feedback. Adrta may retain this information even if such information is deleted from social media sites.</p>

              <h3 class="h5 pb-2">3.	PURPOSES OF USING YOUR INFORMATION</h3>
              <p>3.1.	In addition to the previously mentioned purposes, we may utilize your information for the following purposes:</p>
              <p>(a)	We may reach out to you via email, regarding products downloaded, services signed up for, changes to this Privacy Policy or our Terms and Conditions, and other important agreements, policies and notices.</p>
              <p>(b)	Keeping you informed about our new products, services, upcoming events, promotions, and other relevant information.</p>
              <p>(c)	Requesting your participation in surveys or feedback collection to enhance our products and services.</p>
              <p>(d)	Setting up, maintaining, and providing necessary actions for your account, such as enabling collaboration, website and email hosting, and data backup.</p>
              <p>(e)	Understanding user behavior to monitor, prevent issues, and enhance our products and services.</p>
              <p>(f)	Providing assistance and enhancing interactions with end customers.</p>
              <p>(g)	Detecting and preventing fraudulent activities, reporting spam, and safeguarding the rights of Adrta, its users, third parties, and the public.</p>
              <p>(h)	Updating, expanding, and analyzing records, identifying potential customers, and offering relevant products and services.</p>
              <p>(i)	Analyzing trends, administering websites, and tracking visitor navigation to optimize User experience.</p>
              <p>(j)	Monitoring and enhancing marketing efforts and providing relevant suggestions to Users.</p>

              <h3 class="h5 pb-2">4.	YOUR PREFERENCE IN INFORMATION USAGE</h3>
              <p>4.1.	You have the option to unsubscribe from newsletters and other non-essential messages sent to your registered email address, provided by you, by utilizing the 'unsubscribe' function included in such email communications. However, essential notices and emails, such as account notifications (password changes, renewal reminders, etc.), security incident alerts, privacy updates, and essential transactional and payment-related emails, shall still be delivered to your registered email address.</p>
              <p>4.2.	Before visiting our websites, you can choose to disable browser cookies. Keep in mind that disabling cookies may impact your ability to effectively utilize certain website features provided by us.</p>
              <p>4.3.	You have the freedom to opt out of providing optional profile information, including but not limited to your photograph. Additionally, you can delete or modify your optional profile details at any time, directly through the Website. You also have the choice to refrain from filling in non-mandatory fields, while filling and submitting forms linked to our Website.</p>

              <h3 class="h5 pb-2">5.	ENTITIES WITH ACCESS TO YOUR INFORMATION</h3>
              <p>5.1.	All Adrta group entities listed here have access to the information as outlined in the terms of this Privacy Policy. We do not engage in the sale of your personal information. Your information is shared only as described in the terms of this Privacy Policy and solely with parties who adhere to appropriate confidentiality and security protocols.</p>
              <p>5.2.	Relevant employees and independent contractors of Adrta and Adrta group entities may have access to your information, as outlined in this clause, on a need-to-know basis. All employees and independent contractors are required to adhere to this Privacy Policy, regarding shared personal information.</p>
              <p>5.3.	Personal information, along with aggregated or de-identified data, may be shared with third-party service providers engaged by Adrta, including marketing and advertising partners, event organizers, web analytic service providers, and payment processors. These providers are authorized to use your personal information, solely to fulfill their designated services.</p>
              <p>5.4.	When registering a domain through Adrta via domain name registrars, your name and contact details such as physical address, email address, and phone number may be shared with such domain name registrars, in compliance with ICANN domain registration regulations.</p>
              <p>5.5.	Personal information may be shared with authorized reseller partners in your region, solely for the purpose of contacting you regarding products downloaded or services signed up for, by you. You will have the option to opt out of continued engagement with that partner.</p>
              <p>5.6.	If you install or purchase an application developed, using Adrta's APIs, from Adrta's online marketplace, your name and email address will be shared with the application developer. This allows them to directly engage with you as the provider of the application or service. Adrta does not govern the use of your personal information by developers, as it is subject to their own privacy policies and website terms and conditions.</p>

              <h3 class="h5 pb-2">6.	WHAT WE DO WITH YOUR INFORMATION</h3>
              <p>6.1.	We utilize your data to fulfill requested services, manage account creation and maintenance, and monitor for unauthorized account activity. Additionally, we use it to communicate with you regarding current product usage, customer support inquiries, product recommendations, feedback opportunities, and privacy policy updates. Data analysis is conducted to understand user requirements and enhance our Website and the services offered by us.</p>
              <p>6.2.	Adrta ensures a legal basis for data collection and processing, typically obtained through consent or necessity, to provide requested services. In cases where alternative legal bases are necessary, such as legitimate business interests, we provide justification for such alternative legal bases.</p>
              <p>6.3.	You retain the option to decline certain data uses, by either refraining from initially providing the information, or opting out at a later stage. Disabling cookies may prevent your browser from transmitting information, though it may impact the Website’s functionality. We fully deactivate non-essential and intrusive third-party cookies across all Adrta platforms.</p>
              <p>6.4.	Access to personal information is restricted to Adrta employees and contractors, unless such usage is in compliance with legitimate usage needs. When sharing data with external parties including but not limited to developers, service providers, domain registrars, and reselling partners, they are required to maintain appropriate security measures and justify data usage, in order to serve you.</p>
              <p>6.5.	Adrta acknowledges and provides data subjects such as you in India, California, the European Union, and the European Economic Area (EEA) with certain rights, including access, rectification, erasure, processing restriction, data portability, objection, and complaint resolution, and such rights are extended to other Users as well, regardless of the User’s residence and/or domicile country.</p>
              <p>6.6.	We retain personal data only, as long as necessary, for the purposes outlined in this Privacy Policy. Upon cessation of legitimate data processing needs, we shall delete, destroy, anonymize, or isolate your data, as appropriate and/or as required.</p>

              <h3 class="h5 pb-2">7.	YOUR RIGHTS REGARDING INFORMATION HELD BY US AS A CONTROLLER </h3>
              <p>7.1.	If you are located within the territory of India, California, European Union or the European Economic Area (EEA), you possess the following rights concerning information held by Adrta. Adrta commits to providing you with these rights regardless of your chosen place of residence and/or domicile.</p>
              <p>7.2.	You have the right to access, and obtain a copy, if necessary, to the categories of personal information we hold about you, including but not limited to details about its source, purpose, processing period, and the recipients of the information.</p>
              <p>7.3.	You have the right to update or correct any inaccuracies in the information we hold about you. Depending on the purpose of data usage, you can request the addition of supplementary information to our database.</p>
              <p>7.4.	You have the right to request the deletion of your personal information under certain circumstances, such as when it is no longer necessary for the original purpose of collection.</p>
              <p>7.5.	In certain situations, you may request limitations on the use of your information, such as when you object to its use and when we need to verify whether we have legitimate grounds to continue using your information.</p>
              <p>7.6.	You have the right to transfer your information to a third party in a structured, commonly used, and machine-readable format, provided the information is processed with your consent or by automated means.</p>
              <p>7.7.	You can object to the use of your information in certain instances, such as for direct marketing purposes.</p>
              <p>7.8.	If you have any concerns regarding the way we collect, use, or share your information, you have the right to lodge a complaint with the appropriate supervisory authority. Note that this right may not be available if there is no data protection supervisory authority in your home country.</p>

              <h3 class="h5 pb-2">8.	RETENTION OF INFORMATION</h3>
              <p>8.1.	We keep your personal information only, for as long as necessary, for the purposes outlined in this Privacy Policy. At times, we might retain your data for extended periods, as allowed or required by applicable law in the territory of India. This could include maintaining suppression lists, preventing abuse, fulfilling legal obligations, enforcing agreements, or meeting tax and accounting requirements. Once we no longer require your information for legitimate purposes, we will either delete, destroy or anonymize such information from our active databases. Additionally, we securely store and isolate the information on backup discs and/or the cloud, until it can be successfully deleted or destroyed.</p>

              <h3 class="h5 pb-2">9.	PROCESSING OF INFORMATION</h3>
              <p>9.1.	If you utilize Adrta's website to manage others' data, such as customer or employee information, you are placing that data in our care for processing. When you grant the Adrta Website or mobile application access to your contacts or photo library, you are entrusting us with your data. This data entrusted to us for processing is referred to as service data and all the information entrusted to Adrta is collectively referred to as <b>"service data''</b>.</p>
              <p>9.2.	You retain ownership of your service data and we merely safeguard it, restrict access to it, and only handle it, in accordance with your instructions. You have the right to access, share, request exportation, or deletion of this data, including but not limited to, through third-party integrations.</p>
              <p>9.3.	Your data remains in your account as long as you continue using the services offered by Adrta. Upon the termination of your account, your data is automatically removed from our active database within a maximum period of six (6) months, followed by deletion from our backups within a maximum period of three (3) months thereafter.</p>
              <p>9.4.	If you are located in India, California, European Union or the European Economic Area and believe that someone has entrusted your information to us for processing (e.g., your employer or a company whose services you use), you can request specific actions regarding your data. To exercise these data rights, please contact the individual or company that entrusted the data to us, and we will collaborate with them on your request.</p>
              <p>9.5.	You may deliver information that you or your organization manage, to Adrta concerning the utilization of our services or for soliciting technical assistance for our products or services. This encompasses details regarding your clientele and your workforce (if you act as a controller) or data that you possess and utilize on behalf of another individual for a distinct objective, such as a customer for whom you render services (if you act as a processor). The data may either be retained on our servers during your utilization of our services, or transferred or disclosed to us as part of a request for technical support or other services.</p>
              <p>9.6.	When you opt to authorize it, our Website and/or mobile applications possess access to the camera, microphone, call history, contact details, photo library, files, and additional information stored on your portable device. Our Website and/or mobile applications necessitate such access to deliver their services. Correspondingly, when you opt to grant access, location-based information is also gathered for purposes comprising, but not confined to, locating nearby contacts or setting location-based reminders. This information will be exclusively divulged, to our mapping providers, and will be utilized solely for mapping the User’s locations. You may deactivate the mobile applications' access to this information at any moment by adjusting the settings on your portable device. The data stored on your portable device and their location information to which the mobile applications possess access will be utilized within the context of the mobile application, and transferred to and associated with your account in the corresponding services (in which case the data will be retained on our servers) or products (in which case the data will remain with you unless shared with us). </p>
              <p>9.7.	We may grant access to your service data to our employees and individuals who are independent contractors of the Adrta group entities involved in providing the services (collectively our <b>"employees"</b>) so that they can (i) identify, analyze, and resolve errors, (ii) manually verify emails reported as spam to improve spam detection, or (iii) manually verify scanned images that you submit to us to verify the accuracy of optical character recognition. We ensure that access by our employees to your service data is limited to specific individuals and is logged and audited. Our employees will also have access to the data that you knowingly share with us for technical support or to import data into our products or services. We communicate our privacy and security guidelines to our employees and strictly enforce privacy safeguards within the Adrta group.</p>
              <p>9.8.	Some of our products or services allow you to collaborate with other users or third parties. Initiating collaboration may enable other collaborators to view some or all of your profile information. For example, when you edit a document that you have shared with other persons for collaboration, your name and profile picture will be displayed next to your edits to allow your collaborators to know that you made those edits.</p>
              <p>9.9.	Most of our products and services support integrations with third-party products and services. If you choose to enable any third-party integrations, you may be allowing the third party to access your service data and personal information about you. We encourage you to review the privacy practices of the third-party services and products before you enable integrations with them.</p>
              <p><b>9.10.	Ownership Of Service Data</b></p>
              <p>(a)	We acknowledge that you possess your service data and we afford you complete control of your service data by offering you the capability to (i) access your service data, (ii) share your service data through supported third-party integrations, and (iii) request export or deletion of your service data.</p>
              <p><b>9.11.	How We Utilize Your Service Data</b></p>
              <p>(a)	We process your service data when you furnish us with instructions through the various components of our services. For instance, when you generate an invoice utilizing our invoicing service, details such as the name and address of your customer will be utilized to generate the invoice; and when you utilize our campaign management service for email marketing, the email addresses of the individuals on your mailing list will be employed for sending the emails and may be stored by us.</p>
              <p><b>9.12.	Push Notifications</b></p>
              <p>(a)	If you have enabled notifications on our desktop and mobile applications, we will transmit notifications through a push notification provider such as Apple Push Notification Service, Google Cloud Messaging, Windows Push Notification Services and/or any other push notification provider. You can manage your push notification preferences or deactivate these notifications by disabling notifications in the application or device settings.</p>
              <p><b>9.13.	Who We Share The Service Data With</b></p>
              <p>(a) To provide services and technical support for our products, the contracting entity within the Adrta group engages other group entities and third parties.</p>
              <p><b>9.14.	Retention Of Information</b></p>
              <p>(a)	We retain the data in your account as long as you opt to utilize Adrta Services. Once you terminate your Adrta User account, your data will eventually be expunged from the active database during the subsequent cleanup that occurs once in every six (6) months. The data expunged from the active database will be erased from backups after a period of three (3) months.</p>
              <p><b>9.15.	Data Subject Requests</b></p>
              <p>(a)	If you are from India, California, European Union and/or the European Economic Area and you believe that we store, use, or process your information on behalf of one of our customers, please contact the customer if you would like to access, rectify, erase, restrict, or object to the processing, or export of your personal data. We will extend our support to our customer in responding to your request within a reasonable timeframe.</p>

              <h3 class="h5 pb-2">10.	LEGAL BASIS</h3>
              <p>10.1.	If you are an individual from India, California, European Union or the European Economic Area (EEA), the legal basis for our information collection and usage depends on the specific personal information and the circumstances surrounding its collection. Typically, our activities for collecting and processing information are based on: </p>
              <p>(a)	contractual necessity; </p>
              <p>(b)	legitimate interests of Adrta or a third party, provided they do not outweigh your data protection interests; or </p>
              <p>(c)	 your consent. </p>
              <p>10.2.	There may be instances where we are legally obligated to gather your information or where it's necessary to protect your vital interests or those of another individual.</p>
              <p>10.3.	If we rely on your consent to share your information or data with us, as the legal basis, you retain the right to withdraw your consent at any time. However, this will not affect any processing that has already occurred.</p>
              <p>10.4.	In cases where we rely on legitimate interests as the legal basis, and those interests are not outlined above, we will clearly elucidate to you what those legitimate interests are at the time of collection of the information.</p>

              <h3 class="h5 pb-2">11.	COOKIES AND HOW ARE COOKIES USED</h3>
              <p>11.1.	We track your information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a User’s web browser from our servers and are stored on the User’s computer hard drive. Sending a cookie to a User’s web browser enables us to collect Non-Personal information about the particular User and keep a record of the User’s preferences when utilizing our Services, both on an individual and aggregate basis. The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.</p>
              <p><b>11.2.	Essential Cookies</b></p>
              <p>(a)	These cookies are essential for the basic functionalities offered by our Website and the products offered through our Website. This class of cookies helps in keeping an User logged in to the Service and helps remember relevant information when they return to the Service.</p>
              <p><b>11.3.	Preferential Cookies</b></p>
              <p>(a)	Functional and/or preferential cookies enable us to recall your selections (such as username, language, or region) and additional functionalities (such as managing the cookie banner, redirection to another page) to offer a more tailored online experience. These preferences are retained (via persistent cookies) to eliminate the need for reconfiguration upon subsequent visits to the page.</p>
              <p><b>11.4.	Analytical Cookies</b></p>
              <p>(a)	Analytical cookies aid us in enhancing the functionality of our Website (e.g., ensuring Users easily find desired information) by tracking visit data. This data enables us to refine our services and assess performance. For instance, we analyze visitor behavior to offer more pertinent content or recommend specific activities. Additionally, these cookies gather data on website usage, including referral sources, visit frequency, and session durations. We may also employ analytics cookies for testing new pages or features to gauge user response.</p>
              <p><b>11.5.	Marketing Cookies</b></p>
              <p>(a)	We also use some marketing cookies provided by third parties to collect and analyze various information about the visitors to the Adrta Website and Users of the Website. No personal or intrusive information is collected in this process though.</p>
              <p>(b)	You have the choice of accepting or declining the use of cookies through your web browser. </p>
              <p>(c)	Please note that if you wish to turn off the cookies in your web browser, you might not be able to take advantage of many features of our products and services, offered on our Website.</p>
              <p><b>11.6.	Third Party Cookies</b></p>
              <p>(a) 11.6.1.	A third-party cookie originates from a domain different from the one you are currently visiting (e.g., cookies set by google.com on https://adrtatech.com/). While some of Adrta's technologies and applications may be hosted on third-party domains, our commitment to privacy remains consistent across all domains.</p>
              <p>(b) 11.6.2.	Adrta strictly prohibits third-party tracking companies from setting cookies or other trackers on our Website to safeguard User privacy. However, when embedding third-party hosted content on our website, the third-party may set cookies on your browser for intended service purposes (e.g., bandwidth management, language preference storage, tracking consent, and anonymous statistical data collection). Whenever feasible, we prioritize privacy-friendly options such as privacy mode and anonymous statistics only, to ensure User privacy. Nonetheless, some aspects of embedded content may not be entirely within our control, including the ability to unilaterally manage cookies set by these third parties upon loading of the Website’s webpage.</p>
              <p><b>11.7.	Management of Cookies</b></p>
              <p>(a)	You have the option to adjust your cookie preferences at any time by selecting "Manage Cookie Preference" at the top of this page or by clicking the relevant icon indicating a cookie located at the bottom left corner of the webpages.</p>
              <p>(b)	Many browsers offer settings preferences to manage cookies. However, restricting the Website from setting cookies could potentially diminish your user experience by removing personalization features. It may also prevent you from saving customized settings such as login information. Browser manufacturers typically provide guidance on managing cookies within their products.</p>

              <h3 class="h5 pb-2">12.	GENERAL</h3>
              <p>12.1.	There are constraints on the privacy assurances that we can provide you. We may reveal personal information if it becomes necessary to adhere to a legal obligation, prevent fraud, enforce an agreement, or safeguard the safety of our Users. Presently, we do not respect the do not track signals from internet browsers. However, if a universal standard for processing them arises, we shall adhere to such universal standard for processing.</p>
              <p>12.2.	Third-party websites and social media widgets maintain their own distinct privacy policies. It is best practice to always review the pertinent privacy policy before divulging personal information to third parties.</p>
              <p><b>12.3.	Personal Information Of Children</b></p>
              <p>(a)	Our products and services aren't aimed at individuals under the age group of sixteen (16) years. Adrta doesn't knowingly gather personal information from children under sixteen (16) years old for its own use. If we discover that a child under sixteen (16) has given us personal information, we'll take measures to delete such data. If you believe that a child under sixteen (16) has provided personal information to us, please contact us at privacy@adrtatech.com with the details of such User being under the age of sixteen (16) years, and we shall take the necessary actions or steps to remove the information we have about that child. However, while using our products, you may choose to collect information about individuals who may be children, being below the age of sixteen (16) years and if you handle information concerning children being sixteen (16) years old, you acknowledge and consent that you'll be responsible for complying with the applicable laws and regulations regarding the protection of such personal information of children, being sixteen (16) years old.</p>
              <p><b>12.4.	Security of Your Information</b></p>
              <p>(a)	At Adrta, we prioritize data security which is why we've implemented suitable administrative, technical, and physical safeguards to prevent unauthorized access, use, alteration, disclosure, or destruction of the information you provide to us. </p>
              <p><b>12.5.	Data Protection Officer</b></p>
              <p>(a)	We've designated a Data Protection Officer to oversee our handling of your personal information in line with this Privacy Policy. If you have any questions or concerns about our privacy practices regarding your personal information, you can contact our Data Protection Officer by emailing nitin.patel@adrtatech.com or by writing to the following address: </p>
              <p>Data Protection Officer, <br />
                1209, SATYAMEV EMINENCE,  <br />
                Science City Rd,  <br />
                Sola, Ahmedabad,  <br />
                Gujarat 380060.
              </p>
              <p><b>12.6.	Locations And International Transfers</b></p>
              <p>(a)	We share your personal information and service data within the Adrta group and with third parties engaged by the Adrta group for the purposes described above. By accessing or utilizing our products and services or otherwise providing personal information or service data to us, you understand that the processing, transfer, and storage of your personal information or service data may occur within the territory of India and/or other countries where Adrta operates. Such transfers are governed by appropriate data protection agreements such as a group company agreement based on the applicable laws of GDPR and Digital Personal Data Protection (DPDP) Act, 2023. You can contact shailesh@adrtatech.com to obtain a copy of the agreements under which we transfer your data within the Adrta group and with the third parties engaged by us.</p>
              <p><b>12.7.	Automation And AI</b></p>
              <p>(a)	To enhance productivity and predictive capabilities for our users, we utilize various technologies such as regex parsing, template matching, artificial intelligence, and machine learning. Adrta ensures that your data is used in a manner that respects your privacy and confidentiality expectations. We employ your service data for these technologies in limited ways, including using anonymized subsets of service data to enhance algorithm accuracy and utilizing your organization's data to develop tailored models. Our automation and artificial intelligence technologies primarily rely on our organization's data, including internal communications, customer communications, internal documents, as well as data from free and paid external sources.</p>
              <p><b>12.8.	DNT Requests</b></p>
              <p>(a)	Certain internet browsers support 'Do Not Track' (DNT) features, which transmit a signal (DNT signal) to visited websites indicating a User's preference not to be tracked. Presently, there's no established standard governing websites' actions upon receiving these signals and at this time, we do not respond to these signals.</p>
              <p><b>12.9.	External Links On Our Websites</b></p>
              <p>(a)	Some pages on our websites may contain links to third-party websites not covered by this Privacy Policy. If you provide personal information on these third-party sites, their privacy policies govern the use of your personal information. We advise caution in sharing personal information with third parties unless you've reviewed their privacy policies and are confident in their privacy practices.</p>
              <p><b>12.10.	Forums and Blogs</b></p>
              <p>(a)	We host publicly accessible blogs and forums on our Website. Please be mindful that any information you share on these platforms may lead to unsolicited messages. Exercise caution when disclosing personal information on our blogs and forums, as we're not responsible for publicly disclosed personal information. Your posts and certain profile details may persist even after account termination. To request removal of your information from our blogs and forums, contact us at support@adrtatech.com.</p>
              <p><b>12.11.	Social Media Widgets</b></p>
              <p>(a)	Our Website features social media widgets like the WhatsApp “share” buttons, Facebook "react" buttons, Twitter "tweet" buttons, Instagram “share” button and other buttons for other social media platforms, for sharing content directly through our Website. These widgets may gather information such as your IP address and visited pages, and may set cookies to function properly. Interactions with these widgets are subject to the privacy policies of the respective companies providing them.</p>
              <p><b>12.12.	Disclosures In Compliance</b> </p>
              <p>(a)	We may be obligated by law to retain or disclose your personal information and service data to comply with applicable laws, regulations, legal processes, or governmental requests, including national security requirements.</p>
              <p><b>12.13.	Enforcement Of Our Rights</b></p>
              <p>(a)	Personal information and service data may be disclosed to third parties if necessary for preventing fraud, spam filtering, investigating suspected illegal activities, enforcing agreements or policies, or ensuring User safety.</p>
              <p><b>12.14.	Business Transfers</b></p>
              <p>(a)	While we don't intend to sell our business, in the event of a sale, acquisition, or merger, we'll ensure that the acquiring entity honors our commitments to you. We'll notify you of any changes in ownership or usage of your personal information and service data via email or prominent notices on our Website.</p>
              <p><b>12.15.	Compliance With This Privacy Policy</b></p>
              <p>(a)	We regularly review and endeavor to ensure that personal information provided is used in line with this Privacy Policy. If you have concerns about our compliance or the use of your personal information, contact us at support@adrtatech.com. We'll address your concerns and liaise with appropriate regulatory authorities if necessary.</p>
              <p><b>12.16.	Modifications And Notifications</b></p>
              <p>(a)	We reserve the right to modify the Privacy Policy, with notices provided through service announcements or emails to your primary email address. Significant changes affecting your rights will be communicated at least thirty (30) days in advance. If you believe the updated Privacy Policy affects your rights, you may terminate use within thirty (30) days. Continued use after the effective date constitutes agreement to the modified Privacy Policy. Minor changes won't trigger email notifications; periodic review of our Privacy Policy is advised at https://adrtatech.com/privacy.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyDetails;
