import React, { useState } from 'react';
import accordiondata from '../../json/FaqSign.json';
import SectionTitle from '../common/SectionTitle';


function FaqSign() {
  const [accordionItems, setAccordionItems] = useState(accordiondata);

  const toggleAccordion = (id) => {
    setAccordionItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  return (
    <>
    <section className='faq-section ptb-120'>
    <div className='container'>
    <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>              
            </div>
          </div>
          <div className='row justify-content-center'> 
          <div className='col-lg-10 col-12'>     
    <div className="accordion faq-accordion" id="accordionExample">
      {accordionItems.map((item) => (
        <div className="accordion-item border border-2" key={item.id}>
          <h2 className="accordion-header" id={`heading${item.id}`}>
            <button
              className={`accordion-button ${item.isOpen ? '' : 'collapsed'}`}
              type="button"
              onClick={() => toggleAccordion(item.id)}
              aria-expanded={item.isOpen ? 'true' : 'false'}
              aria-controls={`collapse${item.id}`}
            >
              {item.title}
            </button>
          </h2>
          <div
            id={`collapse${item.id}`}
            className={`accordion-collapse collapse ${
              item.isOpen ? 'show' : ''
            }`}
            aria-labelledby={`heading${item.id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
  );
}

export default FaqSign;

