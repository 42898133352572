import React from 'react';
import Reveal from 'react-reveal/Reveal';


const EyeContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120 pb-60'>
        <div className='container'>
          <div className='rounded-custom light-red-bg'>
          <div className='row align-items-center justify-content-center '>
          <h2 className='title2 text-center pt-5'>Why choose Zentixs Eye
</h2>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
              <div className="cyber feature-info-wrap mb-4">
                <h3 className="h5">Audit Trail and Compliance Tracking</h3>
                <ul className="list-unstyled mb-0 text-dark-gray eye-list ps-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Maintain an audit trail of all actions taken within the software for compliance purposes.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Tracking capabilities to ensure adherence to regulatory standards and internal policies.</li>
                </ul>
              </div>

              <div className="cyber feature-info-wrap">
                <h3 className="h5">User Management and Permissions</h3>
                <ul className="list-unstyled mb-0 text-dark-gray eye-list ps-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Role-based access controls to restrict sensitive audit information to authorized users.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Features for assigning auditors and managing their permissions within the system.</li>
                </ul>
              </div>
              
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
            <Reveal effect="fadeinbottom">
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/audit-01.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
              </Reveal>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EyeContentTwo;
