import React from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../../components/common/HeroTitle';

const HeroLog = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden red-bg-gradient'      
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6'>
              <div className='hero-content-wrap'>
                <h2 className='fw-bold display-4'> Simplifying Occurrent Records with <span className='text-red'>E-Logbook</span></h2>
                <p className='text-dark lead mb-0 mt-5'> 
                Zentixs log (e-logbook) is a digital tool used for recording, tracking, and managing information in various industries, including manufacturing, pharmaceuticals, laboratories, and research environments.</p>
                
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >     
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='200'
              >
            
                <img
                  src='assets/img/log-img.png'
                  alt=''
                  className='img-fluid position-relative rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default HeroLog;
