import React from 'react';
// import SectionTitle from '../common/SectionTitle';

const TimelineAboutus = ({ paddingTop }) => {
    return (
        <>
            <section className={`feature-section-two ${paddingTop ? 'ptb-120' : 'pt-120 pb-120'} `}>
                <div className='container'>

                    <div className="row justify-content-center">
                        <div className='col-md-9'>
                            <div className='text-center'><h2 className='title2 pb-5'>Our journey through time.</h2></div>
                            <section className="main-timeline-section">

                                <div className="timeline-start"></div>
                                <div className="conference-center-line"></div>
                                <div className="conference-timeline-content">
                                    <div className="timeline-article content-right-container">
                                        <div className="content-date">
                                            <span>2012</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>The inception of Infinium Global Technologies marked the genesis of an innovative information technology firm.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-article content-left-container">
                                        <div className="content-date">
                                            <span>2013</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>Our journey of transformation began, spanning across diverse sectors such as agriculture, transport, manufacturing, finance, and pharmaceuticals, catalyzing a significant technological shift across multiple industries.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-article content-right-container">
                                        <div className="content-date">
                                            <span>2014</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>We expanded into research and pharmaceutical firms, introducing a range of product software solutions tailored to their needs.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-article content-left-container">
                                        <div className="content-date">
                                            <span>2015</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>A significant milestone was reached with the development of Infinium Plus software, integrating document management and training products for enhanced functionality.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-article content-right-container">
                                        <div className="content-date">
                                            <span>2017</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>Our innovation continued as we combined instruments, e-clinical tools, and HRMS products into the comprehensive Infinium Plus platform.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-article content-left-container">
                                        <div className="content-date">
                                            <span>2019</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>Our dedication yielded remarkable results, as we proudly secured the patronage of the top 10 pharmaceutical companies, a testament to the value our multiple products offered.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-article content-right-container">
                                        <div className="content-date">
                                            <span>2020</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>With unwavering commitment to quality, we united all our products into a singular platform, unveiling the Zentixs suite encompassing Saga, Log, iBook, Sprint, Track, and Eye.
</p>
                                        </div>
                                    </div>
                                    <div className="timeline-article content-left-container">
                                        <div className="content-date">
                                            <span>2021</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>The momentum persisted with the introduction of new offerings – Sign and Desk – further enriching our product portfolio.</p>
                                        </div>
                                    </div>




                                    <div className="timeline-article content-right-container">
                                        <div className="content-date">
                                            <span>Till Date</span>
                                        </div>
                                        <div className="meta-date"></div>
                                        <div className="content-box">
                                            <p>Marking a significant milestone, the company was rechristened as Adrta Technologies Private Limited, symbolizing our evolution and commitment to technological excellence.</p></div>
                                    </div>
                                </div>
                                <div className="timeline-end"></div>
                            </section>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TimelineAboutus;
