import React from 'react';
import SectionTitle from '../common/SectionTitle';

const IbookContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-6 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/ibook2.jpg'
                  className='img-fluid rounded-custom'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
                <div className='mb-32'>
                  <h2 className='title2'>Inventory management reports</h2>
                </div>
                <p>Ibook inventory management solutions provide inventory-specific reports through which you can gain real-time insights about how much inventory you have and when to order more or fewer stocks.

</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IbookContentThree;
