import React from 'react';
import SectionTitle from '../common/SectionTitle';

const PromoThree = ({hasBg}) => {
  return (
    <>
      <section className={`promo-section ptb-120 ${hasBg?'bg-light':''}`}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                title='Assistance Subscriptions Plans'
                description='Choose from our flexible Assistance Subscriptions Plans for reliable and ongoing support whenever you need it.
                '
                  centerAlign
              />
            </div>
          </div>
          <div className='row gap-5 justify-content-center' >
            <div className='col-md-5 col-lg-5 border border-2 p-5  promo-border-hover bg-white rounded-custom '>
              <div
                className='promo-card-wrap  pb-4 pb-md-4'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div className='promo-card-info border-bottom  pb-4 pb-md-4'>
                  <h2 className='h5'>Classic Support</h2>
                  <p className='mb-0'>
                  Our dedicated support team is available Monday to Friday from 9:00 am to 5:00 pm during business hours via various communication channels such as email, calls and chat to ensure quick assistance during limited time periods. Moreover, we provide the added convenience of scheduling remote support sessions, enabling seamless troubleshooting and expert guidance.
{' '}
                  </p>
                </div>              
              </div>
              <div className='promo-card-info'>              
                  <h2 className='h5'>Premium Support</h2>
                  <p className='mb-0'>
                  Our dedicated support team is available round the clock from Monday to Friday, offering assistance through various communication channels within specified hours. The advantage of a dedicated support team is timely assistance and prompt and reliable solutions to any issues or queries.
                  </p>
                </div>
            </div>        
            <div className='col-md-6 col-lg-6 border border-2 p-5  promo-border-hover bg-white rounded-custom'>
              <div
                className='promo-card-wrap mb-4 mb-lg-0 mb-md-0'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='promo-card-info'>
                 
                  <h2 className='h5'>Enterprise Support</h2>
                  <p>
                  A dedicated point of contact works exclusively for your business, offering prioritized issue resolution, insightful guidance, and pre-emptive support, all in one, for an unmatched support journey.
                  </p>
                  <p>Dedicated support providers offer round-the-clock support</p>
                  <p>The expertise of dedicated engineers allows for quicker identification and resolution of issues, resulting in minimum downtime. This helps your business maintain smooth operations and prevent potential revenue loss.</p>
                  <p>By entrusting any challenges to our dedicated support team, your business can center its focus on core operations, free from concerns about technical issues, providing you with a sense of peace of mind throughout.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PromoThree;
