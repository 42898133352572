import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';
import Reveal from 'react-reveal/Reveal';


const HeroQms = () => {
  return (
    <>
      <section
        className='hero-section ptb-120'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom center",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start'
                data-aos='fade-right'
              >
                <HeroTitle
                  title='Driving GxP Compliance and Quality'
                  desc='Zentixs Track streamlines quality management, enhancing productivity, reducing risks, and ensuring compliance.'
                />
              
              </div>
            </div>
            <div className='col-xl-7 col-lg-6 mt-4 mt-xl-0'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-img-wrap position-relative'>
                  <div className='hero-screen-wrap'>
                  <Reveal effect="fadeinbottom">
                    <div className='qms'>
                      <img
                        src='assets/img/track.svg'
                        alt='hero '
                        className='position-relative img-fluid'
                      />
                    </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroQms;
