import React from 'react';

export default function SignContentTwo() {
  return (
    <section className="digi-news-letter">
      <div className="container">
        <div className="bg-black text-light rounded-custom p-4 p-md-5 p-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="digi-newsletter">
             
                <h3 className='text-white fs-sign'><span className='text-yellow'>Why e-Sign? </span>On paper signature is time-consuming and paper shelf will be overloaded over the time. With eSignature we can digitally sign documents from anywhere and keeps our modern business moving forward. This is also legally valid in most countries.
</h3>
        
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/sign-1.png"
                  className="img-fluid text-end"
                  alt="pie"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
