import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../common/HeroTitle';
import Reveal from 'react-reveal/Reveal';
import PaymentCustomer from '../customer/PaymentCustomer';


const HeroDesk = () => {
 
  return (
    <>
      <section
        className='hero-section bg-desk text-white ptb-30'        
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5 hero-doc'>
                <HeroTitle
                  title='Empower Your Support Team and Simplify Customer Service with Zentixs Desk!'
                  desc='Zentixs Desk transforms customer complaints into quick resolutions, ensuring your support team delivers exceptional service every time.'                  
                />          
              
              </div>
            </div>
            <div className='col-lg-6 col-md-8'>
            <Reveal effect="fadeinright">
              <div className='hero-img position-relative circle-shape-images'>             
                {/* <!--animated shape end--> */}
                <img
                  src='assets/img/desk-dashboard.png'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                />
              </div>
              </Reveal>


            </div>
          </div>
        
        </div>
        <PaymentCustomer />
      </section>
    </>
  );
};

export default HeroDesk;
