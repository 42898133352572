import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqTwo from '../components/faqs/FaqTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroCaliber from '../components/Product/HeroCaliber';
import CaliberContent from '../components/Product/CaliberContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import CaliberContentThree from '../components/Product/CaliberContentThree';
import CaliberContentTwo from '../components/Product/CaliberContentTwo';
import CaliberContentFour from '../components/Product/CaliberContentFour';

const Instrument = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Caliber | Instrument Management Software | Adrta' />
      <Navbar/>
      <HeroCaliber />
      <CustomerBrand  />
      <CaliberContent/>
      <CaliberContentTwo/>      
      <CaliberContentThree />
      <CaliberContentFour />
      <FaqTwo/>
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Instrument;
