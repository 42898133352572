import React from 'react';

const CaliberContentFour = () => {
    return (
        <>
            <section className='ptb-120 bg-light'>
                <div className='container'>
                    <div className="row justify-content-center mb-30">
                        <div className='col-md-10'>

                            <div className="row" >
                                <div className='col-md-6'>
                                    <div>
                                        <h2 className='title2 pb-2'>Real-Time Insights on Upcoming Maintenance</h2>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <p>
                                    The software's dashboard offers real-time insights into calibration, PM, and AMC frequencies, empowering users with efficient resource allocation and operational stability. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pb-0 text-center'>
                        <img
                            src='assets/img/caliber.png'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default CaliberContentFour;