import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LmsContentFour = () => {
  return (
    <>
      <section className='mk-about-section bg-white ptb-120'>
        <div className='container'>
        <SectionTitle
                  subtitle='Why Zentixs Talent'
                  title='Empowering Leaders, Enriching Learning'
                  centerAlign                 
                />
          <div className='row justify-content-center g-4 '>  

          <div class="col-xl-4 col-lg-6 d-flex  ">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column ">           
            <h5 class="h-6">Training Calendar</h5>
            <p>Features a comprehensive schedule of all planned training sessions, including topics, dates, and responsible trainers.</p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 d-flex">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column">           
            <h5 class="h-6">Classroom Training</h5>
            <p>Organizes structured training sessions led by instructors, promoting interactive learning through discussions and group activities.</p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 d-flex">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column">           
            <h5 class="h-6">External Training</h5>
            <p>Facilitates participation in courses or seminars by external experts to enhance specialized skills unavailable internally.</p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 d-flex">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column">           
            <h5 class="h-6">Retraining</h5>
            <p>Offers scheduled updates to existing skills, ensuring employees remain current with regulations and industry best practices.</p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 d-flex">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column">           
            <h5 class="h-6">Compliance Training</h5>
            <p>Focuses on training related to legal standards such as GxP and GMP, ensuring employees meet compliance requirements.</p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 d-flex">
          <div class="bg-white p-4 cyber-count-box mb-30 mb-lg-0 d-flex flex-column">           
            <h5 class="h-6">Cross-functional Training</h5>
            <p>Provides training across different roles and departments, enhancing knowledge-sharing and team flexibility.</p>
            </div>
          </div>
        

          </div>
        </div>
      </section>
    </>
  );
};

export default LmsContentFour;
