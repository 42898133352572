import React from 'react';

const CareerPromo = () => {
  return (
    <>
      <section className='career-promo ptb-100 bg-career'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='section-heading text-center'>
                <h2 className='title2'>Sail with Us and Catch the Wind of Success!</h2>
                <p>
                Absolutely, your provided passage beautifully encapsulates the essence of offering more than just jobs and creating a space for meaningful careers to blossom. It portrays a vision of empowerment, purpose-driven paths, and the limitless potential that lies ahead. This statement not only invites individuals to be part of something greater but also ignites the imagination of a future yet to be written. Well-crafted!
                </p>
              </div>
            </div>
          </div>
          <div class="pb-0">
            <img
              src='assets/img/career.png'
              alt='career'
              className='img-fluid about-img-first mt-5 rounded-custom shadow'
            />            
          </div>

          <div className='row justify-content-center ptb-120'>
            <div className='col-lg-9 col-md-12'>
              <div className='section-heading text-center'>
                <h2 className='title2'>Diversity, equity, inclusion</h2>
                <p>
                At Adrta, we hold diversity and inclusion in the highest regard. Our unwavering commitment to the principles of equal employment opportunity is the cornerstone of our company culture. We stand united against discrimination or harassment in any form, ensuring that every applicant and employee is treated with the utmost respect and fairness.
                </p>
                <p>This steadfast commitment extends to a wide range of factors, including age, race, colour, gender, national origin, religion, creed, disability, covered veteran status, genetic information, sexual orientation, gender identity, pregnancy, childbirth or related medical conditions, marital status, citizenship status, ancestry, amnesty, and any other personal characteristic safeguarded by the law.</p>
                <p>Join us in our mission to cultivate a workplace that celebrates diversity and stands against discrimination. Together, we're building a future where every person's potential can flourish, unencumbered by bias or prejudice.</p>
              </div>   
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4 col-lg-4'>
              <div className='single-feature d-flex mt-0 p-5 bg-white rounded-custom'>
                <span>
                <img
              src='assets/img/icon/home.svg'
              alt='career'
              className=''
              height={50}
            />      
                </span>
                <div className='ms-4'>
                  <h5>Diversity, Equity & Inclusion</h5>
                  <p className='mb-0'>
                  Embrace Diversity, Shape the Future: Join us in creating an inclusive workplace where everyone's potential thrives, free from bias or prejudice.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4'>
              <div className='single-feature d-flex mt-4 mt-xl-0 mt-lg-0 mt-md-0 p-5 bg-white rounded-custom'>
              <span>
                <img
              src='assets/img/icon/clock.svg'
              alt='career'
              className=''
              height={50}
            />      
                </span>
                <div className='ms-4'>
                  <h5>Makes a difference</h5>
                  <p className='mb-0'>
                  Shape your career with us at Adrta, where diversity drives innovation and inclusion empowers success.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4'>
              <div className='single-feature d-flex mt-0 p-5 bg-white rounded-custom'>
              <span>
                <img
              src='assets/img/icon/user.svg'
              alt='career'
              className=''
              height={55}
            />      
                </span>
                <div className='ms-4'>
                  <h5>Value and behaviors</h5>
                  <p className='mb-0'>
                  Explore a fulfilling career at Adrta, where our values drive exceptional behaviours.{' '}
                  </p>
                </div>
              </div>
            </div>       
          </div>       
        </div>
      </section>
    </>
  );
};
export default CareerPromo;
