import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import PageHeader from '../../components/common/PageHeader';
import EulaDetails from '../../components/legal/EulaDetails';


const Eula = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <PageHeader
        title="End User License Agreement" desc="Last Updated: July 29, 2024" />   
      <EulaDetails />
      <FooterOne />
    </Layout>
  );
};

export default Eula;
