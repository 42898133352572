import React from 'react';
// import SectionTitle from '../common/SectionTitle';

const AboutusFour = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-0':'pt-0 pb-0'} `}>
        <div className='container'>

        <div className="row justify-content-center">
        <div className='col-md-9'>
        <h2 className='title2 pb-2'>A vision crafted in private,<br /> now radiating for the world
      </h2>
        <div className="row">
        <div className='col-md-6'>             
              <div>
               <p>
               At our core, our mission is centred on accelerating the development of the pharmaceuticals, research, laboratories, and healthcare industries. To achieve this, we are dedicated to providing intuitive, scalable, and efficient software solutions that revolutionize the way research is conducted.
               </p>
               <p>Our focus is on streamlining the entire research process, from study planning to data collection, analysis, and reporting. By optimizing each step of the research journey, we aim to significantly reduce the time and resources required for projects, expediting the path from discovery to implementation of ground-breaking treatments.</p>
              </div>
            </div>
    <div className='col-md-6'>
            <p>
            Throughout our endeavours, we uphold the highest standards of data integrity and compliance, recognizing the critical importance of confidentiality, security, and adherence to global regulatory guidelines in these industries. Ensuring the privacy and integrity of sensitive data is paramount in all aspects of our software development.
</p>
            </div>
        </div>
        </div>
    </div>


    <div className='ptb-120 pb-0'>
    <img
                  src='assets/img/aboutus-focus-4.jpg'
                  alt='zentixs aboutus'
                  className='img-fluid'
                />
    </div>

        </div>
      </section>
    </>
  );
};

export default AboutusFour;
