import React from "react";
import { Link } from "react-router-dom";

const EyeContentFive = () => {
  return (
    <section className="cyber-features pt-0 pb-0">
      <div className="container">  
        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box integration">        
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Integration Capabilities</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-primary me-2"></i>Ability to integrate with other systems, such as Document Management Systems (DMS), Learning Management Systems (LMS), and enterprise resource planning (ERP) systems.</li>
                </ul>              
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box mobile">           
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Mobile Access</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-dark me-2"></i>Mobile-friendly interface to allow auditors to access checklists, documents, and record findings on-site.</li>
                </ul>
              </div>
 
            </div>
          </div>
          <div className="col-lg-4  d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box training">
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Training and Resources</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Access to training materials, guidelines, and resources for auditors to ensure consistent practices.</li>
                </ul>
              </div>

            </div>
          </div>
   
        </div>
      </div>
    </section>
  );
};

export default EyeContentFive;
