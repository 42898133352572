import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LogContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/screen/prioritization-screen.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Create a Truly Paperless Production Process</h2>
                <div className='mt-5 mb-5'>
                  <h4 className='h4'>Regulatory Compliance (21 CFR Part 11 / EU Annex 11)</h4>
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Ensures compliance with regulations for electronic records and signatures.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Supports features like e-signatures, secure data storage, and restricted access to ensure data integrity.
                      </span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className='h4'>User Authentication and Access Control</h4>
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Role-based access controls (RBAC) ensure that only authorized personnel can access or modify certain data.
                      </span>
                    </li>
                    <li class="d-flex align-items-start py-1">
                      <i class="fad fa-check-circle me-2 text-primary"></i>
                      <span>Multi-factor authentication (MFA) can be implemented to enhance security.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogContentThree;
