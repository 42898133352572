import React from 'react';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import Team from '../components/team/Team';
import HeroLeadership from '../components/Product/HeroLeadership';

const About = () => {
  return (
    <Layout>
      <PageMeta title='The Adrta Team | Cloud Software for Business' />
      <Navbar classOption='navbar-light' />
      <HeroLeadership />
      <Team />      
      <FooterOne  />    
    </Layout>
  );
};

export default About;
