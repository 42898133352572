import React from 'react';

const CaliberContentTwo = () => {
    return (
        <>
            <section className='ptb-120 bg-light'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-10'>

                            <div className="row  justify-content-center align-items-center mb-5" >
                                <div className='col-md-5'>
                                    <div>
                                        <h2 className='title2 pb-2'>Unveiling Comprehensive Instrument Solutions</h2>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <p>
                                    The software presents a robust array of features that streamline every aspect of instrument management, spanning procurement, calibration, maintenance, and withdrawal. Its centralized approach elevates efficiency, reduces errors, and fosters heightened control, culminating in improved operational performance and organizational compliance
                                   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className='col-md-6'>
                    <div className='pb-0'>
                    <div className='feature-content-wrap'>
<ul className='list-unstyled text-dark-gray eye-list'>
  <li className='py-1 font-600'>
  <i className="fa-sharp fa-solid fa-circle-check text-light-green"></i>
  Efficient categorization based on specific applications for streamlined tracking.

  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green "></i>
  Centralized approach minimizes errors and enhances control over instruments.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Automated reminders for upcoming calibrations, reducing oversight and operational disruptions.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Facilitate allocation of unique Instrument IDs for easy tracking.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Define PM and AMC frequencies for preventive maintenance.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Automated label generation after calibration, PM and AMC tasks ensures accuracy and streamlined record-keeping.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Attach user manuals, calibration certificates, and maintenance records.
  </li>

  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Submission of withdrawal requests with approval stages.
  </li>
  <li className='py-1 font-600'>
  <i className="ffa-sharp fa-solid fa-circle-check text-light-green"></i>
  Supports data-driven decisions, trend identification, and regulatory compliance.  
  </li>



</ul>
</div>
                    </div>
                    </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default CaliberContentTwo;