import React from 'react';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroDesk from '../components/Product/Herodesk';
import DeskContent from '../components/Product/DeskContent';
import DeskContentThree from '../components/Product/DeskContenThree';
import DeskContenFour from '../components/Product/DeskContenFour';
import DeskContenFive from '../components/Product/DeskContenFive';
import DeskContentSix from '../components/Product/DeskContentSix';
import FaqDesk from '../components/faqs/FaqDesk';
import TrustedUsers from '../components/work-process/TrustedUsers';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Desk | Customer Service Helpdesk Software | Adrta'
      description='zentixs desk is a customer service help desk software that provides online support for happier customers, empowered agents, and healthier businesses.' />
      <Navbar/>
      <HeroDesk /> 
      <DeskContent />  
      <DeskContentSix />       
      <DeskContentThree />
      <DeskContenFour />
      <DeskContenFive />     
      <FaqDesk />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-desk"
      /> 
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
