import React, { useRef, useState  } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup'
import emailjs from '@emailjs/browser';


const ContactsForm = () => {
  const phoneRegExp =/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

  const [isEmailSent, setIsEmailSent] = useState(false);

 const Formik = useFormik({
    initialValues:{
      firstname:"",
      lastname:"",
      companyname:"",
      email:"",
      phonenumber:"",
      comments:"",
    },
    validationSchema:Yup.object({
      firstname:Yup.string().required('First name is required'),
      lastname:Yup.string().required('Last name is required'),
      companyname:Yup.string().required('Company name is required'),
      email:Yup.string()
      .email('Please enter valid email')
      .required('Please enter an email address'),
       phonenumber:Yup.string() .matches(phoneRegExp, 'Phone number is not valid')     
       .min(10, "Please enter a valid mobile number")
       .max(20, "Please enter a valid mobile number")   
      .required('Phone number is required'),
    

    }),
    
    onSubmit:(values, { resetForm }) =>{
      console.log("form submitted", values);
      const templateParams = {
        firstname: values.firstname,
        lastname: values.lastname,
        companyname: values.companyname,
        email: values.email,
        phonenumber: values.phonenumber,
        comments: values.comments,
      };
    
      emailjs
        .sendForm('service_gqbt9yo', 'template_1pwueom', form.current, '9Hpxt1l3bA5RUjt3s')
        .then((response) => {
          console.log('Email sent successfully:', response);
          setIsEmailSent(true);
          resetForm(); // Reset the form to its initial state after email is sent
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          setIsEmailSent(false);
        });

    }
    
  });

console.log(Formik.values)

const form = useRef();




  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4 text-center'>
          Request a product demo with a solution expert.
          </h3>
          <form action='#' className='register-form'  ref={form} onSubmit={Formik.handleSubmit}>
                    <div className='row'>
                    <div className='col-sm-6'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="firstname" placeholder="First Name" name="firstname" onChange={Formik.handleChange} onBlur={Formik.handleBlur} value={Formik.values.firstname} />
                          <label for="firstname">First Name</label>
                          {Formik.touched.firstname &&  Formik.errors.firstname && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.firstname}</p>}
                          
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="lastname" placeholder="Last Name" name="lastname" onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.lastname} />
                          <label for="lastname">Last Name</label>
                          {Formik.touched.lastname && Formik.errors.lastname && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.lastname}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="companyname" placeholder="Company Name" name="companyname" onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.companyname} />
                          <label for="companyname">Company Name</label>
                          {Formik.touched.companyname && Formik.errors.companyname && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.companyname}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="email" placeholder="Email" name="email" onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.email} />
                          <label for="email">Email</label>
                          {Formik.touched.email && Formik.errors.email && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.email}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="form-floating mb-3 ">
                          <input type="text" className="form-control" id="phonenumber" placeholder="Phone Number" name="phonenumber"  onBlur={Formik.handleBlur} onChange={Formik.handleChange} value={Formik.values.phonenumber} />
                          <label for="phonenumber">Phone Number</label>
                          {Formik.touched.phonenumber && Formik.errors.phonenumber && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.phonenumber}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                      <div className="form-floating">
  <textarea  className="form-control" placeholder="Leave a comment here" id="comments" name='comments' onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.comments}></textarea>
  <label for="comments">Comments</label></div>
                      </div>
                    </div>
                    <button type='submit' value="Send" className='btn btn-primary mt-4 w-100'>
                     Submit
                    </button>
                    <div className="small text-center pt-2">
        <p>By clicking "SUBMIT", I accept the Adrta <a href="/terms" >Terms of Service</a> and <a href="/privacy">Privacy Notice</a>.</p>

      </div>
      {isEmailSent && <div style={{color: '#0e9541'}}><i className="fa-sharp fa-solid fa-circle-check"></i> Thank you! Someone will be in touch shortly.</div>}
          </form>
        </div>
      </div>
    </>
  );
};
export default ContactsForm;
