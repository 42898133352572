import React from 'react';

const SprintContent = () => {
    return (
        <>
            <section className='ptb-120 bg-light-sec pb-0'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-6'>
                        <div className='text-center'>
                        <h2 className="label-txt blck-txt">For a streamlined work experience</h2>
                                        <h2 className='title2 pb-2'>Maximum productivity with minimum use</h2> 
                                        <p>
                                        Conduct live user sessions and interviews with multiple participants, record, and take notes simultaneously. Auto-transcribe videos to save time and simplify reviewing recorded interviews.
                                    </p>
                                    </div>                           
                        </div>
                    </div>
                    <div className='pb-0 text-center'>
                        <img
                            src='assets/img/sprint1.png'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default SprintContent;