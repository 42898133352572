import React from 'react';


const HeroLeadership = () => {
 
  return (
    <>
      <section
        className='hero-section ptb-120 pb-0'
        
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center text-center'>
            <div className='col-lg-6 col-md-10'>
            <h2 className='title2'>The Leadership Team</h2>
            <p className='text-dark'>The Adrta leadership team is made up of industry leaders who bring years of experience and knowledge to our company. Our leadership is committed to empowering and serving our global teams to ensure our values are at the core of everything we do.

</p>
            </div>
       
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroLeadership;
