import React from "react";
import { Link } from "react-router-dom";

const EyeContent = () => {
  return (
    <section className="cyber-features pt-100 pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="section-heading text-center mb-5">              
              <h2 className="title2"> Auditing Versatility: <br />One Software, Infinite Capabilities</h2>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-list"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Audit Planning and Scheduling</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Tools for scheduling audits based on timelines, resources, and priority.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Calendar integration for tracking audit schedules and deadlines.</li>
                </ul>              
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
              <i class="fa-sharp fa-regular fa-list-check"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Audit Checklists</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Customizable checklists to ensure all relevant criteria are assessed during audits.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Pre-defined templates based on industry standards or regulatory requirements.</li>
                </ul>
              </div>
 
            </div>
          </div>
          <div className="col-lg-4  d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-database"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Document Management</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Centralized storage for audit-related documents, including policies, procedures, and past audit reports.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Version control to manage document revisions and updates.</li>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
              <i class="fa-regular fa-chart-mixed"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Non-Conformance Tracking</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Mechanisms to record and track non-conformances identified during audits.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Ability to assign corrective actions and monitor their status.</li>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
              <i class="fa-regular fa-file-chart-pie"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Reporting and Analytics</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Tools for generating comprehensive audit reports, including findings, recommendations, and follow-up actions.</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Analytics to identify trends in audit findings and areas for improvement.</li>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
              <i class="fa-regular fa-screwdriver-wrench"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">CAPA Management</h3>
                <ul className="list-unstyled pricing-feature-list mb-0">
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Integration with Corrective and Preventive Action (CAPA) processes to address issues identified during audits</li>
                <li class="py-1  d-flex"><i class="fa-duotone fa-circle-check text-danger me-2"></i>Workflow for documenting and tracking CAPA effectiveness.</li>
                </ul>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EyeContent;
