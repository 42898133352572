import React from 'react';
import SectionTitle from '../common/SectionTitle';

const SagaContent = () => {
  return (
    <>
      <section className='promo-section ptb-120 dark-orange-bg'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <h2 className='title2 text-orange mb-5'>A modern and functionally complete Archival software solution</h2>
        
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white saga-box rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fas fa-wifi  fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5 text-white'>Stay Connected</h3>
                  <p className='mb-0 text-white'>
                    Interactively disintermediate resource maximizing portals
                    vertical models maintainable systems.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white saga-box rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fas fa-user-friends  fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5 text-white'>Encourage Participation</h3>
                  <p className='mb-0 text-white'>
                    Interactively disintermediate resource maximizing portals
                    vertical models maintainable systems.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center text-white saga-box rounded-custom p-5 mb-4'>
                <div className='promo-icon mb-32'>
                  <i className='fas fa-bezier-curve fa-3x'></i>
                </div>
                <div className='promo-info '>
                  <h3 className='h5 text-white'>Build Connections</h3>
                  <p className='mb-0 text-white'>
                    Interactively disintermediate resource maximizing portals
                    vertical models maintainable systems.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
       
        </div>
      </section>
    </>
  );
};

export default SagaContent;
