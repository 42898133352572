import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import FeatureImgContentThree from '../components/feature-img-content/FeatureImgContentThree';
import FeatureImgEight from '../components/features/FeatureImgEight';
import PromoThree from '../components/promo/PromoThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Community = () => {
  return (
    <Layout>
      <PageMeta title="Service Details-Software &amp; IT Solutions HTML Template" />
      <Navbar />
      <PageHeader
        title="Community"
      />
      <FeatureImgEight paddingTop />
      <FeatureImgContentThree />
      <PromoThree hasBg />
      <FooterOne />
    </Layout>
  );
};

export default Community;
