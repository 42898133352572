import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import {Autoplay } from 'swiper';

export default function CustomerBrand({ fullContainer }) {
  const swiperOption = {
    spaceBetween:24,
    autoplay:{
      delay: 0,
      disableOnInteraction: false,
      
    },
    pauseOnMouseEnter: true,
    modules:[Autoplay],
    breakpoints: {
 576: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 3,
        },
        992: {
            slidesPerView: 5,
        },
        1200: {
            slidesPerView: 5,
        },
        1400: {
            slidesPerView: 6,
        },
    },     
    loop: true,  
    speed: 5000,
    allowTouchMove: false,  
  };
  return (
    <section className="digtal-marketing-logo">
      <div className={fullContainer ? 'container-fluid' : 'container'}
      >
        <div className="py-5 px-4 rounded-3">
          <div className="row justify-content-center">
            <div className="col-auto">
              <h5 className="mb-4">
                Trusted by the best companies in the world
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-auto">

                 <Swiper {...swiperOption} className='digi-logo-slider' >
                   
                      
                     
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/apotex.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=" logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/alkem.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/zydus.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/lupin.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/apcer.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/axis-clinicals.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/cliantha.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/clianslabs.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/jai-research-foundation.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/accutest.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/zenrise.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/azidus.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/syconcro.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/intervain.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/vitelybio.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="logo-grid-item">
                                    <img
                                        src="assets/img/brand-logo/veeda-clinical-research.jpg"
                                        className="img-fluid"
                                        alt="logo"
                                    />
                                </div>
                            </SwiperSlide>                    
                                        
                        </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
