import React, { useState, useEffect } from 'react'
import Routers from './routers'
import BarLoader from "react-spinners/BarLoader";
import ReactGA from "react-ga4";


// Swiper CSS
import "swiper/css";
import 'swiper/css/navigation';

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";


ReactGA.initialize("G-8VZ665PV2M");
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/" });


function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <>

      {
        loading ?
          <div className='text-center justify-content-center '>
            <div>
              <img
                src='assets/img/logo-color.svg'
                alt='adrta logo'
                className='img-fluid mb-4 loader-pt'
              />
            </div>
            <BarLoader
              color="#368ed6"
              height={2}
              width={150}
              cssOverride={{
                margin: 'auto',
                textAlign: 'center'
              }}
              loading={loading}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          :
          <Routers />
      }
    </>
  )
}

export default App