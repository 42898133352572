import React from 'react';

const LimsContentThree = () => {
    return (
        <>
            <section className='ptb-120 dark-purple-bg'>
                <div className='container'>
                    <div className="row justify-content-center text-center">
                        <div className='col-md-10'>
                        <h2 className='title2 pb-2 text-white'>Why Choose Zentixs LIMS?</h2> 
                        <p className='text-white'>Host your business email on a secure, encrypted, privacy-guaranteed, <br/>and ad-free email service.
</p>                         
                        </div>
                    </div>
                    <div className="row mt-5 justify-content-center">
                      
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className=' text-warning mb-3'>Forecast customer demand with confidence
</h5>
              <p>
              Forecasts are updated constantly, factoring in seasonality, marketing, abnormal sales spikes and customer demand shifts for ultimate accuracy.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className=' text-warning mb-3'>Reliable, automated purchasing recommendations</h5>
              <p>
              Get automated buying recommendations that are easy for your whole team to understand. Customize for each SKU, location, supplier, bundle, assembly or by custom category. No more over (or under) ordering!
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className='mb-3'>Best-in-class overstock report
</h5>
              <p>
              Insights you won’t get anywhere else that enable you to liquidate excess inventory without having stockouts, like last sold date, overstock units and overstock costs.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className=' text-warning mb-3'>Align your marketing with your forecasting</h5>
              <p>
              Signed up an influencer? Planning a big promotion? Sync your forecasts with your marketing, so you won’t annoy hard-won new customers by running out of stock.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className=' text-warning mb-3'>Easy bundle and assembly inventory planning</h5>
              <p>
              Inventory Planner breaks forecasts down to component level and tells you how much inventory of each component is needed, plus when to order it.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="crypto-timeline  mb-4 mb-lg-0">
              <div className="crypto-timeline-icon">
                <img src="assets/img/Padlock.svg" alt="Padlock" />
              </div>
              <h5 className=' text-warning mb-3'>Multi-location inventory planning</h5>
              <p>
              Get reliable inventory purchasing and warehouse transfer recommendations based on up-to-date forecasts, for each of your locations including your physical stores, warehouses, FBAs and 3PLs.

</p>
            </div>
          </div>
       
        </div>

                </div>
            </section>
        </>
    );
};

export default LimsContentThree;