import React from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../common/HeroTitle';

const HeroIbook = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden ibook-banner-bg pb-0'      
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <h2 className='fw-bold display-5'> Inventory management software for growing businesses.
</h2>
                <p className='text-dark lead'>Increase your sales and keep track of every unit with our powerful stock management, order fulfillment, and inventory control software.
</p>
                
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >     
                </div>
              </div>
            </div>
            <div className='col-lg-9'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='200'
              >
            
                <img
                  src='assets/img/dashboard-ibook.png'
                  alt=''
                  className='img-fluid position-relative rounded-custom mt-5'
                />
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default HeroIbook;
