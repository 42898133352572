import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import SignContent from '../components/Product/SignContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import SignContentTwo from '../components/Product/SignContentTwo';
import SignContentThree from '../components/Product/SignContentThree';
import PriceThree from '../components/prices/PriceThree';
import SignContentFour from '../components/Product/SignContentFour';
import FaqSign from '../components/faqs/FaqSign';
import TrustedUsers from '../components/work-process/TrustedUsers';

const Sign = () => {

  return (
    <Layout>
      <PageMeta title='Zentixs Sign | Fast and Secure Electronic Signature | Adrta' />
      <Navbar/>    
      <SignContentThree/>
      <CustomerBrand  />
      <SignContent/>
      <SignContentTwo/>    
      <PriceThree />     
      <SignContentFour/>
      <FaqSign />    
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-sign"
      />     
      <FooterOne footerLight />
    </Layout>
  );
};

export default Sign;
