import React from 'react';
import SectionTitle from '../common/SectionTitle';

const SagaContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center  justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
              <h2 className='title3'>Benefits</h2>
                <ul className='list-unstyled text-dark-gray eye-list p-0 mt-4'>
                  <li className='py-1'>
                    <i className='fa-light fa-circle-check text-dark-green'></i>
                    Proactive crash analytics
                  </li>
                  <li className='py-1'>
                    <i className='fa-light fa-circle-check text-dark-green'></i>
                    Enhanced user engagement
                  </li>
                  <li className='py-1'>
                    <i className='fa-light fa-circle-check text-dark-green'></i>
                    Offer rich in-app experiences
                  </li>  
                  <li className='py-1'>
                    <i className='fa-light fa-circle-check text-dark-green'></i>
                    Boosted store ratings
                  </li>  
                  <li className='py-1'>
                    <i className='fa-light fa-circle-check text-dark-green'></i>
                    Increased daily active devices
                  </li>  
                </ul>
              </div>
            </div>
            <div className='col-lg-5 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/screen/animated-screen-3.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SagaContentTwo;
