import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqTwo from '../components/faqs/FaqTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import CustomerBrand from '../components/customer/CustomerBrand';
import HeroSprint from '../components/Product/HeroSprint';
import SprintContent from '../components/Product/SprintContent';
import SprintContentTwo from '../components/Product/SprintContentTwo';
import SprintContentThree from '../components/Product/SprintContentThree';
import SprintContentFour from '../components/Product/SprintContentFour';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Sprint | Project Master Scheduling Software | Adrta' />
      <Navbar/>
      <HeroSprint />
      <CustomerBrand  />
      <SprintContent />
      <SprintContentTwo />      
      <SprintContentThree/>
      <SprintContentFour />
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
