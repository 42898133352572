import React from 'react';
import SectionTitle from '../common/SectionTitle';

const DeskContenFour = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center  justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
              <div className='mb-5'>
               <h3 className='h4'>API Integrations</h3>
               <p className='lead'>Ability to integrate with other software systems (e.g., CRM, project management tools) to streamline workflows and data sharing.</p>
              </div>
              <div>
               <h3 className='h4'>Customizable Workflows</h3>
               <p className='lead'>Options to customize ticket workflows to match the specific processes and requirements of your organization.</p>
              </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-7'>
              <div className='feature-img-wrap text-end'>
                <img
                  src='assets/img/desk2.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeskContenFour;
