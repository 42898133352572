import React from 'react';
import SectionTitle from '../common/SectionTitle';

const SprintContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Increase productivity with team coordination</h2>
                <p>Achieve increased productivity within your team and organization by bringing all your work into a single platform. Focus priorities and adapt workflows with Wrike as your single source of truth.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/sprint2.jpg'
                  alt='Sprint'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SprintContentTwo;
