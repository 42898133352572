import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../common/HeroTitle';

const HeroSprint = () => {

  return (
    <>
      <section
        className='hero-section pink-bg text-white ptb-120'
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-8'>
              <div className='hero-img position-relative circle-shape-images'>
                {/* <!--animated shape end--> */}
                <img
                  src='assets/img/dashboard-img.png'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='hero-content-wrap mt-5 hero-doc'>
                <HeroTitle
                  title='Superior customer service.
                  Lasting experiences.'
                  desc='Proactively coordinate quality quality vectors vis-a-vis
                  supply chains. Quickly engage client-centric web services.'
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSprint;
