import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import QmsContent from '../components/Product/QmsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import QmsContentTwo from '../components/Product/QmsContentTwo';
import QmsContentThree from '../components/Product/QmsContentThree';
import QmsContentFour from '../components/Product/QmsContentFour';
import FaqTwo from '../components/faqs/FaqTwo';
import HeroQms from '../components/Product/HeroQms';
import FaqTrack from '../components/faqs/FaqTrack';
import TrustedUsers from '../components/work-process/TrustedUsers';



const Qms = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Track | Quality Management Software | Adrta' />
      <Navbar/>
      <HeroQms />
      <CustomerBrand  />
      <QmsContent/>
      <QmsContentTwo />
      <QmsContentThree />     
      <FaqTrack />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-track"
      >   
      <img src="assets/img/cta-bg.png" alt="not found" class="position-absolute start-0 top-0" style={{ opacity: 0.1 }} />
      </TrustedUsers>  
      <FooterOne footerLight />
    </Layout>
  );
};

export default Qms;
