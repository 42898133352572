import React, { useState, useEffect, useRef } from 'react';
import Reveal from 'react-reveal/Reveal';

import './VerticalTabs.css';

const ServicesContentOne = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const fromTop = window.scrollY + sidebarRef.current.clientHeight;
      const sectionOffsets = sections.map((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          return {
            id: section.id,
            offsetTop: element.offsetTop,
            offsetBottom: element.offsetTop + element.clientHeight,
          };
        }
        return null;
      });

      const newActiveSection = sectionOffsets.find(
        (section) => fromTop >= section.offsetTop && fromTop < section.offsetBottom
      );

      if (newActiveSection && activeSection !== newActiveSection.id) {
        setActiveSection(newActiveSection.id);
      } else if (!newActiveSection && activeSection !== null) {
        setActiveSection(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections, activeSection]);

  const handleSidebarItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - sidebarRef.current.clientHeight;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
      setActiveSection(id);
  
      // Slide the content section to the center of the viewport
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  
  return (
    <div className="content-wrapper">
      <section className='app-two-feature-two ptb-120'>
        <div className="sticky-sidebar-container container">
          <div className='row justify-content-between'>
            <div className='col-lg-6 d-none d-lg-block'>
              <div className="sticky-sidebar" ref={sidebarRef}>
                <h2 className="title2 mb-4">Revolutionizing Software Solutions for All Needs</h2>
                <ul className='verticaltab'>
                  {sections.map((section) => (
                    <li
                      key={section.id}
                      className={activeSection === section.id ? 'active' : ''}
                      onClick={() => handleSidebarItemClick(section.id)}>
                      {section.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-6'>
          
              <div className="content" style={{ padding: '20px 0 0 40px', minHeight: '100vh' }}>
                {sections.map((section) => (
                    <Reveal effect="slideInUp">
                  <div key={section.id} id={section.id} className="section app-two-single-feature mb-30 p-70">
                    <img src={section.imageUrl} alt={section.title} className='mb-4' />
                    <h2 className='h4'>{section.title}</h2>
                    <p>{section.content}</p>
                  </div>
                  </Reveal>
                ))}
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesContentOne;
