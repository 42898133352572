import React, { useRef, useState  } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup'
import emailjs from '@emailjs/browser';

const ContactBox = () => {

  const phoneRegExp =/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


 const Formik = useFormik({
    initialValues:{
      firstname:"",
      lastname:"",
      email:"",
      phonenumber:"",
      comments:"",
    },
    validationSchema:Yup.object({
      firstname:Yup.string().required('First name is required'),
      lastname:Yup.string().required('Last name is required'),
      email:Yup.string()
      .email('Please enter valid email')
      .required('Please enter an email address'),
       phonenumber:Yup.string() .matches(phoneRegExp, 'Phone number is not valid')     
       .min(10, "Please enter a valid mobile number")
       .max(20, "Please enter a valid mobile number")   
      .required('Phone number is required'),
    

    }),
    
    onSubmit:(values, { resetForm }) =>{
      console.log("form submitted", values);
      setIsLoading(true);

      const templateParams = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phonenumber: values.phonenumber,
        comments: values.comments,
      };
    
      emailjs
        .sendForm('service_7muy94i', 'template_tsb0h5p', form.current, '9Hpxt1l3bA5RUjt3s')
        .then((response) => {
          console.log('Email sent successfully:', response);
          setIsEmailSent(true);
          setIsLoading();
          resetForm(); // Reset the form to its initial state after email is sent
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          setIsEmailSent(false);
          setIsLoading(false);
        });

        setIsLoading(false);


    }
    
  });

console.log(Formik.values)

const form = useRef();



  return (
    <>
      <section className='contact-promo ptb-100'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-9'>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <div className='contact-us-promo pb-5'>
                    <span className='fad fa-comment-alt-lines fa-3x text-primary'></span>
                    <div className='contact-promo-info'>
                      <h5> Sales Inquiries</h5>
                      <p>
                      +91 9664863015                      
                      </p>
                    </div>

                  </div>
                  <div className='contact-us-promo '>
                    <span className='fad fa-envelope fa-3x text-primary'></span>
                    <div className='contact-promo-info mb-4'>
                      <h5>Email Us</h5>
                      <p>
                        <strong>sales@adrtatech.com</strong>
                      </p>
                    </div>

                  </div>
                </div>
                <div className='col-lg-6 col-md-8'>
                  <div className='section-heading text-center mb-4'>
                    <h2 className='title2 fs-35 '>Get in touch with us</h2>
                  </div>
                  <form action='#' className='register-form'  ref={form} onSubmit={Formik.handleSubmit}>
                    <div className='row'>
                    <div className='col-sm-6'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="firstname" placeholder="First Name" name="firstname" onChange={Formik.handleChange} onBlur={Formik.handleBlur} value={Formik.values.firstname} />
                          <label for="firstname">First Name</label>
                          {Formik.touched.firstname &&  Formik.errors.firstname && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.firstname}</p>}
                          
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="lastname" placeholder="Last Name" name="lastname" onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.lastname} />
                          <label for="lastname">Last Name</label>
                          {Formik.touched.lastname && Formik.errors.lastname && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.lastname}</p>}
                        </div>
                      </div>
               
                      <div className='col-sm-12'>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="email" placeholder="Email" name="email" onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.email} />
                          <label for="email">Email</label>
                          {Formik.touched.email && Formik.errors.email && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.email}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="form-floating mb-3 ">
                          <input type="text" className="form-control" id="phonenumber" placeholder="Phone Number" name="phonenumber"  onBlur={Formik.handleBlur} onChange={Formik.handleChange} value={Formik.values.phonenumber} />
                          <label for="phonenumber">Phone Number</label>
                          {Formik.touched.phonenumber && Formik.errors.phonenumber && <p style={{color:"#d82b00", fontSize:".75rem", fontWeight:"600" }}>{Formik.errors.phonenumber}</p>}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                      <div className="form-floating">
  <textarea  className="form-control" placeholder="Leave a comment here" id="comments" name='comments' onChange={Formik.handleChange}  onBlur={Formik.handleBlur} value={Formik.values.comments}></textarea>
  <label for="comments">Comments</label></div>
                      </div>
                    </div>
                    <button type='submit' value="Send" className='btn btn-primary mt-4 w-100' disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                    </button>
                    <div className="small text-center pt-2">
        <p>By clicking "SUBMIT", I accept the Adrta <a href="/terms" >Terms of Service</a> and <a href="/privacy">Privacy Notice</a>.</p>

      </div>
      {isEmailSent && <div style={{color: '#0e9541'}}><i className="fa-sharp fa-solid fa-circle-check"></i> Thank you! Someone will be in touch shortly.</div>}
          </form>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center ptb-100 pb-0'>
            <div className='col-md-12'>
            <div className='section-heading text-center mb-5'>
                    <h2 className='title2 fs-35 '>Office locations</h2>
                  </div>
              <div className='row'>
              <div className='col-lg-3 col-md-3'>
              <div className='contact-us-promo p-4 bg-white rounded-custom custom-shadow d-flex flex-column h-100'>
              <img
                                        src="assets/img/icon/india.svg"
                                        className="icon36 mb-3"
                                        alt="India flag"
                                    />
                      <h5>India</h5>            
                      <p>
                      1209, Satyamev Eminence,<br />
                      Science City Rd, Near Shukan Mall,<br /> Sola, Ahmedabad, Gujarat
                      </p>
                      <p><strong>Tel</strong> : +91 9664863015 <br /> <strong>Email</strong> : sales@adrtatech.com</p>                   
                      
                    </div>
              </div>
              <div className='col-lg-3 col-md-3'>
              <div className='contact-us-promo p-4 bg-white rounded-custom custom-shadow  d-flex flex-column h-100'>
              <img
                                        src="assets/img/icon/tanzania.svg"
                                        className="icon36 mb-3"
                                        alt="tanzania flag"
                                    />
                      <h5>Africa</h5>
                      <p>
                      PO BOX 523, USA River,<br />
                       Arusha, Tanzania, Africa.
                      </p>
                      <p><strong>Tel</strong> :  +255 (713) 618-375 <br /> <strong>Email</strong> : samir@adrtatech.com</p>                    
                    </div>
              </div>
              <div className='col-lg-3 col-md-3'>
              <div className='contact-us-promo p-4 bg-white rounded-custom custom-shadow  d-flex flex-column h-100'>
              <img
                                        src="assets/img/icon/usa.svg"
                                        className="icon36 mb-3"
                                        alt="tanzania flag"
                                    />
                      <h5>USA</h5>
                      <p>
                      711 Newark Ave, Apt 3F. <br />
                      Jersey City,NJ. 07306
                      </p>
                      <p><strong>Tel</strong> :  +1 (704) 907-5377 <br /> <strong>Email</strong> : usa.sales@adrtatech.com</p>                      
                    </div>
              </div>
              <div className='col-lg-3 col-md-3'>
              <div className='contact-us-promo p-4 bg-white rounded-custom custom-shadow  d-flex flex-column h-100'>
              <img
                                        src="assets/img/icon/canada.svg"
                                        className="icon36 mb-3"
                                        alt="tanzania flag"
                                    />
                      <h5>Canada</h5>
                      <p>                     
                      612 - 175 Pulberry Street Winnipeg, <br />Mb R2m 3x6 Canada
                      </p>
                      <p><strong>Tel</strong> :  +1 (204) 962-3844 <br /> <strong>Email</strong> : ca.sales@adrtatech.com</p>                  
                    </div>
              </div>
                 
                  
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBox;
