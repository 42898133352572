import React from "react";
import PaymentCustomer from "../customer/PaymentCustomer";

export default function IbookContent() {
  return (
    <section className="ptb-120 overflow-hidden position-relative bg-black pt-0">
      <PaymentCustomer />
      <div className="container pt-120">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title mb-5 text-center">
              <h2 className="text-white">Real-time data that moves as fast as your inventory</h2>
              <p className="text-off-white">
              The built-in features and seamless integrations that make Katana the end-to-end manufacturing software you need — lean and cloud-based.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="game-features mb-5 mb-lg-0">
              <div className="game-feature d-flex align-items-center mb-4">
                <div className="game-feature-icon flex-shrink-0">
                  <img src="assets/img/ti1.png" className="img-fluid" alt="" />
                </div>
                <div className="ms-3">
                  <h5 className="text-white">Live inventory management</h5>
                  <p className="m-0 text-off-white">
                  Optimize and control your products and raw materials
                  </p>
                </div>
              </div>
              <div className="game-feature d-flex align-items-center mb-4">
                <div className="game-feature-icon flex-shrink-0">
                  <img src="assets/img/ti2.png" className="img-fluid" alt="" />
                </div>
                <div className="ms-3">
                  <h5 className="text-white">Real-time master planning</h5>
                  <p className="m-0 text-off-white">
                  Manage production, scheduling, and resources in one place
                  </p>
                </div>
              </div>
              <div className="game-feature d-flex align-items-center">
                <div className="game-feature-icon flex-shrink-0">
                  <img src="assets/img/ti3.png" className="img-fluid" alt="" />
                </div>
                <div className="ms-3">
                  <h5 className="text-white">Total shop floor control</h5>
                  <p className="m-0 text-off-white">
                  Track floor operations from scheduling to task prioritization
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="game-feature-img position-relative">
              <img
                src="assets/img/ibook1.png"
                className="img-fluid position-relative z-5"
                alt="laptop"
              />
             
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}
