import React from 'react';

const IbookContentFour = () => {
    return (
        <>
            <section className='ptb-120 bg-light-yellow-ibook pb-80'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-7 text-center'>
                        <h2 className='title2 pb-2 '>Essential Features to Keep your Business Running
</h2>
                        </div>
                    </div>
                    <div className="row mt-5 justify-content-center">
                      
          <div className="col-lg-4 col-md-6">
            <div className="mb-4 mb-lg-0 ibook-timeline" >
              <div className="ibook-timeline-icon">
              <i className="fa-light fa-chart-line fa-2xl"></i>
              </div>
              <h5 className='  mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.


              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="ibook-timeline  mb-4 mb-lg-0">
              <div className="ibook-timeline-icon">
              <i className="fa-sharp fa-light fa-file-waveform fa-2xl"></i>
              </div>
              <h5 className='  mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.


              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="ibook-timeline  mb-4 mb-lg-0">
              <div className="ibook-timeline-icon">
              <i className="fa-sharp fa-light fa-location-dot fa-2xl"></i>
              </div>
              <h5 className='  mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.


              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="ibook-timeline  mb-4 mb-lg-0">
              <div className="ibook-timeline-icon">
              <i className="fa-sharp fa-light fa-clipboard-list-check fa-2xl"></i>
              </div>
              <h5 className='  mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.


              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="ibook-timeline  mb-4 mb-lg-0">
              <div className="ibook-timeline-icon">
              <i className="fa-light fa-gears fa-2xl"></i>
              </div>
              <h5 className='  mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.


              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="ibook-timeline  mb-4 mb-lg-0">
              <div className="ibook-timeline-icon">
             
              <i className="fa-light fa-house-building fa-2xl"></i>
              </div>
              <h5 className=' mb-3'>Complete marketing planner</h5>
              <p>
              Organize all your marketing efforts using a single calendar. Get more done with efficient planning and identify campaigns that perform with better monitoring features.
              </p>
            </div>
          </div>
       
        </div>
                </div>
            </section>
        </>
    );
};

export default IbookContentFour;