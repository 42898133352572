import React from 'react';
import SectionTitle from '../common/SectionTitle';

const CaliberContent = () => {
  return (
    <>
      <section className='promo-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='The Complete Approach to Elevated Instrument Operations
                '             
                  centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-sharp fa-light fa-clipboard-list-check  text-success fa-3x"></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Centralized Repository</h3>
                  <p className='mb-0'>
                  The software provides a centralized repository for storing crucial instrument information, including instrument names, makes, and categories. It allows users to easily categorize instruments based on their specific applications, facilitating efficient tracking and management.
              </p>
                </div>
           
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-light fa-gears  text-success fa-3x"></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Precision Management and Maintenance</h3>
                  <p className='mb-0'>
                  Scheduling accurate instrument calibrations and proactive maintenance. Effortlessly set calibration frequencies, while automated reminders prevent oversights. It also manages Preventive Maintenance (PM) and Annual Maintenance Contract (AMC) schedules, empowering users to define frequencies for extended instrument lifespans.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-light fa-user-shield  text-success fa-3x"></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Comprehensive Record Keeping</h3>
                  <p className='mb-0'>
                  The software captures vital Instrument Document, service breakdown, and activity details, ensuring a thorough repository for reference and analysis. This streamlined record-keeping supports informed decision-making and efficient troubleshooting.
                  </p>
                </div>
         
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-light fa-file-import text-success fa-3x" ></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Effortless Instrument Withdrawals</h3>
                  <p className='mb-0'>
                  The software effortlessly manages instrument withdrawals, allowing users to submit requests that undergo approval stages for proper authorization. By capturing withdrawal details and maintaining a comprehensive record, it elevates accountability and transparency throughout the process.
                  </p>
                </div>
         
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-light fa-business-time text-success fa-3x"></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Annual Schedule of Events</h3>
                  <p className='mb-0'>
                  The software's annual scheduling produces detailed reports that summarize calibration, PM frequency, and AMC frequency activities for each instrument over the year. These comprehensive schedules not only fuel data-driven decisions and trend spotting but also play a crucial role in meeting regulatory compliance.
                  </p>
                </div>
         
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative  bg-white   p-5 pb-2 pt-3 '>
                <div className='promo-icon mb-32'>
                <i className="fa-sharp fa-light fa-laptop-mobile text-success fa-3x"></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Audit Trail and Reporting Integration</h3>
                  <p className='mb-0'>
                  Combines two essential features: an Audit Trail function that meticulously records all actions, changes, and authorizations, and a sophisticated Reporting component that generates insightful summaries of instrument activities and history. This integration ensures transparency, accountability, and informed decision-making.
                  </p>
                </div>
         
              </div>
            </div>
          </div>
       
        </div>
      </section>
    </>
  );
};

export default CaliberContent;
