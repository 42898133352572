import React from 'react';
import SectionTitle from '../common/SectionTitle';
import { Link } from 'react-router-dom';

const WelcomeMessage = () => {
    return (
        <>
            <section className='feature-section cta-welcome-message'>
                <div className='container'>
                    <div className='row align-items-center justify-content-center'>
                  
                        <div className='col-lg-6'>                    
                            <p className='text-dark-gray'>
                            Adrta CEO believes create user-friendly software that sets new standards, ensuring compliance, robust data security, and privacy protection. We aim to lead with innovative products that inspire and empower our users, driven by collaboration and a commitment.</p>
                            <div class="author-info mt-4"><h5 class="mb-0">Ramesh Patel</h5><span className='text-black'>CEO & Founder, Adrta Technologies Private Limited.</span></div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='pr-lg-4 position-relative' data-aos='fade-up'>
                                <img
                                    src='assets/img/ramesh-patel.png'
                                    alt='shape'
                                    className='img-fluid'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WelcomeMessage;
