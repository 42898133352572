import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Reveal from 'react-reveal/Reveal';


const LogContent = () => {
  return (
    <>
      <section className='promo-section ptb-120 pb-0'>
        <div className='container'>
          <div className='row justify-content-center text-center  mb-30'>
            <div className='col-lg-7 col-md-10'>
              <h2 className='title2'>E-Logbooks as Key to Efficient Data Governance</h2>
              <p>E-logbooks improve organizational efficiency by streamlining record-keeping, offering easy template management, ensuring data accuracy and security, and simplifying monitoring and categorization.</p>
            </div>
          </div>
          <div className='row justify-content-center text-center  mb-30'>
            <div className='col-lg-11 col-md-10'>
              <div className='row align-content-stretch '>
                <div className='col-lg-4 col-md-6 mb-4'>

                  <div className='features-box position-relative  bg-white   p-5 pb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon1.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Real-Time Data Entry and Tracking</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Allows users to input data in real-time, improving the accuracy and timeliness of log entries.</span>
                        </li>
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Automated timestamps capture when the entries are made, ensuring compliance with regulatory requirements.</span>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-4'>
                  <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon2.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Configurable Templates</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Enables custom templates for log entries, ensuring consistent data collection and reducing setup time for recurring logs.
                          </span>
                        </li>
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Supports industry-specific templates (e.g., for cleanroom operations or maintenance logs).
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-4' >
                  <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon3.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Customizable Workflows</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Enables the creation of custom workflows to meet specific operational needs.</span>
                        </li>
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Can automate repetitive tasks, such as recurring entries, approvals, or validations.</span>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-4' >
                  <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon4.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Automated Instrument Data Collection</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Directly integrates with lab instruments, equipment, or sensors to automate data collection, reducing manual entry and human error.
                          </span>
                        </li>
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Supports IoT integration for real-time data streaming from machines and equipment.
                          </span>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-4' >
                  <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon5.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Searchable Data and Reports</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Provides a user-friendly interface for searching through historical data.</span>
                        </li>
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Offers customizable reporting tools for data analysis, which is helpful for audits and performance reviews.
                          </span>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-4' >
                  <div className='features-box position-relative  bg-white pb-4 p-5 mb-4'>
                    <div className='promo-icon mb-32'>
                      <img src='assets/img/icon/icon6.svg' />
                    </div>
                    <div className='promo-info'>
                      <h3 className='h5'>Automated Alerts and Notifications</h3>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Streamlines record-keeping by automatically logging occurrences, reducing manual input errors and saving time.
                          </span>
                        </li> 
                        <li class="d-flex align-items-start py-1">
                          <i class="fad fa-check-circle me-2 text-primary"></i>
                          <span>Sends alerts for overdue tasks or anomalies, ensuring timely action and improved accountability.
                          </span>
                        </li>               
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default LogContent;
