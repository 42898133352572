import React from 'react';

const ReviewTwo = ({ reqPage }) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap mt-5'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
              Demo Zentixs products
            </h1>
          ) : (
            <h2 className='text-white'>Demo Zentixs products</h2>
          )}
          <div
            className='tab-content testimonial-tab-content text-white-80'
            id='pills-tabContent'
          >
            <div
              className='tab-pane fade show active'
              id='testimonial-tab-1'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "I am Dr. Ravinder Sreedasyam, representing AXIS Clinicals. Our search for quality assurance led us to Mr. Ramesh Patel, while concurrently embarking on an electronic training software initiative. Remarkably, this software seamlessly mirrors our manual workflows, a testament to Mr. Patel's visionary guidance. The software, aligning perfectly with our projections, now governs document issuance control and more across all AXIS Clinicals branches. Adrta's remarkable integration extends beyond Hyderabad. We've entrusted Adrta software for over 5+ years, free from concerns. Regulatory bodies have scrutinized the software extensively, yet no observations have surfaced. Adrta's prowess is truly exceptional."
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Dr. Ravinder Sreedasyam</span>
                <span className='d-block small'>AXIS Clinicals Limited</span>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-2'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "I am thrilled to share my experience with Adrta Technologies Private Limited. Their software solution has been an absolute game-changer for our business. The level of compliance it offers across all regulations is truly exceptional, giving us peace of mind in a complex landscape. What truly sets them apart is their commitment to after-sales service. Their support team has consistently gone above and beyond to assist us, ensuring that our transition and ongoing usage have been nothing short of seamless."
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Mr. Viral Patel</span>
                <span className='d-block small'>Sycon Clinical Research Pvt. Ltd.</span>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-3'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "I am truly impressed by the exceptional software and unparalleled service provided by Adrta Technologies Private Limited. As a professional working in a highly regulated industry, compliance is non-negotiable. Their all modules of the zentixs software are not only meets but exceeds all regulatory requirements, providing me with a sense of security and peace of mind. I wholeheartedly recommend Adrta Technologies Private Limited to anyone seeking a compliance solution backed by top-notch service. With them, you're not just a client; you're a valued partner on a journey towards regulatory success."
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Mr. Kartik Vikani</span>
                <span className='d-block small'>Intervein Research Labs Pvt. Ltd.</span>
              </div>
            </div>
          </div>
          <ul
            className='nav nav-pills mb-0 testimonial-tab-indicator mt-5'
            id='pills-tab'
            role='tablist'
          >
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-1'
                type='button'
                role='tab'
                aria-selected='true'
              >
                <img
                  src='assets/img/testimonial/1.jpg'
                  alt='testimonial'
                  width='55'
                  className='img-fluid rounded-circle'
                />
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-2'
                type='button'
                role='tab'
                aria-selected='false'
              >
                <img
                  src='assets/img/testimonial/2.jpg'
                  alt='testimonial'
                  width='55'
                  className='img-fluid rounded-circle'
                />
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-3'
                type='button'
                role='tab'
                aria-selected='false'
              >
                <img
                  src='assets/img/testimonial/5.jpg'
                  alt='testimonial'
                  width='55'
                  className='img-fluid rounded-circle'
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ReviewTwo;
