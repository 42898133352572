import React from 'react';
import { Link } from 'react-router-dom';

const FooterSocial = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer className='footer-section'>
        {/* <!--footer bottom start--> */}
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; {year} Adrta Technologies Pvt Ltd Rights Reserved.{' '}
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://facebook.com/adrtatech' target='_blank'>
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://twitter.com/adrtatech' target='_blank'>
                        <img
                          src='assets/img/x.svg'
                          alt='logo'
                          className='img-fluid'
                          style={{ height: "14px" }}
                        />
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/company/adrta/' target='_blank'>
                        <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--footer bottom end--> */}
      </footer>
    </>
  );
};

export default FooterSocial;
